import React, {memo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractCurrentObject} from '@src/store/modules/entities/object/selectors';
import PageHeaderMenu from './PageHeaderMenu';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';

interface IObjectEditPageHeaderProps {
	objectId?: string;
}

export const ObjectPageHeader = memo((props: IObjectEditPageHeaderProps) => {
	const {objectId} = props;
	const object = useAppSelector(s => extractCurrentObject(s, objectId));

	useAppHeader(
		{
			title: object ? `Объект «${object.name}»${object.deleted ? ' (удалено)' : ''}` : 'Новый объект',
			showBackBtn: true
		},
		[object]
	);

	return (
		<div
			className="object-page__header"
		>
			<PageHeaderMenu
				objectId={objectId}
				isObjectDeleted={object?.deleted}
			/>
		</div>
	);
});
