import React from 'react';
import {PageHeader} from '@tehzor/ui-components';
import history from '@src/core/history';
import IUser from '@tehzor/tools/interfaces/IUser';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {usePermissions} from '../utils/usePermissions';
import {deleteUser, restoreUser} from '@src/store/modules/entities/user/actions';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import PageHeaderMenu from './PageHeaderMenu';
import {formTitle} from '../utils/formTitle';

interface IUserPageHeaderProps {
	userId?: string;
	user?: IUser;
}

const UserPageHeader = ({userId, user}: IUserPageHeaderProps) => {
	useAppHeader({title: formTitle(userId, user), showBackBtn: true}, [user]);

	const dispatch = useAppDispatch();
	const permissions = usePermissions(user);

	const [deleteDialog, getDeleteConfirmation] = useConfirmDialog('Вы действительно хотите удалить пользователя?');
	const [, handleDelete] = useAsyncFn(async () => {
		if (userId && (await getDeleteConfirmation())) {
			const res = await dispatch(deleteUser(userId));
			if (res.hasOwnProperty('success')) {
				history.push('/users');
			}
		}
	}, [userId]);

	const [restoreDialog, getRestoreConfirmation] = useConfirmDialog(
		'Вы действительно хотите восстановить пользователя?'
	);
	const [, handleRestore] = useAsyncFn(async () => {
		if (userId && (await getRestoreConfirmation())) {
			await dispatch(restoreUser(userId));
		}
	}, [userId]);

	return (
		<PageHeader
			className="user-page__header"
			title=""
		>
			<PageHeaderMenu
				onDeleteClick={!user?.deleted && permissions.canDelete ? handleDelete : undefined}
				onRestoreClick={user?.deleted && permissions.canRestore ? handleRestore : undefined}
			/>

			{deleteDialog}
			{restoreDialog}
		</PageHeader>
	);
};

export default UserPageHeader;
