import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {createApiAction} from '@src/store/middlewares/api';
import {IGetRegulatoryStandardsResponse, requestRegulatoryStandards} from '@src/api/backend/regulatoryStandards/get';

export type IGetRegulatoryStandardsPayload = IGetRegulatoryStandardsResponse;

const request = () => ({type: types.GET_REQUEST});

const success = (response: IGetRegulatoryStandardsResponse) => ({
	type: types.GET_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast('Ошибка', 'при загрузке списка стандартов');
	return {
		type: types.GET_FAILURE,
		payload: error
	};
};

/**
 * Получает список стандартов
 *
 */
export const getRegulatoryStandards = () =>
	createApiAction<IGetRegulatoryStandardsResponse>(request, success, failure, requestRegulatoryStandards);
