import React from 'react';
import {RowInfo} from 'react-table-6';
import INotificationType from '@tehzor/tools/interfaces/notifications/INotificationType';
import Setting from './Setting';

const Settings = ({original}: RowInfo) => {
	const {id, availableSettings} = original as INotificationType;

	return availableSettings && availableSettings.length ? (
		<div
			className="role-page__settings role-page__subs-settings"
		>
			<div className="role-page__settings-title">Дополнительные настройки:</div>

			{availableSettings.map(item => (
				<div
					key={item.key}
					className="role-page__settings-item"
				>
					<Setting
						typeId={id}
						settingKey={item.key}
						name={item.name}
						type={item.type}
					/>
				</div>
			))}
		</div>
	) : null;
};

export default Settings;
