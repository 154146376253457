import {matchPath, useLocation} from 'react-router-dom';

const pages = [
	'companies',
	'objects',
	'archived-objects',
	'export-templates',
	'users',
	'roles',
	'reg-standards',
	'logs',
	'problem-templates-sets'
];

/**
 * Возвращает ключ текущей страницы
 */
export const usePageKey = () => {
	const location = useLocation();
	const path = location.pathname;

	for (const page of pages) {
		if (matchPath(`/${page}/*`, path)) {
			return page;
		}
	}
	return undefined;
};
