import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';
import {IProblemStatus, ProblemStatusId} from '@tehzor/tools/interfaces/problems/IProblemStatus';

const extractAllIds = (state: IState) => state.entities.problemStatuses.allIds || [];
const extractById = (state: IState) => state.entities.problemStatuses.byId || {};

/**
 * Возвращает статусы в виде массива
 */
export const extractProblemStatusesAsArray = createSelector([extractAllIds, extractById], (allIds, byId) =>
	allIds.map((id: string) => byId[id]));

/**
 * Возвращает статусы вместе со статусом "Просрочено"
 */
export const extractProblemStatusesWithExpired = createSelector([extractProblemStatusesAsArray], statuses =>
	(statuses.length
		? statuses.concat([
				{
					id: ProblemStatusId.EXPIRED,
					name: 'Просрочено',
					color: '#FF6565',
					textColor: '#FF5A47',
					transitions: []
				} as IProblemStatus
		  ])
		: []));
