import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';
import IUser, {UserRoleScopes} from '@tehzor/tools/interfaces/IUser';
import {getObjectsAsArray} from '@src/selectors/entities/objects';
import {handlePagination} from '@src/utils/handlePagination';

const getAllIds = (state: IState): string[] => state.entities.users.allIds || [];
export const getUsersById = (state: IState): {[id: string]: IUser} => state.entities.users.byId || {};
export const getUsersFilter = (state: IState) => state.settings.pages.users.filter;
export const getUsersPageOffset = (state: IState) => state.settings.pages.users.offset || 0;
export const getUsersPageSize = (state: IState) => state.settings.pages.users.pageSize || 20;
const getUsersSettings = (state: IState) => state.settings.pages.users;
const getUserId = (state: IState, userId: string | undefined) => userId;

/**
 * Возвращает список пользователей в виде массива
 */
export const extractUsersAsArray = createSelector([getAllIds, getUsersById], (allIds, byId) =>
	allIds.map((id: string) => byId[id]).sort((a, b) => (a.fullName > b.fullName ? 1 : -1)));

/**
 * Возвращает отфильрованный список пользователей
 */
export const extractFilteredUsers = createSelector(
	[extractUsersAsArray, getUsersSettings, getObjectsAsArray],
	(users, settings, objects) => {
		const filtered = [];
		const {offset, pageSize, filter} = settings;
		const {email, fullName, companiesIds, position, deleted, deactivated, roleId, objectsIds} = filter;

		const usersWithObjects = users?.map(user => {
			const objIds = user.roles?.flatMap(r => (r.scope === UserRoleScopes.OBJECT ? r.scopeIds : []));
			const userObjects = objIds?.length ? objects.filter(object => objIds.includes(object.id)) : [];
			return {...user, objects: userObjects};
		});

		const userFullNameFilter = fullName?.toLowerCase().trim();
		const userPositionFilter = position?.toLowerCase().trim();
		const userEmailFilter = email?.toLowerCase().trim();

		for (const user of usersWithObjects) {
			if (
				(userEmailFilter ? user.email?.toLowerCase().includes(userEmailFilter) : true)
				&& (!companiesIds?.length || user.companies?.some(({id}) => companiesIds?.includes(id)))
				&& (userPositionFilter ? user.position?.toLowerCase().includes(userPositionFilter) : true)
				&& (roleId ? user.roles?.some(item => item.roleId === roleId) : true)
				&& (userFullNameFilter ? user.fullName.toLowerCase().includes(userFullNameFilter) : true)
				&& (deleted !== undefined ? deleted === user.deleted : true)
				&& (deactivated !== undefined ? deactivated === !user.activated : true)
				&& (!objectsIds?.length || user.objects.some(({id}) => objectsIds.includes(id)))
			) {
				filtered.push(user);
			}
		}
		const paginatedArr = filtered.filter((item, i) => handlePagination(i, offset, pageSize));
		return {
			data: paginatedArr,
			pageCount: Math.ceil(filtered.length / pageSize),
			currentPage: Math.floor(offset / pageSize)
		};
	}
);
/**
 * Возвращает выбранного пользователя
 */
export const extractUser = createSelector([getUsersById, getUserId], (byId, userId) =>
	(userId ? byId[userId] : undefined));
