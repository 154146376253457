import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export interface IRegulatoryStandardsSettingsState {
	pageSize: number;
	offset: number;
}

const initialState: IRegulatoryStandardsSettingsState = {
	pageSize: 20,
	offset: 0
};

const regulatoryStandardsSettingsSlice = createSlice({
	name: 'regulatoryStandardsSettings',
	initialState,
	reducers: {
		setPageSize: (state, action: PayloadAction<number>) => {
			state.pageSize = action.payload;
		},
		setOffset: (state, action: PayloadAction<number>) => {
			state.offset = action.payload;
		}
	}
});

export const {
	actions: regulatoryStandardsSettingsActions,
	reducer: regulatoryStandardsSettingsReducer
} = regulatoryStandardsSettingsSlice;