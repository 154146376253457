import {wsConnector} from '../wsConnector';
import IUser from '@tehzor/tools/interfaces/IUser';

export type IRestoreUserResponse = IUser;

/**
 * Восстанавливает пользователя
 *
 * @param userId id пользователя
 */
export const makeUserRestoreRequest = (userId: string) =>
	wsConnector.sendAuthorizedRequest<IRestoreUserResponse>('restoreUser', {userId});
