import * as types from '@src/constants/notifications/types';
// @ts-ignore
import {CALL_BACKEND_API} from '../../middlewares/backendMiddleware';
// @ts-ignore
import SocketConnector from '../../api/SocketConnector';
import {IState} from '@src/store/modules';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import INotificationType from '@tehzor/tools/interfaces/notifications/INotificationType';
import IError from '@tehzor/tools/interfaces/IError';

const socketConnector = SocketConnector.instance;

/**
 * Получает список типов уведомлений
 *
 * @returns {Function}
 */
export const getTypes = () => (dispatch: any, getState: () => IState) => {
		const data = getState().notifications.types;
		if (data.loaded) {
			return Promise.resolve(data);
		}
		return dispatch({
			[CALL_BACKEND_API]: {
				actions: [getTypesRequest, getTypesSuccess, getTypesFailure],
				promise: socketConnector.getNotificationTypes
			}
		});
	};

const getTypesRequest = () => ({
		type: types.GET_NOTIFICATION_TYPES_REQUEST
	});

const getTypesSuccess = (response: INormalizedData<INotificationType>) => ({
		type: types.GET_NOTIFICATION_TYPES_SUCCESS,
		payload: {...response}
	});

const getTypesFailure = (error: IError) => ({
		type: types.GET_NOTIFICATION_TYPES_FAILURE,
		payload: {...error}
	});
