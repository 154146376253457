import {combineReducers} from 'redux';
import app from './app';
import role from './roleUIReducer';
import roles from './rolesUIReducer';
import user from './userUIReducer';
import users from './usersUIReducer';
import company from './companyUIReducer';
import objects from './objectsUIReducer';
import exportTemplates from './exportTemplatesUIReducer';

export default combineReducers({
	app,
	role,
	roles,
	user,
	users,
	company,
	objects,
	exportTemplates
});
