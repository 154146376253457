import * as types from '@src/constants/notifications/intervals';
// @ts-ignore
import {CALL_BACKEND_API} from '../../middlewares/backendMiddleware';
// @ts-ignore
import SocketConnector from '../../api/SocketConnector';
import {IState} from '@src/store/modules';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import INotificationInterval from '@tehzor/tools/interfaces/INotificationInterval';
import IError from '@tehzor/tools/interfaces/IError';

const socketConnector = SocketConnector.instance;

/**
 * Получает список каналов уведомлений
 *
 * @returns {Function}
 */
export const getIntervals = () => (dispatch: any, getState: () => IState) => {
		const channels = getState().notifications.intervals;
		if (channels.loaded) {
			return Promise.resolve(channels);
		}
		return dispatch({
			[CALL_BACKEND_API]: {
				actions: [getIntervalsRequest, getIntervalsSuccess, getIntervalsFailure],
				promise: socketConnector.getNotificationIntervals
			}
		});
	};

const getIntervalsRequest = () => ({
		type: types.GET_NOTIFICATION_INTERVALS_REQUEST
	});

const getIntervalsSuccess = (response: INormalizedData<INotificationInterval>) => ({
		type: types.GET_NOTIFICATION_INTERVALS_SUCCESS,
		payload: {...response}
	});

const getIntervalsFailure = (error: IError) => ({
		type: types.GET_NOTIFICATION_INTERVALS_FAILURE,
		payload: {...error}
	});
