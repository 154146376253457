import React from 'react';
import {CellInfo} from 'react-table-6';
import {IconButton} from '@tehzor/ui-components';
import classNames from 'classnames';

type RowType = CellInfo['original'];

const icon = <i className="tz-settings-24 role-page__ptable-rest-expander-icon"/>;

const isHasNoChildren = (original: RowType) => !original.children || !original.children.length;

const isHasAvailableRestrictions = (original: RowType) =>
	original.availableRestrictions && original.availableRestrictions.length;

const isHasAvailableParams = (original: RowType) =>
	original.availableRestrictions && original.availableRestrictions.length;

const RestExpanderCell = ({original, isExpanded}: CellInfo) =>
	(isHasNoChildren(original) && (isHasAvailableParams(original) || isHasAvailableRestrictions(original)) ? (
		<IconButton
			type="transparent"
			className={classNames('role-page__ptable-rest-expander-btn', {
				'role-page__ptable-rest-expander-btn_open': isExpanded
			})}
		>
			{icon}
		</IconButton>
	) : null);

export default RestExpanderCell;
