export const makeFilterLabel = (label: string, selectFilter?: string | string[]): string => {
	if (!selectFilter) {
		return label;
	}
	if (Array.isArray(selectFilter) && selectFilter.length > 0) {
		const remainingCount = selectFilter.length - 1;
		return `${label}: ${selectFilter[0]}${selectFilter.length > 1 ? ` + ${remainingCount}` : ''}`;
	}
	if (typeof selectFilter === 'string') {
		return `${label}: ${selectFilter}`;
	}
	return label;
};
