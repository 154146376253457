import {ColumnDef} from '@tanstack/react-table';
import ICompany from '@tehzor/tools/interfaces/companies/ICompany';
import {CreatedCell} from '../CreatedCell/CreatedCell';
import {ModifiedCell} from '../ModifiedCell/ModifiedCell';

export interface ICompanyWithCustomer extends ICompany {
	customers?: ICompany[];
}

export const buildCompaniesColumns: Array<ColumnDef<ICompanyWithCustomer>> = [
	{
		accessorKey: 'name',
		header: 'Название'
	},
	{
		accessorKey: 'customers',
		header: 'Заказчик',
		accessorFn: ({customers}) => customers?.map(({name}) => name).join(', ')
	},
	{
		accessorKey: 'createdAt',
		header: 'Создано',
		cell: CreatedCell
	},
	{
		accessorKey: 'modifiedAt',
		header: 'Изменено',
		cell: ModifiedCell
	}
];
