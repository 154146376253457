import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {IRestoreObjectResponse, makeObjectRestoreRequest} from '@src/api/backend/object';
import {addErrorToast} from '@src/utils/toasts';

export type IRestoreObjectPayload = IRestoreObjectResponse;

const request = () => ({type: types.RESTORE_REQUEST});

const success = (response: IRestoreObjectResponse) => ({
	type: types.RESTORE_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	if (error.statusCode !== 401) {
		addErrorToast('Ошибка', 'при восстановлении объекта');
	}
	return {
		type: types.RESTORE_FAILURE,
		payload: {...error}
	};
};

/**
 * Восстанавливает объект
 *
 * @param objectId id объекта
 */
export const restoreObject = (objectId: string) =>
	createApiAction<IRestoreObjectResponse>(request, success, failure, () =>
		makeObjectRestoreRequest(objectId));
