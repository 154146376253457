import {createReducer} from '@reduxjs/toolkit';
import * as types from '@src/constants/notifications/intervals';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';
import INotificationInterval from '@tehzor/tools/interfaces/INotificationInterval';
import {
	getLoadedEntitiesSuccess,
	ILoadedEntityState,
	getLoadedEntitiesInitialState
} from '@tehzor/tools/utils/reducersHandlers';

export type INotificationsIntervalsState = ILoadedEntityState<INotificationInterval>;

export default createReducer<INotificationsIntervalsState>(getLoadedEntitiesInitialState(), {
	[types.GET_NOTIFICATION_INTERVALS_SUCCESS]: getLoadedEntitiesSuccess<INotificationInterval>(),
	[CLEAR_STORE]: getLoadedEntitiesInitialState
});
