import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export interface ILogPageSettingsState {
	filter: {
		types?: string[];
		dateFrom?: Date;
		dateTo?: Date;
		users?: string[];
		withError?: boolean;
	};
	sort: {
		[column: string]: boolean;
	};
	pageSize: number;
}

const initialState: ILogPageSettingsState = {
	filter: {},
	sort: {
		date: false
	},
	pageSize: 20
};

const logSettingsSlice = createSlice({
	name: 'logSettings',
	initialState,
	reducers: {
		setFilter: (state, action: PayloadAction<ILogPageSettingsState['filter']>) => {
			state.filter = {...state.filter, ...action.payload};
		},
		clearFilter: state => {
			state.filter = {};
		},
		setSort: (state, action: PayloadAction<ILogPageSettingsState['sort']>) => {
			state.sort = {...state.sort, ...action.payload};
		},
		setPageSize: (state, action: PayloadAction<number>) => {
			state.pageSize = action.payload;
		}
	}
});

export const {actions: logSettingsActions, reducer: logSettingsReducer} = logSettingsSlice;
