import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {wsConnector} from '@src/api/backend/wsConnector';
import ISpaceIndicatorsSet from '@tehzor/tools/interfaces/spaceIndicatorsSets/ISpaceIndicatorsSet';

export type IGetSpaceIndicatorsSetsResponse = INormalizedData<ISpaceIndicatorsSet>;

/**
 * Получает список наборов индикаторов помещений
 */
export const requestSpaceIndicatorsSets = () =>
	wsConnector.sendAuthorizedRequest<IGetSpaceIndicatorsSetsResponse>('getSpaceIndicatorsSets');
