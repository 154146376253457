import React from 'react';
import {ProblemStatusesCtx} from '../PermissionsTable';
import SelectableParam from './SelectableParam';

export interface IParamProps {
	id: string;
	name: string;
	dataType: string;
	permissionId: string;
}

const Param = (props: IParamProps) => {
	switch (props.dataType) {
		case 'problemStatus':
			return (
				<SelectableParam
					{...props}
					context={ProblemStatusesCtx}
				/>
);
		default:
			return null;
	}
};

export default Param;
