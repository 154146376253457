import useAppSelector from '@src/core/hooks/useAppSelector';
import {getUsersFilter} from '@src/selectors/entities/users';
import {usersSettingsActions} from '@src/store/modules/settings/pages/users/usersSettingsSlice';
import {useCallback} from 'react';
import useAppDispatch from '@src/core/hooks/useAppDispatch';

export const useUsersFilters = () => {
	const filtersState = useAppSelector(getUsersFilter);
	const dispatch = useAppDispatch();
	const {setFilter, clearFilter} = usersSettingsActions;

	const onFullNameChange = useCallback(
		(value: string) => {
			dispatch(setFilter({fullName: value}));
		},
		[dispatch, setFilter]
	);

	const onPositionChange = useCallback(
		(value: string) => {
			dispatch(setFilter({position: value}));
		},
		[dispatch, setFilter]
	);

	const onEmailChange = useCallback(
		(value: string) => {
			dispatch(setFilter({email: value}));
		},
		[dispatch, setFilter]
	);

	const onCompanyChange = useCallback(
		(value: string[]) => {
			dispatch(setFilter({companiesIds: value}));
		},
		[dispatch, setFilter]
	);

	const onRoleChange = useCallback(
		(value: string) => {
			dispatch(setFilter({roleId: value}));
		},
		[dispatch, setFilter]
	);

	const onObjectsChange = useCallback(
		(value: string[]) => {
			dispatch(setFilter({objectsIds: value}));
		},
		[dispatch, setFilter]
	);

	const onDeletedChange = useCallback(
		(value: boolean | undefined) => {
			dispatch(setFilter({deleted: value}));
		},
		[dispatch, setFilter]
	);

	const onDeactivatedChange = useCallback(
		(value: boolean | undefined) => {
			dispatch(setFilter({deactivated: value}));
		},
		[dispatch, setFilter]
	);

	const clearFiltersState = () => {
		dispatch(clearFilter());
	};

	const hasUserFilters = Boolean(
		filtersState.objectsIds?.length
		|| filtersState.roleId
		|| filtersState.companiesIds?.length
		|| filtersState.email
		|| filtersState.position
		|| filtersState.fullName
		|| filtersState.deleted !== undefined
		|| filtersState.deactivated !== undefined
	);
	return {
		filtersState,
		onFullNameChange,
		onPositionChange,
		onEmailChange,
		onCompanyChange,
		onRoleChange,
		onObjectsChange,
		onDeletedChange,
		onDeactivatedChange,
		clearFiltersState,
		hasUserFilters
	};
};