import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {addErrorToast} from '@src/utils/toasts';
import {IAddUserResponse, makeUserAddRequest} from '@src/api/backend/user';
import ISavingUser from '@tehzor/tools/interfaces/users/ISavingUser';

export type IAddUserPayload = IAddUserResponse;

const request = () => ({type: types.ADD_REQUEST});

const success = (response: IAddUserResponse) => ({
	type: types.ADD_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	if (error.statusCode !== 401) {
		addErrorToast('Ошибка', 'при добавлении пользователя');
	}
	return {
		type: types.ADD_FAILURE,
		payload: {...error}
	};
};

/**
 * Добавляет пользователя
 *
 * @param user данные пользователя
 */
export const addUser = (user: ISavingUser) =>
	createApiAction<IAddUserResponse>(request, success, failure, () => makeUserAddRequest(user));
