import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {ApiAction, createApiAction} from '@src/store/middlewares/api';
import checkExpiration from '@tehzor/tools/utils/checkExpiration';
import {IState} from '@src/store/modules';
import {createAction} from '@reduxjs/toolkit';
import preventExtraLoading from '@tehzor/tools/core/preventExtraLoading';
import {IGetRolesResponse, requestRoles} from '@src/api/backend/roles';

const getRolesRequest = createAction(types.GET_ROLES_REQUEST);
const getRolesSuccess = createAction<IGetRolesResponse>(types.GET_ROLES_SUCCESS);
const getRolesFailure = createAction(types.GET_ROLES_FAILURE, (error: IError) => {
	if (error.statusCode !== 401) {
		addErrorToast('Ошибка', 'при загрузке списка ролей');
	}
	return {
		payload: {...error}
	};
});

export const getRoles = () =>
	preventExtraLoading(
		'roles',
		checkExpiration<IState, IGetRolesResponse, ApiAction>(
			s => s.entities.roles,
			createApiAction<IGetRolesResponse>(getRolesRequest, getRolesSuccess, getRolesFailure, requestRoles)
		)
	);
