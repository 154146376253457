import React, {useCallback} from 'react';
import {DesktopHeader, DesktopHeaderMenu, MenuItem, MenuSeparator, UserMenu} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import {IHeaderSectionsMenuItem} from '@src/interfaces/IHeaderSectionsMenuItem';
import {useLocation} from 'react-router-dom';
import {useChangePath} from '@src/core/hooks/useChangePath';

interface IDesktopAppHeaderProps {
	title?: string;
	backBtnVisible?: boolean;
	sectionsMenu?: IHeaderSectionsMenuItem[];
}

const DesktopAppHeader = ({title, backBtnVisible, sectionsMenu}: IDesktopAppHeaderProps) => {
	const location = useLocation();
	const {pushPath, replacePath, goBack} = useChangePath();
	/**
	 * Переходит на главную страницу
	 */
	const goToMainPage = () => {
		if (location.pathname !== '/') {
			pushPath('/');
		}
	};

	/**
	 * Переходит в профиль пользователя
	 */
	const goToProfile = () => {
		if (location.pathname !== '/profile') {
			pushPath('/profile');
		}
	};
	const user = useAppSelector(s => s.auth.profile);

	const [exitDialog, getExitConfirmation] = useConfirmDialog('Вы действительно хотите выйти?', undefined, {
		acceptBtnProps: {type: 'accent-red'}
	});

	const logout = useCallback(async () => {
		if (await getExitConfirmation()) {
			pushPath('/logout');
		}
	}, []);

	return (
		<>
			<DesktopHeader
				className="app-header"
				title={title}
				sectionsMenu={
					!!sectionsMenu && (
						<DesktopHeaderMenu
							items={sectionsMenu}
							value={location.pathname}
							onChange={replacePath}
						/>
				)
				}
				userMenu={(
					<UserMenu
						user={user}
						avatarSize="34"
					>
						<MenuItem
							icon={<i className="tz-user-24"/>}
							onClick={goToProfile}
						>
							Перейти в профиль
						</MenuItem>
						<MenuSeparator/>
						<MenuItem
							icon={<i className="tz-exit"/>}
							onClick={logout}
						>
							Выйти
						</MenuItem>
					</UserMenu>
				)}
				onLogoClick={goToMainPage}
				onBackBtnClick={backBtnVisible ? goBack : undefined}
			/>

			{exitDialog}
		</>
	);
};

export default DesktopAppHeader;
