const commonFields = [
	'categoryId',
	'plannedFixDate',
	'reason',
	'planId',
	'floor',
	'location',
	'respUsers',
	'inspectors',
	'prescription',
	'problemTags',
	'description',
	'attachments',
	'critical'
];

export const createDefaultFieldsSettings = () => {
	const createDefaultField = (fieldIds: string[]) => fieldIds.map(fieldId => ({fieldId}));
	return {
		buildingProblem: createDefaultField(commonFields),
		acceptanceProblem: createDefaultField(commonFields),
		acceptanceClaimProblem: createDefaultField(commonFields),
		warrantyClaimProblem: createDefaultField(commonFields),
		problemReply: createDefaultField(['description', 'attachments']),
		inspection: createDefaultField(['planId', 'floor', 'location', 'description', 'attachments']),
		task: createDefaultField([
			'planId',
			'floor',
			'location',
			'respUsers',
			'description',
			'attachments',
			'status',
			'taskType',
			'taskPriority',
			'taskIntervalStart'
		]),
		contract: createDefaultField([
			'number',
			'objectIds',
			'categoryIds',
			'planIds',
			'signingDate',
			'startDate',
			'endDate',
			'priceNumber',
			'priceText',
			'fine',
			'companyBankAccountId',
			'contractorBankAccountId',
			'companyAuthorizedPersonId',
			'contractorAuthorizedPersonId'
		])
	};
};
