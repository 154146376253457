import React from 'react';
import {Table} from '@tehzor/ui-components';
import {IScopeItem} from './RolesTable';
import {RowInfo} from 'react-table-6';
import classNames from 'classnames';
import SubComponent from './SubComponent';
import columns from './columns';

interface IInnerTableProps {
	data: IScopeItem[];
	getTbodyProps?: () => ({});
}

const rowIdent = 50;

const getTrProps = (state: any, row?: RowInfo) => ({row});

const TheadComponent = () => null;

const TrComponent = ({children, className, row, style, ...rest}: any) => {
	const styles: React.CSSProperties = style ? {...style} : {};
	if (row && row.original) {
		styles.paddingLeft = `${row.original.level * rowIdent}px`;
	}
	return (
		<div
			className={classNames('rt-tr', className)}
			style={styles}
			role="row"
			{...rest}
		>
			{children}
		</div>
	);
};

const InnerTable = ({data, getTbodyProps}: IInnerTableProps) => (
	<Table
		data={data}
		columns={columns}
		sortable={false}
		resizable={false}
		showPagination={false}
		pageSize={data.length}
		getTbodyProps={getTbodyProps}
		getTrProps={getTrProps}
		TheadComponent={TheadComponent}
		TrComponent={TrComponent}
		SubComponent={SubComponent}
	/>
);

export default InnerTable;
