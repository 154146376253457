import {useMemo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {hasPermission} from '@tehzor/tools/utils/findPermission';

/**
 * Возвращает полномочия для текущего пользователя
 */
export const useNavPermissions = () => {
	const roles = useAppSelector(s => s.auth.profile.roles);

	return useMemo(
		() =>
			(roles
				? {
						companiesView: hasPermission(roles, 'companiesView'),
						objectsView: hasPermission(roles, 'objectsView'),
						usersView: hasPermission(roles, 'usersView'),
						rolesView: hasPermission(roles, 'rolesView'),
						problemTemplatesSetsView: hasPermission(roles, 'problem-templates-sets-view'),
						regulatoryStandardsView: hasPermission(roles, 'regulatoryStandardsView'),
						actionsLogView: hasPermission(roles, 'actionsLogView')
				  }
				: {}),
		[roles]
	);
};
