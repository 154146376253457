import {FilterButton, Select2, SelectOption, SelectPopup, SelectSearch} from '@tehzor/ui-components';
import React, {memo, useMemo, useState} from 'react';
import {flatFilter} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';
import {makeFilterLabel} from '@src/utils/makeFiltersLabel';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';

interface IMultipleSelectProps<D extends {name: string, id: string}> {
	label: string;
	options?: D[];
	optionsIds?: string[];
	onChangeFilter: (value: string[]) => void;
}

export const MultipleSelect = memo(<D extends {name: string, id: string}>(props: IMultipleSelectProps<D>) => {
	const {label, options, optionsIds, onChangeFilter} = props;
	const [search, setSearch] = useState('');
	const [selectedValue, setSelectedValue] = useState(optionsIds);

	useUpdateEffect(() => {
		setSelectedValue(optionsIds);
	}, [optionsIds]);

	const handleApply = () => {
		if (selectedValue) {
			onChangeFilter(selectedValue);
			setSearch('');
		}
	};

	const handleClear = () => {
		setSelectedValue([]);
		setSearch('');
	};

	const handleCancel = () => {
		setSearch('');
	};

	const handleFullClear = () => {
		setSelectedValue([]);
		onChangeFilter([]);
	};

	const filteredData = useMemo(() => options && flatFilter(options, 'name', search), [options, search]);
	const optionsNamesList = optionsIds && options?.filter(c => optionsIds.includes(c.id)).map(c => c.name);
	return (
		<SelectPopup
			footer
			onCancel={handleCancel}
			onApply={handleApply}
			onClear={handleClear}
			clearButton={!!selectedValue?.length}
			count={selectedValue?.length}
			trigger={(
				<FilterButton
					label={makeFilterLabel(label, optionsNamesList)}
					onClear={handleFullClear}
					active={Boolean(optionsIds?.length)}
				/>
			)}
			search={(
				<SelectSearch
					value={search}
					onChange={setSearch}
					type="popup"
				/>
			)}
		>
			<Select2
				multiple
				onChange={setSelectedValue}
				value={selectedValue}
			>
				{filteredData?.map(option => (
					<SelectOption
						content={option.name}
						inputType="checkbox"
						itemKey={option.id}
						key={option.id}
					/>
				))}
			</Select2>
		</SelectPopup>
	);
});
