/* eslint-disable @typescript-eslint/ban-ts-comment */
import {AnyAction, configureStore} from '@reduxjs/toolkit';
import {persistReducer, persistStore} from 'redux-persist';
import rootReducer, {IState} from './modules';
// @ts-ignore
import backendMiddleware from '@src/middlewares/backendMiddleware';
import apiMiddleware from '@src/store/middlewares/api';
import thunk from 'redux-thunk';
import {formPersistConfig} from './persistentStorage/formPersistConfig';

const middleware = [thunk, apiMiddleware(), backendMiddleware()];

if (process.env.NODE_ENV === 'development') {
	// eslint-disable-next-line max-len
	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-var-requires,global-require,import/no-extraneous-dependencies
	const {createLogger} = require('redux-logger');
	// eslint-disable-next-line @typescript-eslint/no-unsafe-call
	middleware.push(createLogger({collapsed: true}));
}

const persistedReducer = persistReducer(formPersistConfig<IState>('root', {whitelist: []}), rootReducer);

export const store = configureStore<IState, AnyAction, typeof middleware>({
	reducer: persistedReducer,
	middleware,
	devTools: process.env.NODE_ENV === 'development',
	preloadedState: {}
});
export const persistor = persistStore(store);

// @ts-ignore
if (module.hot) {
	// @ts-ignore
	module.hot.accept('./modules', () => {
		// eslint-disable-next-line global-require
		const nextRootReducer = require('./modules').default;
		store.replaceReducer(persistReducer(formPersistConfig<IState>('root'), nextRootReducer));
	});
}

export type AppDispatch = typeof store.dispatch;

export type AppStore = typeof store;
