import React, {memo, useMemo, useState} from 'react';
import {FilterButton, Select2, SelectOption, SelectPopup, SelectSearch} from '@tehzor/ui-components';
import {extractUsersAsArray} from '@src/selectors/entities/users';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {flatFilter} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';
import {makeFilterLabel} from '@src/utils/makeFiltersLabel';

interface IUsersFilterFilterProps {
	usersIds?: string[];
	changeFilter: (usersIds: string[]) => void;
}

const LABEL = 'Пользователь';

export const UsersFilter = memo(({usersIds, changeFilter}: IUsersFilterFilterProps) => {
	const users = useAppSelector(extractUsersAsArray);
	const [search, setSearch] = useState('');
	const [selectedUsers, setSelectedUsers] = useState(usersIds);
	const filteredData = useMemo(() => flatFilter(users, 'displayName', search), [users, search]);
	useUpdateEffect(() => {
		setSelectedUsers(usersIds);
	}, [usersIds]);

	const handleApply = () => {
		if (selectedUsers) {
			changeFilter(selectedUsers);
			setSearch('');
		}
	};

	const handleClear = () => {
		setSelectedUsers([]);
		setSearch('');
	};

	const handleCancel = () => {
		setSearch('');
	};

	const handleFullClear = () => {
		setSelectedUsers([]);
		changeFilter([]);
	};

	const usersNamesList = usersIds && users.filter(user => usersIds.includes(user.id)).map(user => user.displayName);

	return (
		<SelectPopup
			footer
			onCancel={handleCancel}
			onApply={handleApply}
			onClear={handleClear}
			clearButton={!!selectedUsers?.length}
			count={selectedUsers?.length}
			search={(
				<SelectSearch
					value={search}
					onChange={setSearch}
					type="popup"
				/>
			)}
			trigger={(
				<FilterButton
					label={makeFilterLabel(LABEL, usersNamesList)}
					onClear={handleFullClear}
					active={Boolean(usersIds?.length)}
				/>
			)}
		>
			<Select2
				multiple
				value={selectedUsers}
				onChange={setSelectedUsers}
			>
				{filteredData.map(type => (
					<SelectOption
						key={type.id}
						itemKey={type.id}
						content={type.displayName}
						inputType="checkbox"
					/>
				))}
			</Select2>
		</SelectPopup>
	);
});
