import React, {createContext, useCallback} from 'react';
import {ITableContextMenuAction} from '@tehzor/tools/interfaces/table/ITableContextMenuAction';
import {IPreparedProblemTemplatesSet} from '@tehzor/tools/interfaces/problemTemplatesSets/IPreparedProblemTemplatesSet';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {
	extractPreparedTemplatesSetsAsArray,
	extractTemplatesSetsData
} from '@src/store/modules/entities/problemTemplatesSets/selectors';
import {extractTemplatesSetsPageSettings} from '@src/store/modules/settings/pages/problemTemplatesSets/selectors';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import {useAsyncFn} from 'react-use';
import {changeSelectedRows, getTemplatesSets} from '@src/store/modules/entities/problemTemplatesSets/actions';
import {changeSort} from '@src/store/modules/settings/pages/problemTemplatesSets/actions';
import {deleteTemplatesSet} from '@src/store/modules/entities/problemTemplatesSet/actions';
import {useIsLargeTablet} from '@tehzor/ui-components/src/utils/mediaQueries';
import {
	desktopColumns,
	desktopColumnsWithoutObject
} from '@src/pages/ProblemTemplatesSetsPage/components/table/desktopColumns';
import {EntitiesTable, Plate} from '@tehzor/ui-components';
import {determineTableColumns} from '@src/utils/determineTableColumns';
import {useChangePath} from '@src/core/hooks/useChangePath';

export const DispatchActionCtx = createContext<(action: ITableContextMenuAction<IPreparedProblemTemplatesSet>) => void>(
	() => ({}));

export const TemplatesSetsTable = () => {
	const templatesSets = useAppSelector(extractPreparedTemplatesSetsAsArray);
	const templatesSetsData = useAppSelector(extractTemplatesSetsData);
	const pageSettings = useAppSelector(extractTemplatesSetsPageSettings);
	const dispatch = useAppDispatch();
	const {pushPath} = useChangePath();

	const [deleteDialog, getDeleteConfirmation] = useConfirmDialog('Вы действительно хотите удалить набор шаблонов?');
	const [, loadTemplatesSets] = useAsyncFn(() => dispatch(getTemplatesSets('all')), []);

	const handleRowClick = useCallback((templatesSet: IPreparedProblemTemplatesSet) => {
		pushPath(`/problem-templates-sets/${templatesSet.id}`);
	}, []);

	const handleSelectedRowsChange = useCallback((value: string[]) => dispatch(changeSelectedRows(value)), []);

	const handleSortChange = useCallback((value: {[key: string]: boolean}) => {
		dispatch(changeSort(value));
		void loadTemplatesSets();
	}, []);

	const [, handleContextMenuAction] = useAsyncFn(
		async (action: ITableContextMenuAction<IPreparedProblemTemplatesSet>) => {
			if (action.type === 'delete' && (await getDeleteConfirmation())) {
				await dispatch(deleteTemplatesSet(action.payload.id));
				await loadTemplatesSets();
			}
		},
		[]
	);

	const isLargeTablet = useIsLargeTablet();
	const columns = determineTableColumns(
		true,
		isLargeTablet,
		desktopColumns,
		desktopColumnsWithoutObject,
		desktopColumns,
		desktopColumnsWithoutObject
	);

	return (
		<>
			<DispatchActionCtx.Provider value={handleContextMenuAction}>
				<Plate withoutPadding>
					<EntitiesTable
						columns={columns}
						data={templatesSets}
						selectedRows={templatesSetsData.selectedRows}
						sort={pageSettings.sort}
						selectable
						responsive={!isLargeTablet}
						onRowClick={handleRowClick}
						onSelectedRowsChange={handleSelectedRowsChange}
						onSortChange={handleSortChange}
					/>
				</Plate>
			</DispatchActionCtx.Provider>

			{deleteDialog}
		</>
	);
};
