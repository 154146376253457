import React, {useCallback, useContext} from 'react';
import {CellInfo} from 'react-table-6';
import {Checkbox} from '@tehzor/ui-components';
import ICustomizableField from '@tehzor/tools/interfaces/ICustomizableField';
import {SettingsValueCtx} from './FieldsTable';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import IObjectFieldsSettings from '@tehzor/tools/interfaces/objects/IObjectFieldsSettings';
import {objectActions} from '@src/store/modules/entities/object/objectSlice';

const IsRequiredCell = ({original, tdProps}: CellInfo) => {
	const {id} = original as ICustomizableField;
	const {settingKey} = tdProps.rest;
	const value = useContext(SettingsValueCtx);
	const dispatch = useAppDispatch();

	const setting = value?.find(item => item.fieldId === id);

	const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(objectActions.updateFieldSetting({
			fieldId: id,
			key: settingKey as keyof IObjectFieldsSettings,
			setting: 'isRequired',
			value: event.target.checked
		}));
	}, [dispatch, id, settingKey]);

	return (
		<Checkbox
			className={{
				root: 'object-page__contractors-checkbox',
				icon: 'object-page__contractors-checkbox-input'
			}}
			checked={!!setting && setting.isRequired}
			disabled={!setting}
			onChange={onChange}
		/>
	);
};

export default IsRequiredCell;
