import React from 'react';
import {Button} from '@tehzor/ui-components';
import EditableCompany from '@src/components/EditableCompany';
import {useBoolean} from 'react-use';

const plusIcon = <i className="tz-plus-20"/>;

const CompanyAdding = () => {
	const [isDialogOpen, setDialogOpen] = useBoolean(false);

	return (
		<>
			<Button
				type="accent-blue"
				leftIcon={plusIcon}
				label="Новая компания"
				onClick={setDialogOpen}
			/>

			<EditableCompany
				isOpen={isDialogOpen}
				onClose={setDialogOpen}
			/>
		</>
	);
};

export default CompanyAdding;
