import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {wsConnector} from '@src/api/backend/wsConnector';

export type IGetObjectsResponse = INormalizedData<IObject>;

/**
 * Получает список объектов
 */
export const requestObjects = () => wsConnector.sendAuthorizedRequest<IGetObjectsResponse>('getObjects');
