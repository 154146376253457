import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';
import INotificationInterval from '@tehzor/tools/interfaces/INotificationInterval';

const getAllIds = (state: IState) => state.notifications.intervals.allIds || [];
const getById = (state: IState): {[id: string]: INotificationInterval} => state.notifications.intervals.byId || {};

/**
 * Возвращает каналы уведомлений в виде массива
 */
export const getNotificationIntervalsAsArray = createSelector(
	[getAllIds, getById],
	(allIds, byId) => allIds.map((id: string) => byId[id])
);
