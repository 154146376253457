import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';
// import findTreeNode from '@tehzor/tools/utils/findTreeNode';
// import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';
// import IObjectRespRule from '@tehzor/tools/interfaces/objects/IObjectRespRule';
// import {extractObjectsAsTree} from '@src/store/modules/entities/objects/selectors';
// import IObjectFieldsSettings from '@tehzor/tools/interfaces/objects/IObjectFieldsSettings';

const getById = (state: IState) => state.entities.objects.byId || {};
const getObjectId = (state: IState, objectId: string | undefined) => objectId;

export const getObjectForm = (state: IState) => state.entities.object.form;
export const getObjectData = (state: IState) => state.entities.object.data;
export const getObjectValidateErrors = (state: IState) => state.entities.object.errors;
export const getObjectEdited = (state: IState) => state.entities.object.edited || false;
export const getObjectIsLoading = (state: IState) => state.entities.object.isLoading || false;

/**
 * Возвращает текущий объект
 */
export const extractCurrentObject = createSelector([getById, getObjectId], (byId, objectId) =>
	(objectId ? byId[objectId] : undefined));

/**
 * Возвращает текущий объект из дерева
 */
// export const extractCurrentTreeObject = createSelector([extractObjectsAsTree, getObjectId], (tree, objectId) =>
// 	(objectId ? findTreeNode(tree, objectId) : undefined));

/**
 * Возвращает родительский объект текущего объекта
 */
// export const extractParentTreeObject = createSelector(
// 	[extractObjectsAsTree, extractCurrentTreeObject],
// 	(tree, object) => object && object.parentId && findTreeNode(tree, object.parentId)
// );

// export const extractObjectRespRules = createSelector([extractCurrentObject], object => {
// 	if (!object?.contractors) {
// 		return [] as IObjectRespRule[];
// 	}
// 	let rules = [] as IObjectRespRule[];
// 	for (const c of object.contractors) {
// 		if (c.respRules) {
// 			rules = rules.concat(c.respRules.filter((r: IObjectRespRule) => r.user));
// 		}
// 	}
// 	return rules;
// });

// export const extractObjectRespUsers = createSelector([extractObjectRespRules], rules =>
// 	rules
// 		.map(rule => rule.user || ({} as IBriefUser))
// 		.filter((user, i, all) => i === all.findIndex(u => user.id === u.id))
// 		.sort((a: IBriefUser, b: IBriefUser) => (a.fullName > b.fullName ? 1 : b.fullName > a.fullName ? -1 : 0)));
//
// export const extractObjectAutoSelectRespRules = createSelector([extractObjectRespRules], rules =>
// 	rules.filter(rule => rule.autoSelect));
//
// export const extractObjectFieldsSettings = createSelector(
// 	[extractCurrentObject],
// 	object => object?.fieldsSettings || ({} as IObjectFieldsSettings)
// );
