import React, {Dispatch, useCallback, useContext} from 'react';
import {IScopeItem, RolesContext} from './RolesTable';
import {Option, Select} from '@tehzor/ui-components';
import {UserRolesCtx, UserDispatchCtx} from '../../UserPage';
import {CellInfo} from 'react-table-6';
import {batch} from 'react-redux';
import {AnyAction} from 'redux';

const getPopupContainer = (node: HTMLElement): HTMLElement => {
	const elements = document.getElementsByClassName('user-page');
	if (elements.length) {
		return elements[0] as HTMLElement;
	}
	return node;
};

const recursivelySelectChildren = (dispatch: Dispatch<AnyAction>, items: IScopeItem[], roleId?: string) => {
	for (const item of items) {
		if (Array.isArray(item.children) && item.children.length) {
			recursivelySelectChildren(dispatch, item.children, roleId);
		}

		dispatch({
			type: 'update-role',
			scopeId: item.id,
			value: roleId
				? {
						scope: item.type,
						roleId
				  }
				: undefined
		});
	}
};

const RoleCell = ({original}: CellInfo) => {
	const roles = useContext(RolesContext);
	const userRoles = useContext(UserRolesCtx);
	const dispatch = useContext(UserDispatchCtx);

	const {id, type, disabled, children} = original as IScopeItem;

	const value = userRoles[id] ? userRoles[id].roleId : undefined;

	const handleChange = useCallback(
		(roleId?: string) => {
			dispatch({
				type: 'update-role',
				scopeId: id,
				value: roleId
					? {
							scope: type,
							roleId
					  }
					: undefined
			});

			if (Array.isArray(children) && children.length) {
				batch(() => {
					recursivelySelectChildren(dispatch, children, roleId);
				});
			}
		},
		[id, type, children]
	);

	return (
		<Select
			className="user-page__roles-table-select"
			allowClear
			menuMaxHeight="300px"
			value={value}
			disabled={disabled}
			onChange={handleChange}
			getPopupContainer={getPopupContainer}
		>
			{roles.map(role => (
				<Option
					key={role.id}
					value={role.id}
				>
					{role.name}
				</Option>
			))}
		</Select>
	);
};

export default RoleCell;
