import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {wsConnector} from '@src/api/backend/wsConnector';
import IRegulatoryStandard from '@tehzor/tools/interfaces/IRegulatoryStandard';

export type IUpdateRegulatoryStandardsResponse = INormalizedData<IRegulatoryStandard>;

/**
 * Обновляет список стандартов в базе данных
 */
export const makeUpdateRegulatoryStandardsRequest = () =>
	wsConnector.sendAuthorizedRequest<IUpdateRegulatoryStandardsResponse>('updateRegulatoryStandards');
