import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import * as userTypes from '../user/constants';
import * as objectTypes from '../object/constants';
import config from '@src/core/config';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';
import IUser from '@tehzor/tools/interfaces/IUser';
import {
	addEntitySuccess,
	editEntitySuccess,
	getEntitiesSuccess,
	getEntitiesInitialState,
	IEntityState,
	deleteEntitySuccess
} from '@tehzor/tools/utils/reducersHandlers';
import {IDeleteUserPayload} from '@src/store/modules/entities/user/actions';
import {IGetOneUserPayload} from './actions/getOne';

const {entitiesCacheTime} = config;

type IUsersState = IEntityState<IUser>;

export default createReducer<IUsersState>(getEntitiesInitialState<IUser>(), {
	[types.GET_SUCCESS]: getEntitiesSuccess<IUser>(entitiesCacheTime),
	[types.GET_ONE_SUCCESS]: (state, {payload}: {payload: IGetOneUserPayload}) => ({
		byId: {
			...state.byId,
			[payload.id]: payload
		},
		allIds: state.allIds.includes(payload.id)
			? state.allIds
			: state.allIds.concat([payload.id])
	}),
	[userTypes.ADD_SUCCESS]: addEntitySuccess<IUser>(),
	[userTypes.EDIT_SUCCESS]: editEntitySuccess<IUser>(),
	[userTypes.DELETE_SUCCESS]: (state, {payload}: {payload: IDeleteUserPayload}) => {
		if (payload.user) {
			return editEntitySuccess<IUser>()(state, {payload: payload.user});
		}
		return deleteEntitySuccess<IUser>()(state, {payload: {id: payload.id}});
	},
	[userTypes.RESTORE_SUCCESS]: editEntitySuccess<IUser>(),
	[objectTypes.ADD_SUCCESS]: () => getEntitiesInitialState<IUser>(),
	[objectTypes.EDIT_SUCCESS]: () => getEntitiesInitialState<IUser>(),
	[objectTypes.DELETE_SUCCESS]: () => getEntitiesInitialState<IUser>(),
	[CLEAR_STORE]: () => getEntitiesInitialState<IUser>()
});
