import React, {useCallback, useContext} from 'react';
import {IconMenu, MenuItem} from '@tehzor/ui-components';
import {CellProps} from 'react-table';
import {IPreparedProblemTemplatesSet} from '@tehzor/tools/interfaces/problemTemplatesSets/IPreparedProblemTemplatesSet';
import {DispatchActionCtx} from '@src/pages/ProblemTemplatesSetsPage/components/table/TemplatesSetsTable';

const deleteIcon = <i className="tz-delete"/>;
const editIcon = <i className="tz-edit"/>;

const MenuCell = ({row}: CellProps<IPreparedProblemTemplatesSet>) => {
	const menuActions = [];
	const {canEdit, canDelete} = row.original;

	const dispatchAction = useContext(DispatchActionCtx);

	const handleDelete = useCallback(() => {
		dispatchAction({type: 'delete', payload: row.original});
	}, [row.original, dispatchAction]);

	const handleEdit = useCallback(() => {
		dispatchAction({type: 'edit', payload: row.original});
	}, [row.original, dispatchAction]);

	if (canEdit) {
		menuActions.push(
			<MenuItem
				key="delete"
				icon={editIcon}
				onClick={handleEdit}
			>
				Редактировать
			</MenuItem>
		);
	}

	if (canDelete) {
		menuActions.push(
			<MenuItem
				key="delete"
				className="menu-item_red"
				icon={deleteIcon}
				onClick={handleDelete}
			>
				Удалить
			</MenuItem>
		);
	}

	return menuActions.length ? <IconMenu>{menuActions}</IconMenu> : null;
};

export default MenuCell;
