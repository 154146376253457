import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import * as planTypes from '../plan/constants';
import config from '@src/core/config';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';
import {IEntityState} from '@tehzor/tools/utils/reducersHandlers';
import IPlan from '@tehzor/tools/interfaces/plans/IPlan';
import {IGetPlansPayload, IEditPlansOrderPayload} from './actions';
import {IDeletePlanPayload, ICopyPlanPayload, IAddPlanPayload, IEditPlanPayload} from '../plan/actions';

const {entitiesCacheTime} = config;

export interface IPlansState {
	[objectId: string]: IEntityState<IPlan>;
}

export default createReducer<IPlansState>(
	{},
	{
		[types.GET_SUCCESS]: (state, {payload}: {payload: IGetPlansPayload}) => {
			state[payload.objectId] = {
				byId: payload.byId,
				allIds: payload.allIds,
				expires: Date.now() + entitiesCacheTime * 1000
			};
		},

		[types.EDIT_ORDER_SUCCESS]: (state, {payload}: {payload: IEditPlansOrderPayload}) => {
			state[payload.objectId] = {
				byId: payload.byId,
				allIds: payload.allIds,
				expires: Date.now() + entitiesCacheTime * 1000
			};
		},

		[planTypes.ADD_SUCCESS]: (state, {payload}: {payload: IAddPlanPayload}) => {
			const page = state[payload.objectId];
			if (page) {
				page.byId[payload.id] = payload;
				page.allIds.push(payload.id);
			}
		},

		[planTypes.EDIT_SUCCESS]: (state, {payload}: {payload: IEditPlanPayload}) => {
			const page = state[payload.objectId];
			if (page) {
				page.byId[payload.id] = payload;
			}
		},

		[planTypes.COPY_SUCCESS]: (state, {payload}: {payload: ICopyPlanPayload}) => {
			if (state[payload.objectId]) {
				state[payload.objectId] = {
					byId: {...state[payload.objectId].byId, ...payload.byId},
					allIds: [...state[payload.objectId].allIds, ...payload.allIds]
				};
			}
		},

		[planTypes.DELETE_SUCCESS]: (state, {payload}: {payload: IDeletePlanPayload}) => {
			const page = state[payload.objectId];
			if (page) {
				delete page.byId[payload.planId];
				page.allIds = page.allIds.filter(id => id !== payload.planId);
			}
		},

		[CLEAR_STORE]: () => ({})
	}
);
