import React from 'react';
import {CellInfo} from 'react-table-6';
import {IconButton} from '@tehzor/ui-components';
import classNames from 'classnames';

const icon = <i className="tz-settings-24 role-page__subs-table-settings-expander-icon"/>;

const SettingsExpanderCell = ({original, isExpanded}: CellInfo) =>
	(original.availableSettings && original.availableSettings.length ? (
		<IconButton
			className={classNames('role-page__subs-table-settings-expander-btn', {
				'role-page__subs-table-settings-expander-btn_open': isExpanded
			})}
			type="transparent"
		>
			{icon}
		</IconButton>
	) : null);

export default SettingsExpanderCell;
