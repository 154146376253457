import {useMemo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {hasPermission} from '@tehzor/tools/utils/findPermission';
import {UserRoleScopes} from '@tehzor/tools/interfaces/IUser';
import {extractUserProfile} from '@src/store/modules/auth/profile/selectors';

export interface IPermissions {
	canAdd?: boolean;
	canEdit?: boolean;
	canDelete?: boolean;
	canView?: boolean;
	canRestore?: boolean;
}

/**
 * Возвращает полномочия для пользователя
 */
export const useUsersPermissions = (): IPermissions => {
	const user = useAppSelector(extractUserProfile);

	return useMemo(() => {
		if (!user || !user.roles) {
			return {};
		}
		return {
			canView: hasPermission(user.roles, 'usersView', UserRoleScopes.ALL),
			canAdd: hasPermission(user.roles, 'usersAdd', UserRoleScopes.ALL),
			canEdit: hasPermission(user.roles, 'usersEdit', UserRoleScopes.ALL),
			canDelete: hasPermission(user.roles, 'usersDelete', UserRoleScopes.ALL),
			canRestore: hasPermission(user.roles, 'usersRestore', UserRoleScopes.ALL)
		};
	}, [user]);
};
