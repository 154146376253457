import {Reducer, useReducer} from 'react';
import {errorsFns, IEditableCompanyAction, IEditableCompany, IEditableCompanyState, init} from './state';
import ICompany from '@tehzor/tools/interfaces/companies/ICompany';
import {createReducer} from '@tehzor/tools/core/states/editableEntityState';

export const useEditableCompanyState = (company?: ICompany) =>
	useReducer<Reducer<IEditableCompanyState, IEditableCompanyAction>, ICompany | undefined>(
		createReducer<IEditableCompany, ICompany>(init, errorsFns),
		company,
		init
	);
