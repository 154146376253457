import {useParams} from 'react-router-dom';

export const useStrictParams = <T extends Record<string, string | undefined>>() => {
	const params = useParams<T>();

	if (Object.keys(params).length === 0) {
		throw new Error('Missing required parameter');
	}
	return Object.fromEntries(
		Object.entries(params).map(([key, value]) => [key, value as string])
	) as T;
};
