import {useMemo} from 'react';
import {getScopeIds, hasPermission} from '@tehzor/tools/utils/findPermission';
import IUser, {UserRoleScopes} from '@tehzor/tools/interfaces/IUser';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractUserRoles} from '@src/store/modules/auth/profile/selectors';

interface IPermissions {
	canDelete?: boolean;
	canRestore?: boolean;
}

export const usePermissions = (user?: IUser): IPermissions => {
	const userRoles = useAppSelector(extractUserRoles);

	return useMemo(() => {
		if (!user) {
			return {};
		}
		const companiesWithDeleteAccess = getScopeIds(userRoles, UserRoleScopes.COMPANY, 'usersDelete');
		const companiesWithRestoreAccess = getScopeIds(userRoles, UserRoleScopes.COMPANY, 'usersRestore');

		return {
			canDelete:
				hasPermission(userRoles, 'usersDelete', UserRoleScopes.ALL)
				|| user.companies?.every(company => companiesWithDeleteAccess.includes(company.id)),
			canRestore:
				hasPermission(userRoles, 'usersRestore', UserRoleScopes.ALL)
				|| user.companies?.every(company => companiesWithRestoreAccess.includes(company.id))
		};
	}, [user, userRoles]);
};
