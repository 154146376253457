import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';

const extractAllIds = (state: IState) => state.entities.spaceIndicatorsSets.allIds || [];
const extractById = (state: IState) => state.entities.spaceIndicatorsSets.byId || {};

/**
 * Извлекает наборы индикаторов помещений в виде массива
 */
export const extractSpaceIndicatorsSetsAsArray = createSelector([extractAllIds, extractById], (allIds, byId) =>
	allIds.map((id: string) => byId[id]));
