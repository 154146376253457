import React from 'react';
import {CellInfo} from 'react-table-6';
import {Button} from '@tehzor/ui-components';
import classNames from 'classnames';

const icon = <i className="tz-simple-arrow-24 role-page__ptable-perm-expander-icon"/>;

const PermExpanderCell = ({original, isExpanded}: CellInfo) =>
	(original.children && original.children.length ? (
		<Button
			className={classNames('role-page__ptable-perm-expander-btn', {
				'role-page__ptable-perm-expander-btn_open': isExpanded
			})}
			leftIcon={icon}
		/>
	) : null);

export default PermExpanderCell;
