import React from 'react';
import {hasPermission} from '@tehzor/tools/utils/findPermission';
import {IUserRole} from '@tehzor/tools/interfaces/IUser';
import memoizeOne from 'memoize-one';
import {extractUserRoles} from '@src/store/modules/auth/profile/selectors';
import {LinkButton, Plate} from '@tehzor/ui-components';
import './DashboardPage.less';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useChangePath} from '@src/core/hooks/useChangePath';

interface IPermissions {
	companiesView?: boolean;
	adminObjectsView?: boolean;
	exportTemplatesView?: boolean;
	usersView?: boolean;
	rolesView?: boolean;
	actionsLogView?: boolean;
	regulatoryStandardsView?: boolean;
	problemTemplatesSetsView?: boolean;
}

/**
 * Возвращает полномочия для текущего пользователя
 *
 * @param {IUser?} user текущий пользователь
 */
const getPermissions = memoizeOne(
	(userRoles: IUserRole[]): IPermissions => ({
		companiesView: hasPermission(userRoles, 'companiesView'),
		adminObjectsView: hasPermission(userRoles, 'objectsView'),
		usersView: hasPermission(userRoles, 'usersView'),
		rolesView: hasPermission(userRoles, 'rolesView'),
		regulatoryStandardsView: hasPermission(userRoles, 'regulatoryStandardsView'),
		actionsLogView: hasPermission(userRoles, 'actionsLogView'),
		problemTemplatesSetsView: hasPermission(userRoles, 'problem-templates-sets-view')
	})
);

const companyIcon = <i className="tz-company-28"/>;
const objectIcon = <i className="tz-object-28"/>;
const userIcon = <i className="tz-user-28"/>;
const roleIcon = <i className="tz-role-28"/>;
const problemIcon = <i className="tz-problem-28"/>;
const regStandardIcon = <i className="tz-reg-standard-28"/>;
const logIcon = <i className="tz-log-28"/>;

/**
 * Стартовая страница
 */
export const DashboardPage = () => {
	const {pushPath} = useChangePath();
	const userRoles = useAppSelector(extractUserRoles);
	const permissions = getPermissions(userRoles);
	const goToCompanies = () => {
		pushPath('/companies');
	};
	const goToObjects = () => {
		pushPath('/objects');
	};
	const goToUsers = () => {
		pushPath('/users');
	};
	const goToRoles = () => {
		pushPath('/roles');
	};
	const goToProblems = () => {
		pushPath('/problem-templates-sets');
	};
	const goToRegStandards = () => {
		pushPath('/reg-standards');
	};
	const goToLogs = () => {
		pushPath('/logs');
	};
	return (
		<div className="page-cont">
			<div className="row">
				<div className="container">
					<Plate withoutPadding>
						{permissions.companiesView && (
							<LinkButton
								className="dashboard-page__link-button"
								label="Компании"
								leftIcon={companyIcon}
								type="common"
								onClick={goToCompanies}
							/>
						)}

						{permissions.adminObjectsView && (
							<LinkButton
								className="dashboard-page__link-button"
								label="Объекты"
								leftIcon={objectIcon}
								type="common"
								onClick={goToObjects}
							/>
						)}

						{permissions.usersView && (
							<LinkButton
								className="dashboard-page__link-button"
								label="Пользователи"
								leftIcon={userIcon}
								type="common"
								onClick={goToUsers}
							/>
						)}

						{permissions.rolesView && (
							<LinkButton
								className="dashboard-page__link-button"
								label="Роли"
								leftIcon={roleIcon}
								type="common"
								onClick={goToRoles}
							/>
						)}

						{permissions.problemTemplatesSetsView && (
							<LinkButton
								className="dashboard-page__link-button"
								label="Наборы шаблонов нарушений"
								leftIcon={problemIcon}
								type="common"
								onClick={goToProblems}
							/>
						)}

						{permissions.regulatoryStandardsView && (
							<LinkButton
								className="dashboard-page__link-button"
								label="Справочник СП"
								leftIcon={regStandardIcon}
								type="common"
								onClick={goToRegStandards}
							/>
						)}

						{permissions.actionsLogView && (
							<LinkButton
								className="dashboard-page__link-button"
								label="Лог действий"
								leftIcon={logIcon}
								type="common"
								onClick={goToLogs}
							/>
						)}
					</Plate>
				</div>
			</div>
		</div>
	);
};
