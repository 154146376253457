import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {IArchiveObjectResponse, makeObjectArchiveRequest} from '@src/api/backend/object';
import {addErrorToast} from '@src/utils/toasts';

export type IArchiveObjectPayload = IArchiveObjectResponse;

const request = () => ({type: types.ARCHIVE_REQUEST});

const success = (response: IArchiveObjectResponse) => ({
	type: types.ARCHIVE_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	if (error.statusCode !== 401) {
		addErrorToast('Ошибка', 'при архивации объекта');
	}
	return {
		type: types.ARCHIVE_FAILURE,
		payload: {...error}
	};
};

/**
 * Архивирует объект
 *
 * @param objectId id объекта
 */
export const archiveObject = (objectId: string) =>
	createApiAction<IArchiveObjectResponse>(request, success, failure, () =>
		makeObjectArchiveRequest(objectId));
