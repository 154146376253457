import React from 'react';
import './ProblemTemplatesSetsPage.less';
import AppBreadcrumbs from '@src/components/AppBreadcrumbs';
import {Button} from '@tehzor/ui-components';
import {IBreadcrumb} from '@tehzor/ui-components/src/components/navigation/Breadcrumbs';
import {TemplatesSetsTable} from '@src/pages/ProblemTemplatesSetsPage/components/table/TemplatesSetsTable';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {RESET} from '@src/store/modules/entities/problemTemplatesSet/constants';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import useAsync from 'react-use/lib/useAsync';
import {getTemplatesSets} from '@src/store/modules/entities/problemTemplatesSets/actions';
import {useChangePath} from '@src/core/hooks/useChangePath';

const breadcrumbs: IBreadcrumb[] = [];

export const ProblemTemplatesSetsPage = () => {
	const dispatch = useAppDispatch();
	const {pushPath} = useChangePath();

	useAppHeader(
		{
			title: 'Наборы шаблонов нарушений'
		},
		[]
	);

	useAsync(async () => {
		await dispatch(getTemplatesSets('all'));
	}, []);

	return (
		<div className="page-cont">
			<AppBreadcrumbs items={breadcrumbs}>
				<Button
					type="accent-blue"
					leftIcon={<i className="tz-plus-20"/>}
					label="Новый набор шаблонов"
					onClick={() => {
						dispatch({type: RESET});
						pushPath('/problem-templates-sets/add');
					}}
				/>
			</AppBreadcrumbs>

			<TemplatesSetsTable/>
		</div>
	);
};
