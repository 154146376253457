import {Select, Option} from '@tehzor/ui-components';
import React, {useContext} from 'react';
import {IntervalsCtx, PeriodsCtx} from './SubsTable';
import {RoleDispatchCtx, RoleSubsCtx} from '../../RolePage';
import {INotificationMessageSettingTypes} from '@tehzor/tools/interfaces/notifications/INotificationMessageSettingTypes';

interface ISettingProps {
	typeId: string;
	settingKey: string;
	name: string;
	type?: INotificationMessageSettingTypes;
}

const PeriodSetting = ({typeId, settingKey, name}: ISettingProps) => {
	const periods = useContext(PeriodsCtx);
	const subs = useContext(RoleSubsCtx);
	const dispatch = useContext(RoleDispatchCtx);

	const current = subs.find(s => s.type === typeId);
	const selected = (current && current.settings && current.settings[settingKey]) || undefined;

	const handleChange = (value: string[]) => {
		if (current && current.channels.length) {
			dispatch({
				type: 'update-subs',
				typeId,
				channels: current.channels,
				settings: current.settings
					? {
							...current.settings,
							[settingKey]: value
					  }
					: {[settingKey]: value}
			});
		}
	};

	return (
		<div className="role-page__settings-select-wrap">
			<div className="role-page__settings-label-wrap">
				<span className="role-page__settings-label">{name}</span>
				<span className="role-page__settings-id">
					(
					{settingKey}
					)
				</span>
			</div>

			<Select
				wrapTags
				allowClear
				className="role-page__settings-select"
				value={selected}
				disabled={!current || !current.channels.length}
				onChange={handleChange}
			>
				{periods.map(period => (
					<Option
						key={period.id}
						value={+period.id}
					>
						{period.name}
					</Option>
				))}
			</Select>
		</div>
	);
};

const IntervalsSetting = ({typeId, settingKey, name}: ISettingProps) => {
	const intervals = useContext(IntervalsCtx);
	const subs = useContext(RoleSubsCtx);
	const dispatch = useContext(RoleDispatchCtx);

	const current = subs.find(s => s.type === typeId);
	const selected = (current && current.settings && current.settings[settingKey]) || [];

	const handleChange = (value: string[]) => {
		if (current && current.channels.length) {
			dispatch({
				type: 'update-subs',
				typeId,
				channels: current.channels,
				settings: current.settings
					? {
							...current.settings,
							[settingKey]: value
					  }
					: {[settingKey]: value}
			});
		}
	};

	return (
		<div className="role-page__settings-select-wrap">
			<div className="role-page__settings-label-wrap">
				<span className="role-page__settings-label">{name}</span>
				<span className="role-page__settings-id">
					(
					{settingKey}
					)
				</span>
			</div>

			<Select
				className="role-page__settings-select"
				value={selected}
				disabled={!current || !current.channels.length}
				multiple
				wrapTags
				onChange={handleChange}
			>
				{intervals.map(interval => (
					<Option
						key={interval.id}
						value={+interval.id}
					>
						{interval.name}
					</Option>
				))}
			</Select>
		</div>
	);
};

const Setting = (props: ISettingProps) => {
	if (props.type === 'intervals') {
		return <IntervalsSetting {...props}/>;
	}
	if (props.type === 'period') {
		return <PeriodSetting {...props}/>;
	}
	return null;
};

export default Setting;
