import {wsConnector} from '@src/api/backend/wsConnector';
import {IProblemTemplatesSet} from '@tehzor/tools/interfaces/problemTemplatesSets/IProblemTemplatesSet';
import {ISavingProblemTemplatesSet} from '@tehzor/tools/interfaces/problemTemplatesSets/ISavingProblemTemplatesSet';

export type IAddTemplatesSetResponse = IProblemTemplatesSet;

/**
 * Добавляет набор шаблонов нарушений
 */
export const makeAddTemplatesSetRequest = (data: ISavingProblemTemplatesSet) =>
	wsConnector.sendAuthorizedRequest<IAddTemplatesSetResponse>('addProblemTemplatesSet', {...data});
