import React, {useCallback} from 'react';
import {AuthForm, Logo} from '@tehzor/ui-components';
import './LoginPage.less';
import {CSSTransition} from 'react-transition-group';
import useMount from 'react-use/lib/useMount';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {login} from '@src/store/modules/auth/actions';
import {useLocation} from 'react-router-dom';
import {useChangePath} from '@src/core/hooks/useChangePath';

/**
 * Компонент формы ввода логина и пароля
 */
export const LoginPage = () => {
	const location = useLocation();
	const isAuthenticated = useAppSelector(s => s.auth.isAuthenticated);
	const dispatch = useAppDispatch();
	const {pushPath} = useChangePath();
	const redirectLocation = (location.state as {redirect: string} | null)?.redirect;
	useMount(() => {
		if (isAuthenticated) {
			pushPath('/');
		}
	});

	const authenticateUser = useCallback(
		async (email: string, password: string) => {
			await dispatch(login(email, password));

			let redirect = '/';
			if (location.state && redirectLocation) {
				if (redirectLocation !== '/login' && redirectLocation !== '/logout') {
					redirect = redirectLocation;
				}
			}
			pushPath(redirect);
		},
		[location]
	);

	return (
		<CSSTransition
			classNames="login-page"
			in
			appear
			timeout={300}
		>
			<div className="login-page">
				<Logo className="login-page__logo"/>
				<div className="login-page__container">
					<AuthForm
						className="login-page__container-auth-form"
						onLogin={authenticateUser}
					/>
					<div className="login-page__container-background">
						<div className="login-page__container-background__left"/>
						<div className="login-page__container-background__right"/>
					</div>
				</div>
			</div>
		</CSSTransition>
	);
};
