import {wsConnector} from '../wsConnector';
import ISavingUser from '@tehzor/tools/interfaces/users/ISavingUser';
import IUser from '@tehzor/tools/interfaces/IUser';

export type IEditUserResponse = IUser;

/**
 * Изменяет пользователя
 *
 * @param userId id пользователя
 * @param data данные пользователя
 */
export const makeUserEditRequest = (userId: string, data: ISavingUser) =>
	wsConnector.sendAuthorizedRequest<IEditUserResponse>('editUser', {userId, ...data});
