import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import {extractUserRoles} from '@src/store/modules/auth/profile/selectors';
import {getScopeIds, hasPermission} from '@tehzor/tools/utils/findPermission';
import arrayToTree, {Tree} from 'array-to-tree';
import findTreeNode from '@tehzor/tools/utils/findTreeNode';
import hasDescendant from '@tehzor/tools/utils/hasDescendant';
import {UserRoleScopes} from '@tehzor/tools/interfaces/IUser';
import {handlePagination} from '@src/utils/handlePagination';

const getAllIds = (state: IState) => state.entities.objects.allIds || [];
const getById = (state: IState): {[id: string]: IObject} => state.entities.objects.byId || {};
const getObjectId = (state: IState, props: {objectId?: string}) => props.objectId || '';
const getCompanyId = (state: IState, props: {companyId?: string}) => props.companyId;

export const getObjectsPageOffset = (state: IState) => state.settings.pages.objects.offset || 0;
export const getObjectsPageSize = (state: IState) => state.settings.pages.objects.pageSize || 20;
export const getObjectsFilter = (state: IState) => state.settings.pages.objects.filter;
export const getObjectsSettings = (state: IState) => state.settings.pages.objects;
/**
 * Возвращает объекты в виде массива
 */
export const getObjectsAsArray = createSelector([getAllIds, getById], (allIds, byId) =>
	allIds.map((id: string) => byId[id])
);

/**
 * Возвращает отфильтрованный список объектов
 */
export const extractFilteredObjects = createSelector(
	[getObjectsAsArray, getObjectsSettings],
	(objects, settings) => {
		const {filter, pageSize, offset} = settings;
		const {companiesIds, name} = filter;
		const objectNameFilter = name?.toLowerCase().trim();

		const filterByName = (object: IObject) =>
			!objectNameFilter || object.name.toLowerCase().includes(objectNameFilter);

		const filterByCompany = (object: IObject) =>
			!companiesIds?.length || companiesIds.includes(object.companyId);

		const filteredArr = objects.filter(
			object => filterByCompany(object) && filterByName(object)
		);

		const paginatedArr = filteredArr.filter((item, i) => handlePagination(i, offset, pageSize));

		return {
			data: paginatedArr,
			pageCount: Math.ceil(filteredArr.length / pageSize),
			currentPage: Math.floor(offset / pageSize)
		};
	}
);

/**
 * Возвращает текущий объект
 */
// export const getCurrentObject = createSelector(
// 	[getById, getObjectId],
// 	(byId: {}, objectId: string) => byId[objectId] || ({name: ''} as IObject)
// );

/**
 * Возвращает текущий объект
 */
export const extractCurrentObject = createSelector(
	getById,
	(state: IState, objectId?: string) => objectId,
	(byId: {[id: string]: IObject}, objectId?: string) =>
		objectId ? byId[objectId] || ({name: ''} as IObject) : undefined
);

/**
 * Возвращает объекты в виде дерева
 */
export const getObjectsAsTree = createSelector([getObjectsAsArray], objects =>
	arrayToTree<IObject>(objects, {
		parentProperty: 'parentId',
		customID: 'id'
	})
);

/**
 * Возвращает текущий объект из дерева
 */
export const getCurrentTreeObject = createSelector(
	[getObjectsAsTree, getObjectId],
	(tree, objectId) => findTreeNode(tree, objectId) || ({name: ''} as Tree<IObject>)
);

/**
 * Возвращает объекты, которые могут использоваться в качестве родительского при добавлении/редактировании объекта
 */
export const getObjectsForParent = createSelector(
	[getObjectId, getCompanyId, getObjectsAsArray, getCurrentTreeObject, extractUserRoles],
	(objectId, companyId, objects: IObject[], tree, userRoles) => {
		let result: IObject[];
		if (
			hasPermission(
				userRoles,
				objectId ? 'adminObjectsEdit' : 'adminObjectsAdd',
				UserRoleScopes.ALL
			)
		) {
			result = objects;
		} else {
			const ids = getScopeIds(
				userRoles,
				UserRoleScopes.OBJECT,
				objectId ? 'adminObjectsEdit' : 'adminObjectsAdd'
			);
			result = objects.filter(object => ids.includes(object.id));
		}
		result = result.filter(object => object.companyId === companyId);
		if (objectId) {
			result = result.filter(
				object => object.id !== objectId && !hasDescendant(tree, object.id)
			);
		}
		return result;
	}
);
