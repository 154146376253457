import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {AppThunkAction} from '@src/store/interfaces/thunk';
import {createApiAction} from '@src/store/middlewares/api';
import {IAddTemplatesSetResponse, makeAddTemplatesSetRequest} from '@src/api/backend/problemTemplatesSet';
import {ISavingProblemTemplatesSet} from '@tehzor/tools/interfaces/problemTemplatesSets/ISavingProblemTemplatesSet';

export type IAddTemplatesSetPayload = IAddTemplatesSetResponse;

const request = (data: ISavingProblemTemplatesSet) => ({
	type: types.ADD_REQUEST,
	payload: data
});

// Здесь метод replacePath не работает и приводит к сайд эффектам
// replacePath(`/problem-templates-sets/${response.id}`);
const success = (response: IAddTemplatesSetResponse) => ({
	type: types.ADD_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	if (error.statusCode !== 401) {
		addErrorToast('Ошибка', 'при добавлении набора шаблонов нарушений');
	}

	return {
		type: types.ADD_FAILURE,
		payload: error
	};
};

/**
 * Добавляет новый набор шаблонов нарушений
 */
export const addTemplatesSet
	= (data: ISavingProblemTemplatesSet): AppThunkAction<Promise<IAddTemplatesSetResponse>> =>
	dispatch =>
		dispatch(
			createApiAction<IAddTemplatesSetResponse>(
				() => request(data),
				success,
				failure,
				() => makeAddTemplatesSetRequest(data)
			)
		);
