import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import * as authTypes from '../constants';
import IUser from '@tehzor/tools/interfaces/IUser';

export type IProfileState = IUser;

const getInitialState = (): IProfileState => ({
	id: '',
	firstName: '',
	lastName: '',
	fullName: '',
	displayName: ''
});

export const profile = createReducer<IProfileState>(getInitialState(), {
	[authTypes.LOGIN_SUCCESS]: (state, {payload}: {payload: IUser}) => payload,
	[authTypes.LOGIN_FAILURE]: getInitialState,
	[authTypes.UPDATE_TOKENS_FAILURE]: getInitialState,
	[authTypes.LOGOUT]: getInitialState,
	[types.GET_SUCCESS]: (state, {payload}: {payload: IUser}) => payload,
	[types.EDIT_SUCCESS]: (state, {payload}: {payload: IUser}) => payload
});
