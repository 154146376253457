import * as types from '@src/constants/notifications/periods';
// @ts-ignore
import {CALL_BACKEND_API} from '../../middlewares/backendMiddleware';
// @ts-ignore
import SocketConnector from '../../api/SocketConnector';
import {IState} from '@src/store/modules';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import INotificationPeriod from '@tehzor/tools/interfaces/INotificationPeriod';
import IError from '@tehzor/tools/interfaces/IError';

const socketConnector = SocketConnector.instance;

/**
 * Получает список каналов уведомлений
 *
 * @returns {Function}
 */
export const getPeriods = () => (dispatch: any, getState: () => IState) => {
	const periods = getState().notifications.periods;
	if (periods.loaded) {
		return Promise.resolve(periods);
	}
	return dispatch({
		[CALL_BACKEND_API]: {
			actions: [getPeriodsRequest, getPeriodsSuccess, getPeriodsFailure],
			promise: socketConnector.getNotificationPeriods
		}
	});
};

const getPeriodsRequest = () => ({
	type: types.GET_NOTIFICATION_PERIODS_REQUEST
});

const getPeriodsSuccess = (response: INormalizedData<INotificationPeriod>) => ({
	type: types.GET_NOTIFICATION_PERIODS_SUCCESS,
	payload: {...response}
});

const getPeriodsFailure = (error: IError) => ({
	type: types.GET_NOTIFICATION_PERIODS_FAILURE,
	payload: {...error}
});
