import React, {useCallback, useState} from 'react';
import './EditableCompanyTags.less';
import ICompany from '@tehzor/tools/interfaces/companies/ICompany';
import {ITagDataItem} from '@tehzor/ui-components/src/components/tags';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractCompaniesAsArray} from '@src/store/modules/entities/companies/selectors';
import {LinkButton, SelectDialog, Tags} from '@tehzor/ui-components';
import SelectOption from '@tehzor/ui-components/src/components/inputs/select/SelectOption';
import Select from '@tehzor/ui-components/src/components/inputs/select/Select';
import {useDispatchContext} from '@src/components/EditableProblemTemplatesSet/hooks/useEditableProblemTemplatesSet';
import {useUpdateEffect} from 'react-use';

interface IEditableCompanyTagsProps {
	disabled?: boolean;
	label: string;
	data: string[];
}

const createTagsFromIds = (ids: string[], companies: ICompany[]) =>
	ids.reduce((acc: ITagDataItem[], id) => {
		const found = companies.find(company => company.id === id);

		if (!found) {
			return acc;
		}

		acc.push({
			id,
			label: found.name
		});

		return acc;
	}, []);

const companyToTag = (company: ICompany) => ({
	id: company.id,
	label: company.name
});

export const EditableCompanyTags = ({data, label, disabled}: IEditableCompanyTagsProps) => {
	const dispatch = useDispatchContext();
	const companies = useAppSelector(extractCompaniesAsArray);
	const [tags, setTags] = useState(() => createTagsFromIds(data, companies));
	const [selected, setSelected] = useState<string[]>([]);

	useUpdateEffect(() => {
		let selectedIds: string[];

		if (data.length && !selected.length) {
			selectedIds = data;
			setSelected(selectedIds);
		} else {
			selectedIds = selected;
		}

		const selectedCompanies = companies.filter(({id}) => selectedIds.includes(id));
		setTags(selectedCompanies.map(companyToTag));
	}, [selected, data, companies]);

	const handleAdd = useCallback(
		(ids: string[]) => {
			const selectedCompanies = companies.filter(({id}) => ids.includes(id));
			setTags(selectedCompanies.map(companyToTag));

			dispatch({type: 'update', value: ids, field: 'companies'});
			setSelected(ids);
		},
		[companies]
	);

	const handleRemove = useCallback(
		(removedId: string) => {
			setSelected(prev => prev.filter(id => id !== removedId));
			dispatch({type: 'update', value: selected.filter(id => id !== removedId), field: 'companies'});
		},
		[selected]
	);

	return (
		<div className="editable-company-tags">
			<div className="editable-company-tags__header">
				<div className="editable-company-tags__header-title">{label}</div>
				<div className="editable-company-tags__header-buttons">
					<SelectDialog
						title="Выберите компании"
						value={selected}
						onChange={handleAdd}
						trigger={(
							<LinkButton
								disabled={disabled}
								leftIcon={<i className="tz-plus-24"/>}
								label="Добавить компанию"
							/>
						)}
					>
						<Select multiple>
							{companies.map(company => (
								<SelectOption
									key={company.id}
									itemKey={company.id}
									content={company.name}
									style={{display: 'block'}}
								/>
							))}
						</Select>
					</SelectDialog>
				</div>
			</div>
			<div className="editable-company-tags__content">
				<Tags
					color="#F6E3FA"
					data={tags}
					onDelete={handleRemove}
				/>
			</div>
		</div>
	);
};
