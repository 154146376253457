import {useMemo} from 'react';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {IObjectManager} from '@tehzor/tools/interfaces/objects/IObject';
import IUploadingFile from '@tehzor/tools/interfaces/IUploadingFile';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {IEditableObjectState, IObjectErrors, objectActions} from '@src/store/modules/entities/object/objectSlice';
import {getObjectForm, getObjectValidateErrors} from '@src/store/modules/entities/object/selectors';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';
import IFile from '@tehzor/tools/interfaces/IFile';

const enum ValidateErrors {
	RequiredField = 'Поле обязательно для заполнения',
	InvalidPhoneNumber = 'Некорректный номер телефона'
}

const hasValidateErrors = (obj?: IObjectErrors): boolean => {
	if (!obj) return false;
	return Object.values(obj).some(v => v !== undefined);
};
export const useObjectForm = () => {
	const formState = useAppSelector(getObjectForm);
	const validateErrors = useAppSelector(getObjectValidateErrors);
	const dispatch = useAppDispatch();

	const validate = (values: IEditableObjectState) => {
		const phoneRegex = /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;

		const errors: IObjectErrors = {};

		if (!values.name?.trim()) {
			errors.name = ValidateErrors.RequiredField;
		} else {
			errors.name = undefined;
		}

		if (!values.companyId) {
			errors.companyId = ValidateErrors.RequiredField;
		} else {
			errors.companyId = undefined;
		}

		if (values.projectManager?.phone?.length) {
			if (!phoneRegex.test(values.projectManager.phone)) {
				errors.projectManager = ValidateErrors.InvalidPhoneNumber;
			} else {
				errors.projectManager = undefined;
			}
		} else {
			errors.projectManager = undefined;
		}

		if (values.constructionManager?.phone?.length) {
			if (!phoneRegex.test(values.constructionManager.phone)) {
				errors.constructionManager = ValidateErrors.InvalidPhoneNumber;
			} else {
				errors.constructionManager = undefined;
			}
		} else {
			errors.constructionManager = undefined;
		}

		const hasClearedErrors = Object.keys(errors).some(key => errors[key] === undefined && validateErrors?.[key]);
		if (hasValidateErrors(errors) || hasClearedErrors) {
			dispatch(objectActions.updateValidateErrors(errors));
		}
	};

	useUpdateEffect(() => {
		validate(formState);
	}, [formState]);

	const isValid = !hasValidateErrors(validateErrors);

	const createOnChangeCallback
		= <T extends string | string[] | IObjectManager | IFile>(field: keyof IEditableObjectState) =>
		(value: T) => {
			dispatch(objectActions.updateObject({[field]: value}));
			if (field !== 'spaceIndicatorsSet' && field !== 'problemTemplatesSet') {
				dispatch(objectActions.setEdited(true));
			}
		};

	const methods = useMemo(
		() => ({
			onNameChange: createOnChangeCallback<string>('name'),
			onFullNameChange: createOnChangeCallback<string>('fullName'),
			onCompanyChange: createOnChangeCallback<string>('companyId'),
			onExternalIdChange: createOnChangeCallback<string>('externalId'),
			onParentChange: createOnChangeCallback<string>('parentId'),
			onCityChange: createOnChangeCallback<string>('city'),
			onAddressChange: createOnChangeCallback<string>('address'),
			onGeneralContractorChange: createOnChangeCallback<string>('generalContractor'),
			onStageChange: createOnChangeCallback<ObjectStageIds>('stage'),
			onSpaceIndicatorsSetChange: createOnChangeCallback<string>('spaceIndicatorsSet'),
			onProblemTemplatesSetChange: createOnChangeCallback<string>('problemTemplatesSet'),
			onIncObjectChange: createOnChangeCallback<string>('incObjectId'),
			onObjectIdsForIncChange: createOnChangeCallback<string[]>('objectIdsForInc'),
			onProjectManagerChange: createOnChangeCallback<IObjectManager>('projectManager'),
			onConstructionManagerChange: createOnChangeCallback<IObjectManager>('constructionManager'),
			onImageChange: createOnChangeCallback<IFile>('image'),
			onStagesChange: (stageId: ObjectStageIds, dateFrom?: number, dateTo?: number) => {
				dispatch(objectActions.updateStage({stageId, dateFrom, dateTo}));
				dispatch(objectActions.setEdited(true));
			},
			onNewImageChange: (newImage?: IUploadingFile[]) => {
				if (newImage) {
					dispatch(objectActions.updateObject({newImage}));
					dispatch(objectActions.setEdited(true));
				}
			}
		}),
		[dispatch]
	);
	return {formState, isValid, validateErrors, methods};
};
