import React, {useContext} from 'react';
import {IState} from '@src/store/modules';
import {connect} from 'react-redux';
import IRolePermission from '@tehzor/tools/interfaces/IRolePermission';
import arrayToTree from 'array-to-tree';
import {getRolePermissionsAsArray} from '@src/selectors/entities/rolePermissions';
import memoizeOne from 'memoize-one';
import IPermission from '@tehzor/tools/interfaces/IPermission';
import {RolePermissionsCtx} from '../../RolePage';
import InnerTable from './InnerTable';
import {IProblemStatus} from '@tehzor/tools/interfaces/problems/IProblemStatus';
import {extractProblemStatusesAsArray} from '@src/store/modules/entities/problemStatuses/selectors';

interface IPermissionWithChildren extends IRolePermission {
	children?: IPermissionWithChildren[];
}

interface IPreparedPermission extends IPermissionWithChildren {
	children?: IPreparedPermission[];
	level?: number;
}

interface IPermissionsTableProps {
	rolePermissions: IRolePermission[];
	problemStatuses: IProblemStatus[];
}

const TreeDataCtx = React.createContext<IPreparedPermission[]>([]);
const SemiSelectedCtx = React.createContext<string[]>([]);
const ProblemStatusesCtx = React.createContext<IProblemStatus[]>([]);

/**
 * Добавляет к элементам свойство level
 *
 * @param {IPermissionWithChildren[]} treeData дерево полномочий
 * @param {number} level начальный индекс
 */
const fillLevel = (treeData: IPermissionWithChildren[], level: number): IPreparedPermission[] =>
	treeData.map(item => ({
		...item,
		children: item.children && fillLevel(item.children, level + 1),
		level
	}));

/**
 * Преобразовывает массив полномочий в дерево
 *
 * @param {IRolePermission[]} data массив полномочий
 */
const convertPermissions = memoizeOne((data: IRolePermission[]): IPreparedPermission[] => {
	const treeData = arrayToTree<IRolePermission>(data, {
		parentProperty: 'parentId',
		customID: 'id'
	});
	return fillLevel(treeData, 0);
});

/**
 * Возвращает массив id частично выбранных элементов
 *
 * @param {IPreparedPermission[]} treeData дерево полномочий
 * @param {IPermission[]} selected выбранные элементы
 */
const getSemiSelected = (treeData: IPreparedPermission[], selected: IPermission[]): string[] => {
	let result: string[] = [];
	for (const item of treeData) {
		const semi: string[] = [];
		if (item.children) {
			semi.push(...getSemiSelected(item.children, selected));
			const currentSelected = item.children.filter(p => selected.some(s => s.permissionId === p.id));

			if (semi.length || (currentSelected.length > 0 && currentSelected.length < item.children.length)) {
				semi.push(item.id);
			}
		}
		result = result.concat(semi);
	}
	return result;
};

const getTbodyProps = () => ({className: 'role-page__ptable-tbody'});

const PermissionsTable = ({rolePermissions, problemStatuses}: IPermissionsTableProps) => {
	const permissions = useContext(RolePermissionsCtx);
	const data = convertPermissions(rolePermissions);
	const semiSelected = getSemiSelected(data, permissions);

	return (
		<TreeDataCtx.Provider value={data}>
			<SemiSelectedCtx.Provider value={semiSelected}>
				<ProblemStatusesCtx.Provider value={problemStatuses}>
					<div className="field__wide-label-wrap">
						<div className="field__label">Полномочия:</div>
					</div>

					<InnerTable
						data={data}
						getTbodyProps={getTbodyProps}
					/>
				</ProblemStatusesCtx.Provider>
			</SemiSelectedCtx.Provider>
		</TreeDataCtx.Provider>
	);
};

const mapStateToProps = (state: IState) =>
	({
		rolePermissions: getRolePermissionsAsArray(state),
		problemStatuses: extractProblemStatusesAsArray(state)
	} as IPermissionsTableProps);

export {IPreparedPermission, TreeDataCtx, SemiSelectedCtx, ProblemStatusesCtx};
export default connect(mapStateToProps)(PermissionsTable);
