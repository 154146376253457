import React, {memo} from 'react';
import './UsersPage.less';
import {Button, LoadingPanel} from '@tehzor/ui-components';
import useAsync from 'react-use/lib/useAsync';
import {getUsers} from '@src/store/modules/entities/users/actions';
import {getCompanies} from '@src/store/modules/entities/companies/actions';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {getObjects} from '@src/store/modules/entities/objects/actions';
import {UsersFilter} from '@src/pages/UsersPage/components/UsersFilter/UsersFilter';
import {UsersTable} from '@src/pages/UsersPage/components/UsersTable/UsersTable';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {getRoles} from '@src/store/modules/entities/roles/actions/get';

const plusIcon = <i className="tz-plus-24"/>;

export const UsersPage = memo(() => {
	const dispatch = useAppDispatch();
	const {pushPath} = useChangePath();

	useAppHeader(
		{
			title: 'Пользователи'
		},
		[]
	);

	const loadingState = useAsync(async () => {
		await Promise.allSettled([
			dispatch(getUsers()),
			dispatch(getCompanies()),
			dispatch(getRoles()),
			dispatch(getObjects())
		]);
	}, []);

	const goToUserAdding = () => {
		pushPath('/users/add');
	};

	return (
		<LoadingPanel
			active={loadingState.loading}
			className="loading-panel_main"
		>
			<div className="page-cont">
				<div className="container">
					<div className="users-page__button-block">
						<Button
							onClick={goToUserAdding}
							leftIcon={plusIcon}
							label="Добавить"
							type="accent-blue"
						/>
					</div>
					<UsersFilter/>
					<UsersTable/>
				</div>
			</div>
		</LoadingPanel>
	);
});
