import {wsConnector} from '../wsConnector';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';

export type IDeleteObjectResponse = IObject;

/**
 * Удаляет объект
 *
 * @param objectId id объекта
 */
export const makeObjectDeleteRequest = (objectId: string) =>
	wsConnector.sendAuthorizedRequest<IDeleteObjectResponse>('deleteObject', {objectId});
