import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';
import {handlePagination} from '@src/utils/handlePagination';

const getAllIds = (state: IState) => state.entities.regulatoryStandards.allIds || [];
const getById = (state: IState) => state.entities.regulatoryStandards.byId || {};
export const getRegStandardsPageOffset = (state: IState) => state.settings.pages.regulatoryStandards.offset || 0;
export const getRegStandardsPageSize = (state: IState) => state.settings.pages.regulatoryStandards.pageSize || 20;
export const getRegStandardsSettings = (state: IState) => state.settings.pages.regulatoryStandards;

/**
 * Возвращает записи в виде массива
 */
export const getRegStandardsAsArray = createSelector(
	[getAllIds, getById],
	(allIds, byId) => allIds.map((id: string) => byId[id])
);

export const getPaginatedRegStandards = createSelector(
	[getRegStandardsAsArray, getRegStandardsSettings],
	(regStandards, settings) => {
		const {offset, pageSize} = settings;
		const paginatedArr = regStandards.filter((item, i) => handlePagination(i, offset, pageSize));
		return {
			data: paginatedArr,
			pageCount: Math.ceil(regStandards.length / pageSize),
			currentPage: Math.floor(offset / pageSize)
		};
	}
);
