import {ColumnDef} from '@tanstack/react-table';
import IUser from '@tehzor/tools/interfaces/IUser';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';

export interface IUserWithObjects extends IUser {
	objects: IObject[];
}

const filterUniqueItems = (items: string[]) => [...new Set(items)];

export const buildUsersColumns: Array<ColumnDef<IUserWithObjects>> = [
	{
		accessorKey: 'fullName',
		header: 'ФИО'
	},
	{
		accessorKey: 'email',
		header: 'Email'
	},
	{
		accessorKey: 'position',
		header: 'Должность'
	},
	{
		accessorKey: 'companies',
		header: 'Компания',
		accessorFn: originalRow => {
			if (originalRow.companies) {
				return filterUniqueItems(originalRow.companies.map(item => item.name)).join(', ');
			}
			return undefined;
		}
	},
	{
		accessorKey: 'objects',
		header: 'Объекты',
		accessorFn: originalRow => filterUniqueItems(originalRow.objects.map(o => o.name)).join(', ')
	},
	{
		accessorKey: 'roles',
		header: 'Роль',
		accessorFn: originalRow => {
			if (originalRow.roles) {
				return filterUniqueItems(originalRow.roles.map(item => item.roleName)).join(', ');
			}
			return undefined;
		}
	},
	{
		accessorKey: 'deleted',
		header: 'Удалён',
		accessorFn: originalRow => (originalRow.deleted ? 'Да' : 'Нет')
	}
];
