import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
// import * as setTypes from '../spaceIndicatorsSet/constants';
import config from '@src/core/config';
import {
	// addEntitySuccess,
	// editEntitySuccess,
	getEntitiesSuccess,
	IEntityState,
	getEntitiesInitialState
} from '@tehzor/tools/utils/reducersHandlers';
import ISpaceIndicatorsSet from '@tehzor/tools/interfaces/spaceIndicatorsSets/ISpaceIndicatorsSet';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';

const {entitiesCacheTime} = config;

export type ISpaceIndicatorsSetsState = IEntityState<ISpaceIndicatorsSet>;

export default createReducer<ISpaceIndicatorsSetsState>(getEntitiesInitialState<ISpaceIndicatorsSet>(), {
	[types.GET_SUCCESS]: getEntitiesSuccess<ISpaceIndicatorsSet>(entitiesCacheTime),
	// [setTypes.ADD_SUCCESS]: addEntitySuccess<IObject>(),
	// [setTypes.EDIT_SUCCESS]: editEntitySuccess<IObject>(),
	// [setTypes.DELETE_SUCCESS]: editEntitySuccess<IObject>(),
	[CLEAR_STORE]: () => getEntitiesInitialState<ISpaceIndicatorsSet>()
});
