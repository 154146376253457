import React from 'react';
import {EditableCompanyInspectors} from '@src/components/editableFields/EditableCompanyInspectors';
import {IEditableCompanyState} from '@src/core/hooks/states/useEditableCompanyState';

interface IInspectorsTabProps {
	editingState: IEditableCompanyState;
}

export const InspectorsTab = ({editingState}: IInspectorsTabProps) => (
	<EditableCompanyInspectors value={editingState.employees}/>
);