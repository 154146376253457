import React from 'react';
import {useCompaniesPermissions} from '@src/core/hooks/permissions/useCompaniesPermissions';
import CompanyAdding from '../CompanyAdding/CompanyAdding';

const CompanyAddingWithPermissions = () => {
	const permissions = useCompaniesPermissions();

	return <>{permissions.canAdd && <CompanyAdding/>}</>;
};

export default CompanyAddingWithPermissions;
