/* eslint-disable no-param-reassign */
import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import * as companyTypes from '../company/constants';
import config from '@src/core/config';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';
import {
	addEntitySuccess,
	deleteEntitySuccess,
	editEntitySuccess,
	getEntitiesSuccess,
	IEntityState,
	getEntitiesInitialState
} from '@tehzor/tools/utils/reducersHandlers';
import ICompany from '@tehzor/tools/interfaces/companies/ICompany';

const {entitiesCacheTime} = config;

export type ICompaniesState = IEntityState<ICompany>;

export default createReducer<ICompaniesState>(getEntitiesInitialState<ICompany>(), {
	[types.GET_SUCCESS]: getEntitiesSuccess<ICompany>(entitiesCacheTime),
	[companyTypes.ADD_SUCCESS]: addEntitySuccess<ICompany>(),
	[companyTypes.EDIT_SUCCESS]: editEntitySuccess<ICompany>(),
	[companyTypes.DELETE_SUCCESS]: deleteEntitySuccess<ICompany>(),
	[CLEAR_STORE]: () => getEntitiesInitialState<ICompany>()
});
