import {createAction} from '@reduxjs/toolkit';
import * as types from '@src/store/modules/entities/roles/constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {createApiAction} from '@src/store/middlewares/api';
import ISavingRole from '@src/interfaces/ISavingRole';
import {IEditRoleResponse, makeRoleEditRequest} from '@src/api/backend/roles/edit';

const editRoleRequest = createAction(types.EDIT_ROLE_REQUEST);
const editRoleSuccess = createAction<IEditRoleResponse>(types.EDIT_ROLE_SUCCESS);
const editRoleFailure = createAction(types.EDIT_ROLE_FAILURE, (error: IError) => {
	if (error.statusCode !== 401) {
		addErrorToast('Ошибка', 'при изменения роли');
	}
	return {
		payload: {...error}
	};
});

export const editRole = (roleId: string, role: ISavingRole) =>
	createApiAction<IEditRoleResponse>(
		editRoleRequest,
		editRoleSuccess,
		editRoleFailure,
		() => makeRoleEditRequest(roleId, role)
);
