import createReducer from '@tehzor/tools/utils/createReducer';
import INotificationMessage from '@tehzor/tools/interfaces/INotificationMessage';
import * as types from '@src/constants/notifications/messages';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';
import {IGetNotificationMessagesResponse} from '@src/actions/notifications/messages';

interface INotificationMessagesState {
	messages: INotificationMessage[];
	offset: number;
	limit: number;
	total: number;
	hasMore: boolean;
}

const initialState = {
	messages: [],
	offset: 0,
	limit: 10,
	total: 0,
	hasMore: false
} as INotificationMessagesState;

const messages = createReducer<INotificationMessagesState>(initialState, {
	[types.UNSUBSCRIBE_FROM_NOTIFICATIONS_REQUEST]: () => ({...initialState}),
	[types.GET_NOTIFICATION_MESSAGES_REQUEST]: state => ({
		...state,
		hasMore: false
	}),
	[types.GET_NOTIFICATION_MESSAGES_SUCCESS]: (state, payload: IGetNotificationMessagesResponse) => ({
		messages: [...state.messages, ...payload.messages],
		offset: payload.offset + payload.limit,
		limit: payload.limit,
		total: payload.total,
		hasMore: payload.total > payload.offset + payload.limit
	}),
	[types.STORE_NOTIFICATION_MESSAGE]: (state, payload: INotificationMessage) => ({
		messages: [payload, ...state.messages],
		offset: state.offset + 1,
		limit: state.limit,
		total: state.total + 1,
		hasMore: state.hasMore
	}),
	[types.READ_NOTIFICATION_MESSAGE_SUCCESS]: (state, payload: {id: string}) => ({
		messages: state.messages.filter(m => m.id !== payload.id),
		offset: state.offset - 1,
		limit: state.limit,
		total: state.total - 1,
		hasMore: state.hasMore
	}),
	[types.CLEAR_NOTIFICATION_MESSAGES]: () => ({...initialState}),
	[CLEAR_STORE]: () => ({...initialState})
});

export {INotificationMessagesState};
export default messages;
