import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {SortingState} from '@tanstack/react-table';

interface IDate {
	from: Date;
	to: Date;
}

export interface ICompaniesPageSettingsState {
	filter: {
		companiesIds?: string[];
		customersIds?: string[];
		createdAt?: IDate;
		modifiedAt?: IDate;
	};
	sorting: SortingState;
	pageSize: number;
	offset: number;
}

const initialState: ICompaniesPageSettingsState = {
	filter: {},
	sorting: [],
	pageSize: 20,
	offset: 0
};

const companiesSettingsSlice = createSlice({
	name: 'companiesSettings',
	initialState,
	reducers: {
		setFilter: (state, action: PayloadAction<ICompaniesPageSettingsState['filter']>) => {
			state.filter = {...state.filter, ...action.payload};
		},
		clearFilter: state => {
			state.filter = {};
		},
		setSort: (state, action: PayloadAction<ICompaniesPageSettingsState['sorting']>) => {
			state.sorting = action.payload;
		},
		setPageSize: (state, action: PayloadAction<number>) => {
			state.pageSize = action.payload;
		},
		setOffset: (state, action: PayloadAction<number>) => {
			state.offset = action.payload;
		}
	}
});

export const {
	actions: companiesSettingsActions,
	reducer: companiesSettingsReducer
} = companiesSettingsSlice;