import {createReducer} from '@reduxjs/toolkit';
import * as types from '@src/constants/notifications/subscriptions';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';
import INotificationSubscription from '@tehzor/tools/interfaces/INotificationSubscription';
import {
	getLoadedEntitiesSuccess,
	ILoadedEntityState,
	getLoadedEntitiesInitialState
} from '@tehzor/tools/utils/reducersHandlers';

export type INotificationsSubscriptionsState = ILoadedEntityState<INotificationSubscription>;

export default createReducer(getLoadedEntitiesInitialState(), {
	[types.GET_NOTIFICATION_SUBSCRIPTIONS_SUCCESS]: getLoadedEntitiesSuccess<INotificationSubscription>(),
	[types.EDIT_NOTIFICATION_SUBSCRIPTIONS_SUCCESS]: (state, payload) => ({
		allIds: payload.allIds,
		byId: payload.byId,
		loaded: true
	}),
	[CLEAR_STORE]: getLoadedEntitiesInitialState
});
