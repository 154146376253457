import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {IState} from '@src/store/modules';
import checkExpiration from '@tehzor/tools/utils/checkExpiration';
import {ApiAction, createApiAction} from '@src/store/middlewares/api';
import {AppThunkAction} from '@src/store/interfaces/thunk';
import preventExtraLoading from '@tehzor/tools/core/preventExtraLoading';
import {IGetSpaceIndicatorsSetsResponse, requestSpaceIndicatorsSets} from '@src/api/backend/spaceIndicatorsSets';

export type IGetSpaceIndicatorsSetsPayload = IGetSpaceIndicatorsSetsResponse;

const request = () => ({type: types.GET_REQUEST});

const success = (response: IGetSpaceIndicatorsSetsResponse) => ({
	type: types.GET_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	if (error.statusCode !== 401) {
		addErrorToast('Ошибка', 'при загрузке наборов индикаторов помещений');
	}
	return {
		type: types.GET_FAILURE,
		payload: error
	};
};

/**
 * Получает список наборов индикаторов помещений
 */
export const getSpaceIndicatorsSets = () =>
	preventExtraLoading(
		'spaceIndicatorsSets',
		checkExpiration<IState, IGetSpaceIndicatorsSetsResponse, ApiAction>(
			s => s.entities.spaceIndicatorsSets,
			createApiAction<IGetSpaceIndicatorsSetsResponse>(request, success, failure, requestSpaceIndicatorsSets)
		)
	) as AppThunkAction<Promise<IGetSpaceIndicatorsSetsResponse>>;
