import {ThunkAction} from 'redux-thunk';
import {IState} from '@src/store/modules';
import {Action} from 'redux';
import * as types from '../constants';
import {logout as apiLogout} from '@src/api/backend/auth';
import {localAuthStorage} from '@tehzor/tools/api/localAuthStorage';

/**
 * Сбрасывает аутентификацию и перенаправляет на страницу входа
 *
 * @returns {function} action
 */
export const logout = (): ThunkAction<Promise<void>, IState, null, Action> => async dispatch => {
	const deviceId = await localAuthStorage.getDeviceId();
	dispatch({type: types.LOGOUT});
	dispatch({type: types.CLEAR_STORE});

	try {
		await apiLogout(deviceId);
	} catch (error) {
		//
	}
};
