import * as types from '@src/constants/entities/logRecordTypes';
// @ts-ignore
import {CALL_BACKEND_API} from '../../middlewares/backendMiddleware';
// @ts-ignore
import SocketConnector from '../../api/SocketConnector';
import {IState} from '@src/store/modules';
import {addErrorToast} from '@src/utils/toasts';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import IError from '@tehzor/tools/interfaces/IError';
import ILogRecordType from '@tehzor/tools/interfaces/ILogRecordType';

const socketConnector = SocketConnector.instance;

/**
 * Получает список типов записей лога
 *
 * @return {Function}
 */
export const getLogRecordTypes = () => (dispatch: any, getState: () => IState) => {
		const logRecordTypes = getState().entities.logRecordTypes;
		if (logRecordTypes.loaded) {
			return Promise.resolve(logRecordTypes);
		}
		return dispatch({
			[CALL_BACKEND_API]: {
				actions: [getLogRecordTypesRequest, getLogRecordTypesSuccess, getLogRecordTypesFailure],
				promise: socketConnector.getLogRecordTypes
			}
		});
	};

const getLogRecordTypesRequest = () => ({
		type: types.GET_LOG_RECORD_TYPES_REQUEST
	});

const getLogRecordTypesSuccess = (response: INormalizedData<ILogRecordType>) => ({
		type: types.GET_LOG_RECORD_TYPES_SUCCESS,
		payload: {...response}
	});

function getLogRecordTypesFailure(error: IError) {
	if (error.code !== 401) {
		addErrorToast('Ошибка', 'при получении типов записей лога');
	}
	return {
		type: types.GET_LOG_RECORD_TYPES_FAILURE,
		payload: {...error}
	};
}
