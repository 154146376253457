import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';

const extractAllIds = (state: IState) => state.entities.regulatoryStandards.allIds || [];
const extractById = (state: IState) => state.entities.regulatoryStandards.byId || {};

/**
 * Извлекает СП в виде массива
 */
export const extractRegStandardsAsArray = createSelector([extractAllIds, extractById], (allIds, byId) =>
	allIds.map((id: string) => byId[id]));
