import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import config from '@src/core/config';
import {ICategoriesSet} from '@tehzor/tools/interfaces/categoriesSets/ICategoriesSet';
import {IGetCategoriesSetsPayload} from './actions/get';
import {IDeleteCategoriesSetPayload} from './actions/delete';
import {IEditCategoriesSetPayload} from './actions/edit';
import {IAddCategoriesSetPayload} from './actions/add';
import {ILoadableEntitiesState} from '@tehzor/tools/core/storeHelpers/reducers';
// import * as settingsTypes from '../../settings/pages/manage/categoriesSets/constants';

export type ICategoriesSetsState = ILoadableEntitiesState<ICategoriesSet>;

const {entitiesCacheTime} = config;

export const getInitialState = (): ICategoriesSetsState => ({
	byId: {},
	allIds: [],
	loadedAt: undefined,
	loaded: false
});

// const clearExpires = (state: ICategoriesSetsState) => {
// 	state.expires = undefined;
// };

export const categoriesSets = createReducer<ICategoriesSetsState>(getInitialState(), {
	[types.GET_SUCCESS]: (state, {payload}: {payload: IGetCategoriesSetsPayload}) => {
		state.allIds = payload.allIds;
		state.byId = payload.byId;

		state.loadedAt = Date.now() + entitiesCacheTime * 1000;
	},
	[types.DELETE_SUCCESS]: (state, {payload}: {payload: IDeleteCategoriesSetPayload}) => {
		if (state.allIds.includes(payload.id) && state.byId[payload.id]) {
			state.allIds = state.allIds.filter(id => id !== payload.id);
			delete state.byId[payload.id];

			state.loadedAt = undefined;
		}
	},
	[types.ADD_SUCCESS]: (state, {payload}: {payload: IAddCategoriesSetPayload}) => {
		const {id} = payload;

		state.byId[id] = payload;
		state.allIds.push(id);
		state.loadedAt = undefined;
	},
	[types.EDIT_SUCCESS]: (state, {payload}: {payload: IEditCategoriesSetPayload}) => {
		const {id} = payload;

		state.byId[id] = payload;

		state.loadedAt = undefined;
	}
});
