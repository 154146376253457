import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {ISavingObject} from '@tehzor/tools/interfaces/objects/ISavingObject';
import {IAddObjectResponse, makeObjectAddRequest} from '@src/api/backend/object';
import {addErrorToast} from '@src/utils/toasts';
import {createAction} from '@reduxjs/toolkit';

export type IAddObjectPayload = IAddObjectResponse;

export const addObjectRequest = createAction(types.ADD_REQUEST);

export const addObjectSuccess = createAction<IAddObjectResponse>(types.ADD_SUCCESS);

export const addObjectFailure = createAction(types.ADD_FAILURE, (error: IError) => {
	if (error.statusCode !== 401) {
		addErrorToast('Ошибка', 'при добавлении объекта');
	}
	return {
		payload: {...error}
	};
});

/**
 * Добавляет проверку
 *
 * @param object
 */
export const addObject = (object: ISavingObject) =>
	createApiAction<IAddObjectResponse>(
		addObjectRequest,
		addObjectSuccess,
		addObjectFailure,
		() => makeObjectAddRequest(object)
);
