import {ITreeDataItem} from '../../../ui-components/src/components/Tree';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import ICompany from '@tehzor/tools/interfaces/companies/ICompany';

/**
 * Формирует дерево компаний и объектов
 *
 * @param companies компании
 * @param objects объекты
 */
export function makeObjectsTree(objects: IObject[], companies?: ICompany[]): ITreeDataItem[] {
	let result: ITreeDataItem[] = [];
	if (companies && companies.length > 1) {
		result = companies
			.filter(c => objects.some(o => o.companyId === c.id)) // Исключить компании без связанных объектов
			.map(c => ({
				id: c.id,
				content: c.name
			}));
	}
	result = result.concat(
		objects.map(o => ({
			id: o.id,
			content: o.name,
			parentId: o.parentId || o.companyId
		}))
	);
	return result;
}
