import React, {memo} from 'react';
import {CellContext} from '@tanstack/react-table';
import {format} from 'date-fns';
import {dateTimeFormat} from '@tehzor/tools/utils/dateFormats';
import {ICompanyWithCustomer} from '../CompaniesTable/buildCompaniesColumns';

export const ModifiedCell = memo(({row}: CellContext<ICompanyWithCustomer, ICompanyWithCustomer>) => {
	const modifiedAt = format(new Date(row.original.modifiedAt ? row.original.modifiedAt : ''), dateTimeFormat);
	const modifiedBy = row.original.modifiedBy?.fullName;

	return (
		<>
			<span>{modifiedAt}</span>
			<br/>
			<span>{modifiedBy}</span>
		</>
	);
});
