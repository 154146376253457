import {wsConnector} from '../wsConnector';
import ICompany from '@tehzor/tools/interfaces/companies/ICompany';
import ISavingCompany from '@tehzor/tools/interfaces/companies/ISavingCompany';

export type IAddCompanyResponse = ICompany;

/**
 * Добавляет компанию
 *
 * @param company данные компании
 */
export const makeCompanyAddRequest = (company: ISavingCompany) =>
	wsConnector.sendAuthorizedRequest<IAddCompanyResponse>('addCompany', {...company});
