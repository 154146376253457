import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {makeTempFilesDeleteRequest} from '@src/api/backend/tempFile';

const request = () => ({type: types.DELETE_REQUEST});

const success = () => ({type: types.DELETE_SUCCESS});

const failure = (error: IError) => ({type: types.DELETE_FAILURE, payload: {...error}});

/**
 * Удаляет временный файл
 *
 * @param fileId id временного файла
 */
export const deleteTempFile = (fileId: string) =>
	createApiAction<void>(request, success, failure, () => makeTempFilesDeleteRequest(fileId));
