import React, {memo} from 'react';
import {EditableFieldLabel} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractParentObjects} from '@src/store/modules/entities/objects/selectors';
import {SingleSelect} from '@src/components/SingleSelect';

interface IIncObjectFieldProps {
	objectId: string;
	value?: string;
	onChange?: (value: string) => void;
}

export const IncObjectField = memo(({objectId, value, onChange}: IIncObjectFieldProps) => {
	const objects = useAppSelector(s => extractParentObjects(s, objectId));
	return (
		<div className="field">
			<EditableFieldLabel className="field__label">Нумерация по объекту</EditableFieldLabel>
			<SingleSelect
				noHeader
				optionId={value}
				options={objects}
				onChange={onChange}
			/>
		</div>
	);
});
