import {wsConnector} from '../wsConnector';
import IArchivedObject from '@tehzor/tools/interfaces/objects/IArchivedObject';

export type IArchiveObjectResponse = IArchivedObject;

/**
 * Архивирует объект
 *
 * @param objectId id объекта
 */
export const makeObjectArchiveRequest = (objectId: string) =>
	wsConnector.sendAuthorizedRequest<IArchiveObjectResponse>('archiveObject', {objectId});
