import React, {Dispatch} from 'react';
import './EditableProblemTemplatesSet.less';
import IObjectFieldSetting from '@tehzor/tools/interfaces/objects/IObjectFieldSetting';
import {
	IEditableProblemTemplatesSetAction,
	IEditableProblemTemplatesSetState
} from '@src/core/hooks/states/useEditableProblemTemplatesSetState';
import EditableTextField from '@src/components/editableFields/EditableTextField';
import {EditableProblemTemplatesList} from '@src/components/EditableProblemTemplatesSet/components/EditableProblemTemplatesList';
import {EditableCompanyTags} from '@src/components/EditableProblemTemplatesSet/components/EditableCompanyTags';
import {CheckboxField} from '@src/components/editableFields/SelectField/SelectField';

interface IEditableProblemTemplatesSetProps {
	editingState: IEditableProblemTemplatesSetState;
	editingDispatch: Dispatch<IEditableProblemTemplatesSetAction>;
	fieldsSettings: Record<string, IObjectFieldSetting>;
	saving: boolean;
}

/**
 * Интерфейс с визуальными компонентами для редактирования полей
 */
const EditableProblemTemplatesSet = (props: IEditableProblemTemplatesSetProps) => {
	const {editingState, editingDispatch, fieldsSettings, saving} = props;

	return (
		<div className="editable-templates-set">
			<div className="editable-templates-set__info-grid">
				{fieldsSettings.name !== undefined && (
					<EditableTextField
						className="editable-templates-set__input"
						fieldName="name"
						label="Название"
						value={editingState.name}
						editingDispatch={editingDispatch}
						required={fieldsSettings.name.isRequired}
						disabled={saving}
						hasError={editingState.errors.name}
					/>
				)}

				{fieldsSettings.shared !== undefined && (
					<CheckboxField
						className="editable-templates-set__checkbox"
						fieldName="shared"
						label="Доступен всем компаниям"
						value={editingState.shared}
						editingDispatch={editingDispatch}
						required={fieldsSettings.name.isRequired}
						disabled={saving}
					/>
				)}

				<EditableCompanyTags
					label="Используется в компании"
					data={editingState.companies || []}
					disabled={saving}
				/>

				<EditableProblemTemplatesList
					label="Шаблоны нарушений"
					disabled={saving}
					templates={editingState.data || []}
				/>
			</div>
		</div>
	);
};

export default EditableProblemTemplatesSet;
