import React, {memo, useMemo, useState} from 'react';
import {FilterButton, Select2, SelectOption, SelectPopup, SelectSearch} from '@tehzor/ui-components';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';
import {makeFilterLabel} from '@src/utils/makeFiltersLabel';
import {flatFilter} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {getLogRecordTypesAsArray} from '@src/selectors/entities/logRecordTypes';

interface ITypesFilterProps {
	logTypesIds?: string[];
	changeFilter: (types: string[]) => void;
}

const LABEL = 'Действие';

export const TypesFilter = memo(({logTypesIds, changeFilter}: ITypesFilterProps) => {
	const logTypes = useAppSelector(getLogRecordTypesAsArray);
	const [search, setSearch] = useState('');
	const [selectedTypes, setSelectedTypes] = useState(logTypesIds);
	const filteredData = useMemo(() => flatFilter(logTypes, 'name', search), [logTypes, search]);
	useUpdateEffect(() => {
		setSelectedTypes(logTypesIds);
	}, [logTypesIds]);

	const handleApply = () => {
		if (selectedTypes) {
			changeFilter(selectedTypes);
			setSearch('');
		}
	};

	const handleClear = () => {
		setSelectedTypes([]);
		setSearch('');
	};

	const handleCancel = () => {
		setSearch('');
	};

	const handleFullClear = () => {
		setSelectedTypes([]);
		changeFilter([]);
	};

	const typesNamesList
		= logTypesIds && logTypes.filter(types => logTypesIds.includes(types.id)).map(types => types.name);

	return (
		<SelectPopup
			footer
			onCancel={handleCancel}
			onApply={handleApply}
			onClear={handleClear}
			clearButton={!!selectedTypes?.length}
			count={selectedTypes?.length}
			search={(
				<SelectSearch
					value={search}
					onChange={setSearch}
					type="popup"
				/>
			)}
			trigger={(
				<FilterButton
					label={makeFilterLabel(LABEL, typesNamesList)}
					onClear={handleFullClear}
					active={Boolean(logTypesIds?.length)}
				/>
			)}
		>
			<Select2
				multiple
				value={selectedTypes}
				onChange={setSelectedTypes}
			>
				{filteredData.map(type => (
					<SelectOption
						key={type.id}
						itemKey={type.id}
						content={type.name}
						inputType="checkbox"
					/>
				))}
			</Select2>
		</SelectPopup>
	);
});
