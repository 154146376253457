import {wsConnector} from '@src/api/backend/wsConnector';
import {IProblemTemplatesSet} from '@tehzor/tools/interfaces/problemTemplatesSets/IProblemTemplatesSet';

export type IDeleteTemplatesSetResponse = IProblemTemplatesSet;

/**
 * Удаляет набор шаблонов нарушений
 */
export const makeDeleteTemplatesSetRequest = (id: string) =>
	wsConnector.sendAuthorizedRequest<IDeleteTemplatesSetResponse>('deleteProblemTemplatesSet', {id});
