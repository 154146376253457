import {DeleteCell} from './DeleteCell';
import {Column} from 'react-table';
import {NameCell} from './NameCell';
import ICompany from '@tehzor/tools/interfaces/companies/ICompany';

export const columns: Array<Column<ICompany>> = [
	{
		id: 'name',
		Cell: NameCell,
		minWidth: 200
	},
	{
		id: '_delete',
		Cell: DeleteCell,
		width: 78,
		disableResizing: true
	}
];
