import {createSlice, PayloadAction} from '@reduxjs/toolkit';

interface IObjectsPageSettingsState {
	filter: {
		companiesIds?: string[];
		name?: string;
	};
	pageSize: number;
	offset: number;
}

const initialState: IObjectsPageSettingsState = {
	filter: {},
	pageSize: 20,
	offset: 0
};

const objectsSettingsSlice = createSlice({
	name: 'objectsSettings',
	initialState,
	reducers: {
		setFilter: (state, action: PayloadAction<IObjectsPageSettingsState['filter']>) => {
			state.filter = {...state.filter, ...action.payload};
		},
		clearFilter: state => {
			state.filter = {};
		},
		setPageSize: (state, action: PayloadAction<number>) => {
			state.pageSize = action.payload;
		},
		setOffset: (state, action: PayloadAction<number>) => {
			state.offset = action.payload;
		}
	}
});

export const {actions: objectsSettingsActions, reducer: objectsSettingsReducer} =
	objectsSettingsSlice;
