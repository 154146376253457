import {createSlice, PayloadAction} from '@reduxjs/toolkit';

interface IUsersPageSettingsState {
	filter: {
		email?: string;
		fullName?: string;
		companiesIds?: string[];
		position?: string;
		roleId?: string;
		deactivated?: boolean;
		deleted?: boolean;
		objectsIds?: string[];
	};
	pageSize: number;
	offset: number;
}

const initialState: IUsersPageSettingsState = {
	filter: {},
	pageSize: 20,
	offset: 0
};

const usersSettingsSlice = createSlice({
	name: 'usersSettings',
	initialState,
	reducers: {
		setFilter: (state, action: PayloadAction<IUsersPageSettingsState['filter']>) => {
			state.filter = {...state.filter, ...action.payload};
		},
		clearFilter: state => {
			state.filter = {};
		},
		setPageSize: (state, action: PayloadAction<number>) => {
			state.pageSize = action.payload;
		},
		setOffset: (state, action: PayloadAction<number>) => {
			state.offset = action.payload;
		}
	}
});

export const {actions: usersSettingsActions, reducer: usersSettingsReducer} = usersSettingsSlice;