import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import {IProblemStatus} from '@tehzor/tools/interfaces/problems/IProblemStatus';
import {
	getLoadedEntitiesInitialState,
	getLoadedEntitiesSuccess,
	ILoadedEntityState
} from '@tehzor/tools/utils/reducersHandlers';

export type IProblemStatusesState = ILoadedEntityState<IProblemStatus>;

export default createReducer<IProblemStatusesState>(getLoadedEntitiesInitialState<IProblemStatus>(), {
	[types.GET_REQUEST]: getLoadedEntitiesInitialState,
	[types.GET_SUCCESS]: getLoadedEntitiesSuccess<IProblemStatus>()
});
