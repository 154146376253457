import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {getObjectsFilter} from '@src/selectors/entities/objects';
import {useCallback} from 'react';
import {objectsSettingsActions} from '@src/store/modules/settings/pages/objects/objectsSettingsSlice';

export const useObjectsFilters = () => {
	const dispatch = useAppDispatch();
	const filterState = useAppSelector(getObjectsFilter);
	const {setFilter} = objectsSettingsActions;

	const onCompaniesChange = useCallback(
		(value: string[]) => {
			dispatch(setFilter({companiesIds: value}));
		},
		[dispatch, setFilter]
	);

	const onNameChange = useCallback(
		(value: string) => {
			dispatch(setFilter({name: value}));
		},
		[dispatch, setFilter]
	);

	return {
		filterState,
		onCompaniesChange,
		onNameChange
	};
};
