import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';

const getAllIds = (state: IState) => state.entities.logRecords.allIds || [];
const getById = (state: IState) => state.entities.logRecords.byId || {};

export const getLogRecordsCount = (state: IState) => state.entities.logRecords.total || 0;
export const getLogRecordsPageSize = (state: IState) => state.settings.pages.log.pageSize || 0;
export const getLogRecordsOffset = (state: IState) => state.entities.logRecords.offset || 0;
export const getLogRecordsIsLoading = (state: IState) => state.entities.logRecords.loading || false;

export const getLogRecordsFilters = (state: IState) => state.settings.pages.log.filter || {};

/**
 * Возвращает записи в виде массива
 */
export const getLogRecordsAsArray = createSelector([getAllIds, getById], (allIds, byId) =>
	allIds.map((id: string) => byId[id]));
