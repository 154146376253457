import React from 'react';

const NoData = () => (
	<div className="table__no-data">
		<div className="table__no-data-text">
			Данные не найдены
		</div>
	</div>
);

export default NoData;
