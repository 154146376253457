import React from 'react';
import './AppBreadcrumbs.less';
import {Breadcrumbs} from '@tehzor/ui-components';
import {IBreadcrumb} from '@tehzor/ui-components/src/components/navigation/Breadcrumbs';
import {LargeTablet} from '@tehzor/ui-components/src/utils/mediaQueries';
import {useChangePath} from '@src/core/hooks/useChangePath';

interface IAppBreadcrumbsProps {
	items: IBreadcrumb[];
	children?: React.ReactNode;
}

const AppBreadcrumbs = ({items, children}: IAppBreadcrumbsProps) => {
	const {pushPath} = useChangePath();
	return (
		<LargeTablet>
			<div className="app-breadcrumbs">
				<Breadcrumbs
					className="app-breadcrumbs__links"
					items={items}
					onNavigate={pushPath}
				/>

				{children !== undefined && <div className="app-breadcrumbs__controls">{children}</div>}
			</div>
		</LargeTablet>
	);
};

export default AppBreadcrumbs;
