import {wsConnector} from '@src/api/backend/wsConnector';
import IObjectStage from '@tehzor/tools/interfaces/objects/IObjectStage';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';

export type IGetObjectStagesResponse = INormalizedData<IObjectStage>;

/**
 * Получает стадии объектов
 */
export const requestObjectStages = () => wsConnector.sendAuthorizedRequest<IGetObjectStagesResponse>('getObjectStages');
