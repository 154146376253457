import React from 'react';
import Restriction from './Restriction';
import {rowIdent} from '../InnerTable';
import IRolePermission from '@tehzor/tools/interfaces/IRolePermission';

interface IRestrictionsProps {
	permissionId: string;
	restrictions: NonNullable<IRolePermission['availableRestrictions']>;
	level?: number;
}

const Restrictions = ({permissionId, restrictions, level = 0}: IRestrictionsProps) => (
	<div
		className="role-page__settings"
		style={{marginLeft: `${level * rowIdent + 50}px`}}
	>
		<div className="role-page__settings-title">Ограничения:</div>

		{restrictions.filter(el => !el.disabeOthers).map(item => (
			<div
				key={item.id}
				className="role-page__settings-item"
			>
				<Restriction
					{...item}
					permissionId={permissionId}
				/>
			</div>
		))}
	</div>
);

export default Restrictions;
