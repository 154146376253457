import React from 'react';
import BooleanRestriction from './BooleanRestriction';
import ProblemStatusesRestriction from './ProblemStatusesRestriction';

export interface IRestrictionProps {
	id: string;
	name: string;
	dataType: string;
	permissionId: string;
}

const Restriction = (props: IRestrictionProps) => {
	switch (props.dataType) {
		case 'boolean':
			return <BooleanRestriction {...props}/>;
		case 'problemStatuses':
			return <ProblemStatusesRestriction {...props}/>;
		default:
			return null;
	}
};

export default Restriction;
