import * as types from '@src/constants/entities/logRecords';
// @ts-ignore
import {CALL_BACKEND_API} from '../../middlewares/backendMiddleware';
// @ts-ignore
import SocketConnector from '@src/api/SocketConnector';
import {IState} from '@src/store/modules';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {addErrorToast} from '@src/utils/toasts';
import {ILogRecord} from '@tehzor/tools/interfaces/ILogRecord';
import IError from '@tehzor/tools/interfaces/IError';

const socketConnector = SocketConnector.instance;

export interface IGetLogRecordsResponse extends INormalizedData<ILogRecord> {
	offset: number;
	limit: number;
	total: number;
}

const getLogRecordsRequest = () => ({
	type: types.GET_LOG_RECORDS_REQUEST
});

const getLogRecordsSuccess = (response: IGetLogRecordsResponse) => ({
	type: types.GET_LOG_RECORDS_SUCCESS,
	payload: {...response}
});

function getLogRecordsFailure(error: IError) {
	if (error.code !== 401) {
		addErrorToast('Ошибка', 'при загрузке данных');
	}
	return {
		type: types.GET_LOG_RECORDS_FAILURE,
		payload: {...error}
	};
}

/**
 * Получает список записей лога
 *
 * @returns {function}
 */
export const getLogRecords = () => (dispatch: any, getState: () => IState) =>
	dispatch({
		[CALL_BACKEND_API]: {
			actions: [getLogRecordsRequest, getLogRecordsSuccess, getLogRecordsFailure],
			promise: () => {
				const s = getState();
				if (!s.entities.logRecords || !s.settings.pages.log) {
					return Promise.reject({code: 834, error: 'getLogRecords error'});
				}
				const {offset} = s.entities.logRecords;
				const {filter, sort, pageSize} = s.settings.pages.log;
				return socketConnector.getLogRecords(filter, sort, offset, pageSize);
			}
		}
	});

/**
 * Изменяет смещение списка записей лога
 *
 * @param {number} offset смещение
 * @returns {{type, payload}} action
 */
export const changeLogRecordsOffset = (offset: number) => ({
	type: types.CHANGE_LOG_RECORDS_OFFSET,
	payload: {offset}
});
