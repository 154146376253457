import React, {memo, useMemo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractCompaniesAsArray} from '@src/store/modules/entities/companies/selectors';
import {DateFilter} from '@src/components/DateFilter';
import {useCompaniesFilters} from '../../hooks/useCompaniesFilters/useCompaniesFilters';
import {Button} from '@tehzor/ui-components';
import {MultipleSelect} from '@src/components/MultipleSelect';

export const CompaniesFilter = memo(() => {
	const companies = useAppSelector(extractCompaniesAsArray);
	const companiesWithContractors = useMemo(() => companies.filter(({contractors}) => contractors), [companies]);

	const {
		onCompaniesChange,
		onCustomersChange,
		onCreatedAtDateChange,
		onModifiedAtDateChange,
		onClearFilters,
		hasCompaniesFilters,
		filterState: {
			companiesIds,
			customersIds,
			modifiedAt,
			createdAt
		}
	} = useCompaniesFilters();

	return (
		<div className="companies-page__filters-container">
			<MultipleSelect
				label="Компании"
				options={companies}
				optionsIds={companiesIds}
				onChangeFilter={onCompaniesChange}
			/>
			<MultipleSelect
				label="Заказчики"
				options={companiesWithContractors}
				optionsIds={customersIds}
				onChangeFilter={onCustomersChange}
			/>
			<DateFilter
				changeFilters={onCreatedAtDateChange}
				from={createdAt?.from}
				to={createdAt?.to}
				label="Дата создания"
			/>
			<DateFilter
				changeFilters={onModifiedAtDateChange}
				from={modifiedAt?.from}
				to={modifiedAt?.to}
				label="Дата изменения"
			/>
			{hasCompaniesFilters && (
				<Button
					onClick={onClearFilters}
					label="Сбросить"
					type="cancel"
				/>
			)}
		</div>
	);
});
