import React from 'react';
import {CellInfo} from 'react-table-6';
import {IconButton} from '@tehzor/ui-components';
import classNames from 'classnames';

const icon = <i className="tz-simple-arrow-24 user-page__roles-table-expander-icon"/>;

const ExpanderCell = ({original, isExpanded}: CellInfo) =>
	(original.children && original.children.length ? (
		<IconButton
			className={classNames('user-page__roles-table-expander-btn', {
				'user-page__roles-table-expander-btn_open': isExpanded
			})}
			type="transparent"
		>
			{icon}
		</IconButton>
	) : null);

export default ExpanderCell;
