import {wsConnector} from '../wsConnector';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';

export type IRestoreObjectResponse = IObject;

/**
 * Восстанавливает объект
 *
 * @param objectId id объекта
 */
export const makeObjectRestoreRequest = (objectId: string) =>
	wsConnector.sendAuthorizedRequest<IRestoreObjectResponse>('restoreObject', {objectId});
