import React from 'react';
import './UserSelectDialog.less';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';
import SelectDialog, {ISelectDialogProps} from '../../select/SelectDialog';
import Select from '../../select/Select';
import SelectOption from '../../select/SelectOption';

interface IUserDialogSelectProps {
	className?: string;
	style?: React.CSSProperties;
	trigger: ISelectDialogProps['trigger'];
	title?: string;
	multiple?: boolean;
	data: IBriefUser[];
	value?: string | string[];

	onChange?: (value: string | string[] | undefined) => void;
}

const UserSelectDialog = (props: IUserDialogSelectProps) => {
	const {
		className,
		style,
		trigger,
		title = 'Выберите пользователей',
		multiple,
		data,
		value,
		onChange
	} = props;

	return (
		<SelectDialog
			className={className}
			style={style}
			trigger={trigger}
			title={title}
			value={value}
			onChange={onChange}
		>
			<Select multiple={multiple}>
				{data.map(item => (
					<SelectOption
						inputType={multiple ? 'checkbox' : 'radio'}
						key={item.id}
						itemKey={item.id}
						content={item.fullName}
					/>
				))}
			</Select>
		</SelectDialog>
	);
};

export default UserSelectDialog;