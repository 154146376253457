import {combineReducers} from 'redux';
import app from './app';
import {auth} from './auth';
import entities from './entities';
import settings from './settings';
import notifications from '../../reducers/notifications';
// @ts-ignore
import ui from '../../reducers/ui/index';

const rootReducer = combineReducers({
	app,
	auth,
	entities,
	settings,
	notifications,
	ui
});

export type IState = ReturnType<typeof rootReducer>;

export default rootReducer;
