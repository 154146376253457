import * as types from '../../constants/entitiesConstants';
import * as storeTypes from '../../constants/storeConstants';
import createReducer from '@tehzor/tools/utils/createReducer';
import config from '../../core/config';

const {entitiesCacheTime} = config;

const initialState = {
	byId: {},
	allIds: [],
	expires: null
};

export default createReducer(initialState, {
	[types.GET_ARCHIVE_OBJECTS_SUCCESS]: (state, payload) => ({
		byId: payload.byId,
		allIds: payload.allIds,
		expires: Date.now() + entitiesCacheTime * 1000
	}),
	[types.UNARCHIVED_ARCHIVE_OBJECT_SUCCESS]: (state, payload) => {
		if (!payload.length) {
			return state;
		}
		const byId = {
			...state.byId
		};
		const archiveObjectsId = [];
		for (const item in byId) {
			const objectId = byId[item].objectId;
			if (payload.some(item => item === objectId)) {
				delete byId[item];
				archiveObjectsId.push(item);
			}
		}
		const allIds = [];
		for (const item in state.allIds) {
			const objectId = state.allIds[item];
			if (archiveObjectsId.some(item => item !== objectId)) {
				allIds.push(state.allIds[item]);
			}
		}
		return {
			byId,
			allIds,
			expires: Date.now() + entitiesCacheTime * 1000
		};
	},
	[types.DELETE_ARCHIVE_OBJECT_SUCCESS]: (state, payload) => {
		if (!payload.id) {
			return state;
		}
		const byId = {
			...state.byId
		};
		delete byId[payload.id];
		return {
			byId,
			allIds: state.allIds.filter(item => item !== payload.id),
			expires: Date.now() + entitiesCacheTime * 1000
		};
	},
	[storeTypes.CLEAR_STORE]: () => ({
		...initialState
	})
});
