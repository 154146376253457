/* eslint-disable @typescript-eslint/ban-types */
import React from 'react';
import {Column, useExpanded, useFlexLayout, useResizeColumns, useRowState, useTable} from 'react-table';
import Table from '../Table';
import TableHead from '../Table/components/TableHead';
import TableRow from '../Table/components/TableRow';
import TableBody from '../Table/components/TableBody';
import TableCell from '../TableCell';
import TableWrap from '../Table/components/TableWrap';
import TableHeadRow from '../Table/components/TableHeadRow';
import {ISubContentProps} from '../shared/interfaces';
import classNames from 'classnames';
import TableRowWrap from '../Table/components/TableRowWrap';
import TableRowSubContent from '../Table/components/TableRowSubContent';

interface IExpandableTableProps<D extends object> {
	className?: string;
	style?: React.CSSProperties;
	columns: Array<Column<D>>;
	data: D[];
	showHead?: boolean;
	renderSubContent?: (props: ISubContentProps<D>) => React.ReactNode;

	onRowClick?: (data: D) => void;
}

const ExpandableTable = <D extends object>(props: IExpandableTableProps<D>) => {
	const {className, style, columns, data, showHead = true, renderSubContent, onRowClick} = props;
	const isRowsClickable = !!onRowClick;

	const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} = useTable<D>(
		{
			columns,
			data
		},
		useFlexLayout,
		useExpanded,
		useResizeColumns,
		useRowState
	);

	return (
		<TableWrap
			className={className}
			style={style}
		>
			<Table {...getTableProps()}>
				{showHead && (
					<TableHead>
						{headerGroups.map(headerGroup => (
							<TableHeadRow {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map(column => (
									<TableCell {...column.getHeaderProps()}>{column.render('Header')}</TableCell>
								))}
							</TableHeadRow>
						))}
					</TableHead>
				)}
				<TableBody {...getTableBodyProps()}>
					{rows.map(row => {
						prepareRow(row);
						const {key, ...rowProps} = row.getRowProps();

						return (
							<TableRowWrap
								key={key}
								className={classNames({'table2__row-wrap_expanded': row.isExpanded})}
							>
								<TableRow
									{...rowProps}
									className={classNames({table2__row_expanded: row.isExpanded})}
									clickable={isRowsClickable}
								>
									{row.cells.map(cell => (
										<TableCell
											{...cell.getCellProps()}
											className={cell.column.className}
											data={row.original}
											onClick={cell.column.isNonClickable ? undefined : onRowClick}
										>
											{cell.render('Cell')}
										</TableCell>
									))}
								</TableRow>

								{renderSubContent ? (
									<TableRowSubContent expanded={row.isExpanded}>
										{renderSubContent(row)}
									</TableRowSubContent>
								) : null}
							</TableRowWrap>
						);
					})}
				</TableBody>
			</Table>
		</TableWrap>
	);
};

export default ExpandableTable;
