import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Route, Routes, useResolvedPath} from 'react-router-dom';
import useAsync from 'react-use/lib/useAsync';
import './CompanyPage.less';
import {extractCompany} from '@src/store/modules/entities/companies/selectors';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {getCompanies} from '@src/store/modules/entities/companies/actions';
import {getUsers} from '@src/store/modules/entities/users/actions';
import useScrollRestoration from '@src/core/hooks/useScrollRestoration';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import MainTab from './components/MainTab';
import PageBreadcrumbs from './components/PageBreadcrumbs';
import LeftSide from './components/LeftSide';
import {InspectorsTab} from './components/InspectorsTab';
import {ActionButtons, AdaptiveHeightPanel, Button, Plate, usePageLeavingBlock} from '@tehzor/ui-components';
import {
	convertToSave,
	EditableCompanyDispatchCtx,
	hasErrors,
	isCompanyEdited,
	useEditableCompanyState
} from '@src/core/hooks/states/useEditableCompanyState';
import {useUpdateEffect} from 'react-use';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {editCompany} from '@src/store/modules/entities/company/actions';
import {usePageReloadPrevent} from '@tehzor/tools/core/hooks/usePageReloadPrevent';
import {ContractorsTab} from './components/ContractorsTab';
import {useStrictParams} from '@src/core/hooks/useStrictParams';

export const CompanyPage = () => {
	useScrollRestoration();
	const url = useResolvedPath('').pathname;
	const {companyId} = useStrictParams<{companyId: string}>();
	const company = useAppSelector(s => extractCompany(s, companyId));

	useAppHeader(
		{
			title: company ? `${company.name}` : '',
			showBackBtn: true,
			sectionsMenu: [
				{
					url,
					label: 'Основное'
				},
				{
					url: `${url}/inspectors`,
					label: 'Сотрудники'
				},
				{
					url: `${url}/contractors`,
					label: 'Подрядчики'
				}
			]
		},
		[company]
	);

	const pageRef = useRef<HTMLDivElement>(null);
	const dispatch = useAppDispatch();

	useAsync(async () => {
		await Promise.all([dispatch(getUsers()), dispatch(getCompanies())]);
	}, []);

	const [editingState, editingDispatch] = useEditableCompanyState(company);
	const [isEdited, setIsEdited] = useState(false);
	usePageLeavingBlock('company', isEdited, undefined, 'Все введённые данные будут потеряны', [
		url,
		`${url}/inspectors`,
		`${url}/contractors`
	]);
	usePageReloadPrevent(isEdited);

	useEffect(() => {
		setIsEdited(isCompanyEdited(editingState, company));
	}, [editingState, company]);

	useUpdateEffect(() => {
		editingDispatch({type: 'reset', entity: company});
	}, [company]);

	const [savingState, save] = useAsyncFn(async () => {
		// Проверка наличия ошибок в состояниях
		if (hasErrors(editingState)) {
			editingDispatch({type: 'update-errors'});
			return;
		}
		// Проверка, были ли отредактированы поля
		if (!isCompanyEdited(editingState, company)) {
			return;
		}
		await dispatch(editCompany(companyId, convertToSave(editingState, company, true)));
	}, [editingState, company]);

	const cancel = useCallback(() => {
		editingDispatch({type: 'reset', entity: company});
	}, [company]);

	return (
		<div
			className="page-cont company-page"
			ref={pageRef}
		>
			<PageBreadcrumbs companyId={companyId}/>

			<div className="company-page__d-columns">
				<div className="company-page__d-column company-page__d-column_info">
					<LeftSide company={company}/>
				</div>

				<div className="company-page__d-column company-page__d-column_main">
					<AdaptiveHeightPanel pageRef={pageRef.current}>
						<Plate className={{root: 'company-page__plate', content: 'company-page__content-wrap'}}>
							<EditableCompanyDispatchCtx.Provider value={editingDispatch}>
								<div className="company-page__content-scroll">
									<Routes>
										<Route
											path="/"
											element={<MainTab editingState={editingState}/>}
										/>

										<Route
											path="inspectors"
											element={<InspectorsTab editingState={editingState}/>}
										/>
										{company ? (
											<Route
												path="contractors"
												element={(
													<ContractorsTab
														editingState={editingState}
														companyId={company.id}
													/>
												)}
											/>
										) : null}
									</Routes>
								</div>

								{isEdited && (
									<ActionButtons className="company-page__buttons">
										<Button
											type="accent-blue"
											label="Сохранить"
											disabled={savingState.loading}
											onClick={save}
										/>
										<Button
											type="cancel"
											label="Отменить"
											disabled={savingState.loading}
											onClick={cancel}
										/>
									</ActionButtons>
								)}
							</EditableCompanyDispatchCtx.Provider>
						</Plate>
					</AdaptiveHeightPanel>
				</div>
			</div>
		</div>
	);
};
