import {
	IEditableEntityAction,
	IEditableEntityState,
	isEntityEdited
} from '@tehzor/tools/core/states/editableEntityState';
import ICompany from '@tehzor/tools/interfaces/companies/ICompany';
import ISavingCompany from '@tehzor/tools/interfaces/companies/ISavingCompany';
import isEqual from 'lodash/isEqual';
import ICompanyEmployee from '@tehzor/tools/interfaces/companies/ICompanyEmployee';

export interface IEditableCompanyEmployee {
	userId: string;
}

export interface IEditableCompany {
	name: string;
	employees: IEditableCompanyEmployee[];
	contractors: string[];
}

export type IEditableCompanyState = IEditableEntityState<IEditableCompany>;

export type IEditableCompanyAction = IEditableEntityAction<IEditableCompanyState, ICompany>;

const makeEmptyState = (): IEditableCompanyState => ({
	name: '',
	employees: [],
	contractors: [],
	errors: {
		name: false,
		employees: false,
		contractors: false
	}
});

export const init = (company?: ICompany): IEditableCompanyState => {
	const empty = makeEmptyState();
	if (!company) {
		return empty;
	}
	return {
		name: company.name,
		employees: company.employees ?? [],
		contractors: company.contractors?.filter(el => !el.deleted).map(item => item.subCompanyId) ?? [],
		errors: empty.errors
	};
};

const isNameEdited = (state: IEditableCompany, original?: ICompany) =>
	(original?.name ? original.name !== state.name : !!state.name);

const areEmployeesEdited = (state: IEditableCompanyEmployee[], original?: ICompanyEmployee[]) =>
	(original
		? original.length !== state.length || original.some(a => state.every(b => !isEqual(a, b)))
		: !!state.length);

const areInspectorsEdited = (state: IEditableCompany, original?: ICompany) =>
	areEmployeesEdited(state.employees, original?.employees);

const areContractorsEdited = (state: IEditableCompany, original?: ICompany) => {
	const activeContractors = original?.contractors?.filter(el => !el.deleted)?.map(item => item.subCompanyId) ?? [];
	return (
		state.contractors.length !== activeContractors.length
		|| !state.contractors.every(id => activeContractors.includes(id))
	);
};

/**
 * Возвращает значение, показывающее были ли отредактированы поля
 *
 * @param state состояние
 * @param original изначальные данные
 */
export const isCompanyEdited = (state: IEditableCompanyState, original?: ICompany): boolean =>
	isEntityEdited(state, original, isNameEdited, areInspectorsEdited, areContractorsEdited);

/**
 * Функции проверки полей на ошибки
 */
export const errorsFns = {
	name: (state: IEditableCompanyState) => !state.name,
	inspectors: () => false,
	contractors: () => false
};

/**
 * Проверяет, есть ли ошибки в полях сущности
 *
 * @param state состояние
 */
export const hasErrors = (state: IEditableCompanyState) => errorsFns.name(state);

/**
 * Конвертирует данные в формат, пригодный для отправки на сервер
 *
 * @param state состояние
 * @param original изначальные данные
 * @param onlyEdited возвращать только изменённые поля
 */
export const convertToSave = (
	state: IEditableCompanyState,
	original?: ICompany,
	onlyEdited?: boolean
): ISavingCompany => {
	if (!onlyEdited) {
		return {
			name: state.name,
			employees: state.employees,
			contractors: state.contractors
		};
	}
	const data: ISavingCompany = {};
	if (isNameEdited(state, original)) {
		data.name = state.name;
	}
	if (areInspectorsEdited(state, original)) {
		data.employees = state.employees;
	}
	if (areContractorsEdited(state, original)) {
		data.contractors = state.contractors;
	}
	return data;
};
