import React from 'react';
import {useEditableCompanyDispatchCtx} from '@src/core/hooks/states/useEditableCompanyState';
import {CompanySelectDialog, ExpandableTable, InlineButton} from '@tehzor/ui-components';
import EditableFieldHeader from '@src/components/editableFields/EditableFieldHeader';
import {columns} from './components/columns';

import useAppSelector from '@src/core/hooks/useAppSelector';
import {
	extractAllSubCompaniesAsArray,
	extractPotentialContractorCompaniesAsArray,
	extractSubCompaniesAsArrayByIds
} from '@src/store/modules/entities/companies/selectors';

interface IEditableCompanyContractorsProps {
	value: string[];
	companyId: string;
}

export const EditableCompanyContractors = ({value, companyId}: IEditableCompanyContractorsProps) => {
	const editingDispatch = useEditableCompanyDispatchCtx();
	const allContractorsCompanies = useAppSelector(extractAllSubCompaniesAsArray);
	const forbiddenEditing = allContractorsCompanies.some(item => item.id === companyId);
	const contractorsCompanies = useAppSelector(s => extractSubCompaniesAsArrayByIds(s, value));
	const companies = useAppSelector(extractPotentialContractorCompaniesAsArray);
	const filteredCompanies = companies.filter(item => !value.includes(item.id));

	const handleAdding = (val: string[]) => {
		editingDispatch({type: 'add-in-array', field: 'contractors', value: val});
	};

	return (
		<div>
			<EditableFieldHeader title="Подрядчики">
				<CompanySelectDialog
					trigger={(
						<InlineButton
							type="accent"
							leftIcon={<i className="tz-plus-20"/>}
							label="Добавить"
							disabled={forbiddenEditing}
						/>
					)}
					data={filteredCompanies}
					value={value}
					onChange={handleAdding}
				/>
			</EditableFieldHeader>
			{forbiddenEditing ? (
				<div>
					Добавление подрядчиков недоступно, так как данная компания уже является подрядчиком у другой
					компании
				</div>
			) : (
				<ExpandableTable
					columns={columns}
					data={contractorsCompanies}
					showHead={false}
				/>
			)}
		</div>
	);
};