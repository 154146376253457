import React, {useCallback} from 'react';
import ICustomizableField, {CustomizableFieldDestination} from '@tehzor/tools/interfaces/ICustomizableField';
import {connect} from 'react-redux';
import {IState} from '@src/store/modules';
import {getCustomizableFieldsByDest} from '@src/selectors/entities/customizableFields';
import columns from './columns';
import {Table} from '@tehzor/ui-components';
import classNames from 'classnames';
import IObjectFieldSetting from '@tehzor/tools/interfaces/objects/IObjectFieldSetting';

interface IFieldsTableProps {
	settingKey: string;
	title: string;
	value?: IObjectFieldSetting[];
	destination: CustomizableFieldDestination;
	hasCopyColumn?: boolean;
}

interface IFieldsTableConnectedProps {
	fields: ICustomizableField[];
}

export const SettingsValueCtx = React.createContext<IObjectFieldSetting[] | undefined>([]);

const getTbodyProps = () => ({className: 'object-page__fields-settings-tbody'});

const TdComponent = ({className, children, style}: any) => (
	<div
		className={classNames('rt-td', className)}
		style={style}
		role="gridcell"
	>
		{children}
	</div>
);

const FieldsTable = ({settingKey, title, value, hasCopyColumn = true, fields}: IFieldsTableProps & IFieldsTableConnectedProps) => {
	const getTdProps = useCallback(() => ({settingKey}), [settingKey]);

	return (
		<div className="object-page__fields-settings-table">
			<div className="field__wide-label-wrap">
				<div className="field__label">
					{title}
					:
				</div>
			</div>

			<SettingsValueCtx.Provider value={value}>
				<Table
					data={fields}
					columns={hasCopyColumn ? columns : columns.slice(0, 3)}
					sortable={false}
					resizable={false}
					showPagination={false}
					pageSize={fields.length}
					getTbodyProps={getTbodyProps}
					// getTrProps={getTrProps}
					getTdProps={getTdProps}
					TdComponent={TdComponent}
					// TrComponent={TrComponent}
				/>
			</SettingsValueCtx.Provider>
		</div>
	);
};

const mapStateToProps = (state: IState, props: IFieldsTableProps) =>
	({
		fields: getCustomizableFieldsByDest(state, props)
	} as IFieldsTableConnectedProps);

export default connect(mapStateToProps)(FieldsTable);
