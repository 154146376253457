import React from 'react';
import {SelectSearch} from '@tehzor/ui-components';
import {MultipleSelect} from '@src/components/MultipleSelect';
import {useObjectsFilters} from '@src/pages/ObjectsPage/hooks/useObjectsFilters/useObjectsFilters';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractCompaniesAsArray} from '@src/store/modules/entities/companies/selectors';

export const ObjectsFilter = () => {
	const companies = useAppSelector(extractCompaniesAsArray);
	const [objectName, setObjectName] = React.useState('');
	const {onCompaniesChange, onNameChange, filterState} = useObjectsFilters();

	const handleNameChange = (value: string) => {
		setObjectName(value);
	};
	const applyNameFilter = () => {
		onNameChange(objectName);
	};
	const clearNameFilter = () => {
		onNameChange('');
	};

	return (
		<div className="objects-page__filters-block">
			<SelectSearch
				type="filter"
				placeholder="Название объекта"
				value={filterState.name}
				onChange={handleNameChange}
				onSearch={applyNameFilter}
				onClear={clearNameFilter}
			/>
			<MultipleSelect
				label="Компании"
				onChangeFilter={onCompaniesChange}
				options={companies}
				optionsIds={filterState.companiesIds}
			/>
		</div>
	);
};
