import {ColumnDef} from '@tanstack/react-table';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import {CompanyNameCell} from '../companyNameCell/companyNameCell';
import {DeleteObjectCell} from '../DeleteObjectCell/DeleteObjectCell';

export const buildObjectsColumns: Array<ColumnDef<IObject>> = [
	{
		accessorKey: 'companyId',
		header: 'Компания',
		cell: CompanyNameCell
	},
	{
		accessorKey: 'name',
		header: 'Название'
	},
	{
		accessorKey: 'city',
		header: 'Город'
	},
	{
		accessorKey: 'deleted',
		header: 'Удалён',
		cell: DeleteObjectCell
	}
];
