import {wsConnector} from '../wsConnector';

export type IDeleteCompanyResponse = void;

/**
 * Удаляет компанию
 *
 * @param companyId id компании
 */
export const makeCompanyDeleteRequest = (companyId: string) =>
	wsConnector.sendAuthorizedRequest<IDeleteCompanyResponse>('deleteCompany', {companyId}, {timeout: 300});
