import {combineReducers} from 'redux';
// @ts-ignore
import currentUser from '../../../reducers/entities/currentUserReducer';
import users from './users/reducers';
import user from './user/reducers';
import {roles} from './roles/reducer';
import rolePermissions from '../../../reducers/entities/rolePermissions';
import companies from './companies/reducers';
// @ts-ignore
import listTypesExport from '../../../reducers/entities/listTypesExportReducer';
import objects from './objects/reducers';
// @ts-ignore
import archiveObjects from '../../../reducers/entities/archiveObjectsReducer';
import regulatoryStandards from './regulatoryStandards/reducers';
import spaceIndicatorsSets from './spaceIndicatorsSets/reducers';
import problemStatuses from './problemStatuses/reducers';
import {categories} from './categories/reducers';
import {categoriesSets} from './categoriesSets/reducers';
import plans from './plans/reducers';
import logRecords from '../../../reducers/entities/logRecords';
import logRecordTypes from '../../../reducers/entities/logRecordTypes';
import customizableFields from '../../../reducers/entities/customizableFields';
import problemTemplatesSet from './problemTemplatesSet/reducers';
import problemTemplatesSets from './problemTemplatesSets/reducers';
import objectStages from './objectStages/reducers';
import devices from './devices/reducers';
import {objectReducer} from './object/objectSlice';

export default combineReducers({
	currentUser,
	users,
	user,
	roles,
	rolePermissions,
	companies,
	objects,
	archiveObjects,
	listTypesExport,
	categories,
	categoriesSets,
	plans,
	regulatoryStandards,
	spaceIndicatorsSets,
	object: objectReducer,
	problemStatuses,
	logRecords,
	logRecordTypes,
	customizableFields,
	objectStages,
	problemTemplatesSet,
	problemTemplatesSets,
	devices
});
