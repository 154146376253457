import {wsConnector} from '../wsConnector';
import ICompany from '@tehzor/tools/interfaces/companies/ICompany';
import ISavingCompany from '@tehzor/tools/interfaces/companies/ISavingCompany';

export type IEditCompanyResponse = ICompany;

/**
 * Изменяет компанию
 *
 * @param companyId id компании
 * @param company данные компании
 */
export const makeCompanyEditRequest = (companyId: string, company: ISavingCompany) =>
	wsConnector.sendAuthorizedRequest<IEditCompanyResponse>('editCompany', {companyId, ...company});
