import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {getLogRecordsFilters} from '@src/selectors/entities/logRecords';
import {useCallback} from 'react';
import {getLogRecords} from '@src/actions/entities/logRecords';
import {logSettingsActions} from '@src/store/modules/settings/pages/log/logSettingsSlice';

export const useLogFilters = () => {
	const dispatch = useAppDispatch();
	const filterState = useAppSelector(getLogRecordsFilters);
	const {setFilter, clearFilter} = logSettingsActions;

	const onLogTypesChange = useCallback(
		(value: string[]) => {
			dispatch(setFilter({types: value}));
			dispatch(getLogRecords());
		},
		[dispatch]
	);

	const onUsersChange = useCallback(
		(value: string[]) => {
			dispatch(setFilter({users: value}));
			dispatch(getLogRecords());
		},
		[dispatch]
	);

	const onErrorChange = useCallback(
		(value: boolean | undefined) => {
			dispatch(setFilter({withError: value}));
			dispatch(getLogRecords());
		},
		[dispatch]
	);

	const onDateChange = useCallback(
		(from: Date | undefined, to: Date | undefined) => {
			dispatch(setFilter({dateTo: to, dateFrom: from}));
			dispatch(getLogRecords());
		},
		[dispatch]
	);

	const onClearFilter = () => {
		dispatch(clearFilter());
		dispatch(getLogRecords());
	};

	const hasLogFilters = Boolean(
		filterState.dateTo ||
			filterState.dateFrom ||
			filterState.users?.length ||
			filterState.withError !== undefined ||
			filterState.types?.length
	);
	return {
		filterState,
		onLogTypesChange,
		onUsersChange,
		onErrorChange,
		onDateChange,
		onClearFilter,
		hasLogFilters
	};
};
