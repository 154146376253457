import React, {useCallback, useContext} from 'react';
import {CellInfo} from 'react-table-6';
import {Checkbox} from '@tehzor/ui-components';
import ICustomizableField from '@tehzor/tools/interfaces/ICustomizableField';
import {SettingsValueCtx} from './FieldsTable';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {objectActions} from '@src/store/modules/entities/object/objectSlice';
import IObjectFieldsSettings from '@tehzor/tools/interfaces/objects/IObjectFieldsSettings';

const IsAvailableCell = ({original, tdProps}: CellInfo) => {
	const {id} = original as ICustomizableField;
	const {settingKey} = tdProps.rest;
	const value = useContext(SettingsValueCtx);
	const dispatch = useAppDispatch();

	const isChecked = value?.some(item => item.fieldId === id);

	const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			dispatch(objectActions.addFieldSetting({fieldId: id, key: settingKey as keyof IObjectFieldsSettings}));
		} else {
			dispatch(objectActions.deleteFieldSetting({fieldId: id, key: settingKey as keyof IObjectFieldsSettings}));
		}
	}, [dispatch, id, settingKey]);

	return (
		<Checkbox
			className={{
				root: 'object-page__contractors-checkbox',
				icon: 'object-page__contractors-checkbox-input'
			}}
			checked={isChecked}
			onChange={onChange}
		/>
	);
};

export default IsAvailableCell;
