import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import {ICheckUserContentResponse} from '@src/api/backend/user/checkContent';
import {ICheckUserContentPayload} from '@src/store/modules/entities/user/actions';
import config from '@src/core/config';

const {entitiesCacheTime} = config;

interface IUserState {
	id?: string;
	contentCounts?: ICheckUserContentResponse;
	expires?: number;
}

const getInitialState = (): IUserState => ({
	contentCounts: undefined
});

export default createReducer(getInitialState(), {
	[types.CHECK_CONTENT_REQUEST]: state => {
		state.contentCounts = undefined;
	},
	[types.CHECK_CONTENT_SUCCESS]: (state, {payload}: {payload: ICheckUserContentPayload}) => {
		state.id = payload.userId;
		state.contentCounts = payload.counts;
		state.expires = Date.now() + entitiesCacheTime * 1000;
	}
});
