import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';
import INotificationType from '@tehzor/tools/interfaces/notifications/INotificationType';

const getAllIds = (state: IState) => state.notifications.types.allIds || [];
const getById = (state: IState): {[id: string]: INotificationType} => state.notifications.types.byId || {};

/**
 * Возвращает типы уведомлений в виде массива
 */
export const getNotificationTypesAsArray = createSelector(
	[getAllIds, getById],
	(allIds, byId) => allIds.map((id: string) => byId[id])
);
