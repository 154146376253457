import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import config from '@src/core/config';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';
import IRole from '@src/interfaces/IRole';
import {
	addEntitySuccess,
	deleteEntitySuccess,
	editEntitySuccess,
	getEntitiesSuccess,
	IEntityState
} from '@tehzor/tools/utils/reducersHandlers';

const {entitiesCacheTime} = config;

type IRolesState = IEntityState<IRole>;

const initialState = {
	byId: {},
	allIds: [],
	expires: undefined
} as IRolesState;

export const roles = createReducer<IRolesState>(initialState, {
	[types.GET_ROLES_SUCCESS]: getEntitiesSuccess<IRole>(entitiesCacheTime),
	[types.ADD_ROLE_SUCCESS]: addEntitySuccess<IRole>(),
	[types.EDIT_ROLE_SUCCESS]: editEntitySuccess<IRole>(),
	[types.DELETE_ROLE_SUCCESS]: deleteEntitySuccess<IRole>(),
	[CLEAR_STORE]: () => ({...initialState})
});

export {IRolesState};
