import React from 'react';
import {useAsync} from 'react-use';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {getCompanies} from '@src/store/modules/entities/companies/actions';
import {getObjects} from '@src/store/modules/entities/objects/actions';
import {Button, LoadingPanel} from '@tehzor/ui-components';
import {ObjectsTable} from './components/ObjectsTable/ObjectsTable';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import useAppSelector from '@src/core/hooks/useAppSelector';
import './ObjectsPage.less';
import {hasPermission} from '@tehzor/tools/utils/findPermission';
import {extractUserRoles} from '@src/store/modules/auth/profile/selectors';
import {UserRoleScopes} from '@tehzor/tools/interfaces/IUser';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {ObjectsFilter} from './components/ObjectsFilter/ObjectsFilter';

const plusIcon = <i className="tz-plus-24" />;

export const ObjectsPage = () => {
	useAppHeader(
		{
			title: 'Объекты'
		},
		[]
	);
	const dispatch = useAppDispatch();
	const {pushPath} = useChangePath();
	const userRoles = useAppSelector(extractUserRoles);
	const loadingState = useAsync(async () => {
		await Promise.allSettled([dispatch(getObjects()), dispatch(getCompanies())]);
	}, []);

	const goToObjectAdding = () => {
		pushPath('/objects/add');
	};

	const createObjectButtonVisible = (hasPermission(
		userRoles,
		'adminObjectsAdd',
		UserRoleScopes.ALL
	) ||
		hasPermission(userRoles, 'adminObjectsAdd', UserRoleScopes.COMPANY)) && (
		<Button
			onClick={goToObjectAdding}
			leftIcon={plusIcon}
			label="Создать Объект"
			type="accent-blue"
		/>
	);

	return (
		<LoadingPanel
			active={loadingState.loading}
			className="loading-panel_main"
		>
			<div className="page-cont">
				<div className="container" />
				<div className="objects-page__button-block">{createObjectButtonVisible}</div>
				<ObjectsFilter />
				<ObjectsTable />
			</div>
		</LoadingPanel>
	);
};
