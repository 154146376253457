import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import IFile from '@tehzor/tools/interfaces/IFile';
import IUploadingFile from '@tehzor/tools/interfaces/IUploadingFile';
import IObjectFieldsSettings from '@tehzor/tools/interfaces/objects/IObjectFieldsSettings';
import {IObject, IObjectDateStageRange, IObjectManager} from '@tehzor/tools/interfaces/objects/IObject';
import IObjectFieldSetting from '@tehzor/tools/interfaces/objects/IObjectFieldSetting';
import {addObjectFailure, addObjectRequest, addObjectSuccess} from './actions/add';
import {getObjectSuccess} from './actions/get';
import {editObjectFailure, editObjectRequest, editObjectSuccess} from '@src/store/modules/entities/object/actions';
import {convertForm} from './utils/convertFormState';
import {createDefaultFieldsSettings} from './utils/createDefaultFieldsSettings';

export interface IEditableObjectState {
	name?: string;
	fullName?: string;
	externalId?: string;
	parentId?: string;
	companyId?: string;
	city?: string;
	address?: string;
	generalContractor?: string;
	stage?: ObjectStageIds;
	image?: IFile;
	newImage?: IUploadingFile[];
	fieldsSettings?: Required<IObjectFieldsSettings>;
	spaceIndicatorsSet?: string;
	problemTemplatesSet?: string;
	incObjectId?: string;
	objectIdsForInc?: string[];
	constructionManager?: IObjectManager;
	projectManager?: IObjectManager;
	stages?: {
		[key in ObjectStageIds]?: IObjectDateStageRange | null;
	};
}

export type IObjectErrors = {
	[key in keyof IEditableObjectState]?: string;
};

export interface IObjectSchema {
	data?: IObject;
	form: IEditableObjectState;
	edited: boolean;
	errors?: IObjectErrors;
	isLoading: boolean;
}

const initialState: IObjectSchema = {
	form: {
		fieldsSettings: createDefaultFieldsSettings(),
		newImage: []
	},
	data: undefined,
	errors: undefined,
	edited: false,
	isLoading: false
};

export const objectSlice = createSlice({
	name: 'object',
	initialState,
	reducers: {
		clearObject: state => {
			state.data = undefined;
			state.form = initialState.form;
			state.errors = undefined;
			state.edited = false;
		},
		setEdited: (state, action: PayloadAction<boolean>) => {
			state.edited = action.payload;
		},
		updateObject: (state, action: PayloadAction<IEditableObjectState>) => {
			state.form = {
				...state.form,
				...action.payload
			};
		},
		resetObject: state => {
			if (state.data) state.form = convertForm(state.data);
			state.edited = false;
		},
		updateValidateErrors: (state, action: PayloadAction<IObjectErrors>) => {
			state.errors = {
				...state.errors,
				...action.payload
			};
		},
		addFieldSetting: (
			state,
			action: PayloadAction<{
				fieldId: string;
				key: keyof IObjectFieldsSettings;
			}>
		) => {
			const {form} = state;

			if (form?.fieldsSettings) {
				const {fieldId, key} = action.payload;
				const settings = form.fieldsSettings[key];

				const settingExists = settings?.some(setting => setting.fieldId === fieldId);

				if (!settingExists) {
					settings?.push({
						fieldId,
						isRequired: false,
						canBeCopied: false
					});
				}
			}
			state.edited = true;
		},
		deleteFieldSetting: (
			state,
			action: PayloadAction<{
				fieldId: string;
				key: keyof IObjectFieldsSettings;
			}>
		) => {
			const {form} = state;

			if (form?.fieldsSettings) {
				const {fieldId, key} = action.payload;
				const keySettings = form.fieldsSettings[key];

				if (keySettings) {
					const settingIndex = keySettings.findIndex(setting => setting.fieldId === fieldId);

					if (settingIndex !== -1) {
						keySettings.splice(settingIndex, 1);
					}
				}
			}
			state.edited = true;
		},
		updateFieldSetting: (
			state,
			action: PayloadAction<{
				fieldId: string;
				key: keyof IObjectFieldsSettings;
				setting: keyof IObjectFieldSetting;
				value: string | boolean;
			}>
		) => {
			const {form} = state;
			const {fieldId, key, setting, value} = action.payload;

			if (form?.fieldsSettings && form.fieldsSettings[key]) {
				form.fieldsSettings[key] = form.fieldsSettings[key].map(item =>
					(item.fieldId === fieldId ? {...item, [setting]: value} : item));
			}
			state.edited = true;
		},
		updateStage: (
			state,
			action: PayloadAction<{
				stageId: ObjectStageIds;
				dateFrom?: number;
				dateTo?: number;
			}>
		) => {
			const {form} = state;
			const {stageId, dateFrom, dateTo} = action.payload;
			const stages = form?.stages;

			if (stages) {
				stages[stageId] = {
					from: dateFrom,
					to: dateTo
				};
			}
		}
	},
	extraReducers: builder => {
		builder
			.addCase(getObjectSuccess, (state, action: PayloadAction<IObject>) => {
				state.data = action.payload;
				state.form = convertForm(action.payload);
				state.edited = false;
			})
			.addCase(addObjectRequest, state => {
				state.isLoading = true;
			})
			.addCase(addObjectFailure, state => {
				state.isLoading = false;
			})
			.addCase(addObjectSuccess, state => {
				state.isLoading = false;
			})
			.addCase(editObjectRequest, state => {
				state.isLoading = true;
			})
			.addCase(editObjectFailure, state => {
				state.isLoading = false;
			})
			.addCase(editObjectSuccess, state => {
				state.isLoading = false;
			});
	}
});

export const {actions: objectActions} = objectSlice;
export const {reducer: objectReducer} = objectSlice;
