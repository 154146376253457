import NameCell from './NameCell';
import IsAvailableCell from './IsAvailableCell';
import IsRequiredCell from './IsRequiredCell';
import CanBeCopiedCell from './CanBeCopiedCell';

const columns = [
{
	id: 'name',
	Cell: NameCell,
	Header: 'Название',
	className: 'object-page__fields-settings-name-col',
	headerClassName: 'object-page__fields-settings-name-col',
	minWidth: 140
}, {
	id: 'isAvailable',
	Cell: IsAvailableCell,
	Header: 'Включено',
	className: 'object-page__fields-settings-checkbox-col',
	headerClassName: 'object-page__fields-settings-checkbox-col',
	minWidth: 110
}, {
	id: 'isRequired',
	Cell: IsRequiredCell,
	Header: 'Обязательно',
	className: 'object-page__fields-settings-checkbox-col',
	headerClassName: 'object-page__fields-settings-checkbox-col',
	minWidth: 110
}, {
	id: 'canBeCopied',
	Cell: CanBeCopiedCell,
	Header: 'Копируется',
	className: 'object-page__fields-settings-checkbox-col',
	headerClassName: 'object-page__fields-settings-checkbox-col',
	minWidth: 110
}
];

export default columns;
