import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {ISavingObject} from '@tehzor/tools/interfaces/objects/ISavingObject';
import {IEditObjectResponse, makeObjectEditRequest} from '@src/api/backend/object';
import {addErrorToast} from '@src/utils/toasts';
import {createAction} from '@reduxjs/toolkit';

export type IEditObjectPayload = IEditObjectResponse;

export const editObjectRequest = createAction(types.EDIT_REQUEST);

export const editObjectSuccess = createAction<IEditObjectResponse>(types.EDIT_SUCCESS);

export const editObjectFailure = createAction(types.EDIT_FAILURE, (error: IError) => {
	if (error.statusCode !== 401) {
		addErrorToast('Ошибка', 'при изменении объекта');
	}
	return {
		payload: {...error}
	};
});

/**
 * Изменяет объект
 *
 * @param objectId id объекта
 * @param object данные объекта
 */
export const editObject = (objectId: string, object: ISavingObject) =>
	createApiAction<IEditObjectResponse>(
		editObjectRequest,
		editObjectSuccess,
		editObjectFailure,
		() => makeObjectEditRequest(objectId, object)
	);
