import React from 'react';
import {RowInfo} from 'react-table-6';
import InnerTable from './InnerTable';
import {IScopeItem} from './RolesTable';

const getTbodyProps = () => ({className: 'user-page__roles-table-tbody-inner'});

const SubComponent = ({original}: RowInfo) => {
	const {children} = original as IScopeItem;

	return children && children.length ? (
		<InnerTable
			data={children}
			getTbodyProps={getTbodyProps}
		/>
	) : null;
};

export default SubComponent;
