import IPermission from '@tehzor/tools/interfaces/IPermission';
import IRole from '@src/interfaces/IRole';
import {AnyAction} from 'redux';
import ISavingRole from '@src/interfaces/ISavingRole';

interface IEditableRole {
	name: string;
	companyId?: string;
	permissions: IPermission[];
	defaultSubs: Array<{
		type: string;
		channels: string[];
		settings?: {};
	}>;
	edited: boolean;
}

const init = (role: IRole): IEditableRole => ({
	name: role?.name || '',
	companyId: role?.company ? role.company.id : undefined,
	permissions: role?.permissions || [],
	defaultSubs: role?.defaultSubs || [],
	edited: false
});

const reducer = (state: IEditableRole, action: AnyAction): IEditableRole => {
	switch (action.type) {
		case 'update':
			return {
				...state,
				[action.field]: action.value,
				edited: true
			};
		case 'update-fields':

		return {
				...state,
				permissions: state.permissions.map(item => (item.permissionId !== action.permissionId
					? item
					: {
						...item,
						fields: item.fields
						? {
							...item.fields,
							[action.field]: {
								...item.fields[action.field],
								[action.restriction]: action.value
							}
						} : {
							[action.field]: {
								[action.restriction]: action.value
							}
						}
					}
					)),
				edited: true
			};
		case 'update-restriction':
			return {
				...state,
				permissions: state.permissions.map(item =>
				(item.permissionId !== action.permissionId
					? item
					: {
						...item,
						restrictions: {
							...item.restrictions,
							[action.restriction]: action.value
						}
					})),
				edited: true
			};
		case 'update-param':
			return {
				...state,
				permissions: state.permissions.map(item =>
				(item.permissionId !== action.permissionId
					? item
					: {
						...item,
						params: {
							...item.params,
							[action.param]: action.value
						}
					})),
				edited: true
			};
		case 'update-subs':
			const defaultSubs = state.defaultSubs.filter(item => item.type !== action.typeId);
			defaultSubs.push({
				type: action.typeId,
				channels: action.channels,
				settings: action.settings
			});
			return {
				...state,
				defaultSubs,
				edited: true
			};
		case 'reset':
			return init(action.role);
		default:
			return state;
	}
};

/**
 * Конвертирует данные в формат, пригодный для отправки на сервер
 *
 * @param {IEditableRole} edited измененные данные
 * @param {IRole} original первоначальные данные
 * @param {string|undefined} roleId id роли
 * @return {ISavingRole}
 */
const convertToSave = (edited: IEditableRole, original?: IRole, roleId?: string): ISavingRole => {
	if (!original || !roleId) {
		return {
			name: edited.name,
			companyId: edited.companyId,
			permissions: edited.permissions,
			defaultSubs: edited.defaultSubs ? edited.defaultSubs.filter(s => s.channels.length) : []
		};
	}
	const role = {} as ISavingRole;
	if (edited.name !== original.name) {
		role.name = edited.name;
	}
	if (!edited.companyId && original.company) {
		role.companyId = null;
	}
	if (edited.companyId && (!original.company || edited.companyId !== original.company.id)) {
		role.companyId = edited.companyId;
	}
	if (edited.permissions !== original.permissions) {
		role.permissions = edited.permissions;
	}
	if (edited.defaultSubs !== original.defaultSubs) {
		role.defaultSubs = edited.defaultSubs ? edited.defaultSubs.filter(s => s.channels.length) : [];
	}
	return role;
};

export {IEditableRole, init, reducer, convertToSave};
