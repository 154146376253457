import React, {useContext} from 'react';
import {RowInfo} from 'react-table-6';
import {IPreparedPermission} from './PermissionsTable';
import InnerTable from './InnerTable';
import Restrictions from './restrictions/Restrictions';

import Params from './params/Params';
import {RolePermissionsCtx} from '@src/pages/RolePage/RolePage';
import {Fields} from './fields/Fields';

const getTbodyProps = () => ({className: 'role-page__ptable-tbody-inner'});

/**
 * Потому что без обертки в момент разворачивания компонента реакт падает с hook violation
 */
const Wrapped = ({original}: RowInfo) => {
	const permissions = useContext(RolePermissionsCtx);
	const isSelected = permissions.some(item => item.permissionId === original.id);
	const {id, children, availableRestrictions, availableParams, level, fields} = original as IPreparedPermission;

	return (
		<>
			{isSelected && !!availableRestrictions?.length && (
				<Restrictions
					permissionId={id}
					restrictions={availableRestrictions}
					level={level}
				/>
			)}

			{isSelected && !!availableRestrictions?.length && (
				(fields && fields.length > 0)
				&& (
					<Fields
						permissionId={id}
						fields={fields}
						restrictions={availableRestrictions}
						level={level}
					/>
)
			)}

			{isSelected && !!availableParams?.length && (
				<Params
					permissionId={id}
					params={availableParams}
					level={level}
				/>
			)}
			{children && children.length !== 0 && (
				<InnerTable
					data={children}
					getTbodyProps={getTbodyProps}
				/>
			)}
		</>
	);
};

const SubComponent = (props: RowInfo) => <Wrapped {...props}/>;

export default SubComponent;
