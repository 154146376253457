import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import * as templatesSetTypes from '../problemTemplatesSet/constants';
import config from '@src/core/config';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';
import {CHANGE_SORT} from '@src/store/modules/settings/pages/problemTemplatesSets/constants';
import {
	IAddTemplatesSetPayload,
	IDeleteTemplatesSetPayload,
	IEditTemplatesSetPayload
} from '@src/store/modules/entities/problemTemplatesSet/actions';
import {IGetTemplatesSetsPayload} from '@src/store/modules/entities/problemTemplatesSets/actions';
import {IProblemTemplatesSet} from '@tehzor/tools/interfaces/problemTemplatesSets/IProblemTemplatesSet';

const {entitiesCacheTime} = config;

export interface ITemplatesSetsPageState {
	byId: {
		[id: string]: IProblemTemplatesSet;
	};
	allIds: string[];
	total: number;
	expires?: number;
	selectedRows: string[];
}

export type ITemplatesSetsPagesState = Record<string, ITemplatesSetsPageState>;

export const getInitialStateForPage = (): ITemplatesSetsPageState => ({
	byId: {},
	allIds: [],
	total: 0,
	expires: undefined,
	selectedRows: []
});

const remove = (state: ITemplatesSetsPagesState, objectId: string, templatesSetId: string) => {
	const page = state[objectId];
	page.allIds = page.allIds.filter(id => id !== templatesSetId);
	delete page.byId[templatesSetId];
	page.expires = undefined;
};

export default createReducer<ITemplatesSetsPagesState>(
	{},
	{
		[types.GET_REQUEST]: (state, {payload}: {payload: {objectId: string}}) => {
			if (!state.hasOwnProperty(payload.objectId)) {
				state[payload.objectId] = getInitialStateForPage();
			}
		},
		[types.GET_SUCCESS]: (state, {payload}: {payload: IGetTemplatesSetsPayload}) => {
			const page = state.all;
			page.byId = payload.byId;
			page.allIds = payload.allIds;
			page.total = payload.allIds.length;
			page.expires = Date.now() + entitiesCacheTime * 1000;
		},
		[types.CHANGE_SELECTED_ROWS]: (state, {payload}: {payload: {objectId: string, selectedRows: string[]}}) => {
			state[payload.objectId].selectedRows = payload.selectedRows;
		},
		// При сортировке нужно обнулить expires для игнорирования кеша
		[CHANGE_SORT]: state => {
			state.all.expires = undefined;
		},
		[templatesSetTypes.ADD_SUCCESS]: (state, {payload}: {payload: IAddTemplatesSetPayload}) => {
			state.all.byId[payload.id] = payload;
			state.all.allIds.push(payload.id);
			state.all.expires = undefined;
		},
		[templatesSetTypes.EDIT_SUCCESS]: (state, {payload}: {payload: IEditTemplatesSetPayload}) => {
			state.all.byId[payload.id] = payload;
			state.all.expires = undefined;
		},
		[templatesSetTypes.DELETE_SUCCESS]: (state, {payload}: {payload: IDeleteTemplatesSetPayload}) => {
			remove(state, 'all', payload.id);
		},
		[CLEAR_STORE]: () => ({})
	}
);
