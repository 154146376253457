import {wsConnector} from '@src/api/backend/wsConnector';
import {IProblemTemplatesSet} from '@tehzor/tools/interfaces/problemTemplatesSets/IProblemTemplatesSet';
import {ISavingProblemTemplatesSet} from '@tehzor/tools/interfaces/problemTemplatesSets/ISavingProblemTemplatesSet';

export type IEditTemplatesSetResponse = IProblemTemplatesSet;

/**
 * Обновляет набор шаблонов нарушений
 */
export const makeEditTemplatesSetRequest = (id: string, data: ISavingProblemTemplatesSet) =>
	wsConnector.sendAuthorizedRequest<IEditTemplatesSetResponse>('editProblemTemplatesSet', {id, ...data});
