import React, {useCallback, useRef} from 'react';
import {ResourcesLoader as ResLoader} from '@tehzor/tools/components/ResourcesLoader';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {getCompanies} from '@src/store/modules/entities/companies/actions';
import {getProfile} from '@src/store/modules/auth/profile/actions';
import {useUpdateEffect} from 'react-use';

/**
 * Компонент для первоначальной загрузки ресурсов при старте приложения
 */
export const ResourcesLoader = () => {
	const dispatch = useAppDispatch();
	const isAuthenticated = useAppSelector(s => s.auth.isAuthenticated);
	const isActivated = useAppSelector(s => s.auth.profile.activated);

	// Флаг для загрузки профиля только если был аутентифицирован и не было logout'а
	const wasAuthenticated = useRef<boolean>(isAuthenticated);

	useUpdateEffect(() => {
		if (!isAuthenticated) {
			wasAuthenticated.current = false;
		}
	}, [isAuthenticated]);

	const loadResources = useCallback(async () => {
		if (wasAuthenticated.current) {
			await dispatch(getProfile());
		}
		await dispatch(getCompanies());
	}, []);

	return (
		<ResLoader
			isOnline
			isAuthorized={isAuthenticated}
			isActivated={isActivated}
			onLoad={loadResources}
		/>
);
};
