import {IUserRole, UserRoleScopes} from '@tehzor/tools/interfaces/IUser';
import {hasPermission} from '@tehzor/tools/utils/findPermission';
import {useMemo} from 'react';

interface IPermissions {
	canAdd?: boolean;
	canEdit?: boolean;
	canDelete?: boolean;
	canRestore?: boolean;
	canArchive?: boolean;
}

export const usePermissions = (userRoles: IUserRole[], objectId?: string) =>
	useMemo<IPermissions>(() => {
		if (!objectId) {
			return {canAdd: hasPermission(userRoles, 'objectsAdd')};
		}
		return {
			canEdit:
				hasPermission(userRoles, 'objectsEdit', UserRoleScopes.ALL)
				|| hasPermission(userRoles, 'objectsEdit', UserRoleScopes.OBJECT, objectId),
			canDelete:
				hasPermission(userRoles, 'objectsDelete', UserRoleScopes.ALL)
				|| hasPermission(userRoles, 'objectsDelete', UserRoleScopes.OBJECT, objectId),
			canRestore:
				hasPermission(userRoles, 'objectsRestore', UserRoleScopes.ALL)
				|| hasPermission(userRoles, 'objectsRestore', UserRoleScopes.OBJECT, objectId),
			canArchive:
				hasPermission(userRoles, 'objectsArchive', UserRoleScopes.ALL)
				|| hasPermission(userRoles, 'objectsArchive', UserRoleScopes.OBJECT, objectId)
		};
	}, [userRoles, objectId]);
