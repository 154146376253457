import React, {memo} from 'react';
import {IconMenu, InlineButton, MenuItem, PageHeader} from '@tehzor/ui-components';
import IRole from '@src/interfaces/IRole';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {deleteRole} from '@src/store/modules/entities/roles/actions/delete';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useUserRolesPermissions} from '../hooks/useUserRolesPermissions';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractUserRoles} from '@src/store/modules/auth/profile/selectors';

const arrowIcon = <i className="tz-arrow-24 page-back-btn__icon"/>;
const bucketIcon = <i className="tz-delete menu-item__icon-bucket"/>;

interface IRolePageHeaderProps {
	roleId?: string;
	role?: IRole;
}

export const RolePageHeader = memo((props: IRolePageHeaderProps) => {
	const {roleId, role} = props;
	const {goBack, pushPath} = useChangePath();
	const dispatch = useAppDispatch();
	const userRoles = useAppSelector(extractUserRoles);
	const {canDelete} = useUserRolesPermissions(userRoles, role);

	const [deleteDialog, getDeleteConfirmation] = useConfirmDialog('Вы действительно хотите удалить роль?');

	const [, handleDelete] = useAsyncFn(async () => {
		if (roleId && (await getDeleteConfirmation())) {
			await dispatch(deleteRole(roleId));
			pushPath('/roles');
		}
	}, [roleId]);

	return (
		<PageHeader
			className="role-page__header"
			title={role && role.name ? `Роль «${role.name}»` : 'Новая роль'}
			elementBefore={(
				<InlineButton
					key="back"
					className="page-back-btn"
					type="accent"
					label="Назад"
					leftIcon={arrowIcon}
					onClick={goBack}
				/>
			)}
		>
			{canDelete && (
				<IconMenu>
					<MenuItem
						key="delete"
						className="menu-item_red"
						icon={bucketIcon}
						onClick={handleDelete}
					>
						Удалить
					</MenuItem>
				</IconMenu>
			)}
			{deleteDialog}
		</PageHeader>
	);
});