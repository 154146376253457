import {Reducer, useReducer} from 'react';
import {errorsFns, init, IEditableProblemTemplatesSetState, IEditableProblemTemplatesSetAction} from './state';
import {createReducer} from '@tehzor/tools/core/states/editableEntityState';
import {IProblemTemplatesSet} from '@tehzor/tools/interfaces/problemTemplatesSets/IProblemTemplatesSet';
import {ISavingProblemTemplatesSet} from '@tehzor/tools/interfaces/problemTemplatesSets/ISavingProblemTemplatesSet';

export const useEditableProblemTemplatesSetState = (templatesSet?: IProblemTemplatesSet) =>
	useReducer<
		Reducer<IEditableProblemTemplatesSetState, IEditableProblemTemplatesSetAction>,
		IProblemTemplatesSet | undefined
	>(createReducer<ISavingProblemTemplatesSet, IProblemTemplatesSet>(init, errorsFns), templatesSet, init);
