import React from 'react';
import {CellInfo} from 'react-table-6';

const NameCell = ({original}: CellInfo) => (
	<>
		{original.name}
		{' '}
		<span className="role-page__table-id">
			(
			{original.id}
			)
		</span>
	</>
);

export default NameCell;
