import format from 'date-fns/format';
import {dateFormat} from '@tehzor/tools/utils/dateFormats';

/**
 * Формирует текст для кнопки фильтра по датам
 *
 * @param str текст по умолчанию
 * @param from дата от
 * @param to дата до
 */
const formatDate = (date: Date) => format(date, dateFormat);
export const makeDateFilterLabel = (str?: string, from?: Date, to?: Date) => {
	if (from !== undefined && to !== undefined) {
		if (!str) {
			return `${formatDate(new Date(from))} - ${formatDate(new Date(to))}`;
		}
		return `${str}: ${formatDate(new Date(from))} - ${formatDate(new Date(to))}`;
	}
	return str;
};
