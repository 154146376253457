import NameCell from './NameCell';
import ChannelsCell from './ChannelsCell';
import SettingsExpanderCell from './SettingsExpanderCell';
import {RowInfo} from 'react-table-6';
import INotificationType from '@tehzor/tools/interfaces/notifications/INotificationType';

const getSettingsExpanderProps = (...args: any[]) => {
	const original = (args[1] as RowInfo).original as INotificationType;
	if (!original.availableSettings || !original.availableSettings.length) {
		return {onClick: () => null};
	}
	return {};
};

const columns = [
{
	id: 'name',
	Cell: NameCell,
	className: 'role-page__subs-table-name-col',
	minWidth: 100
}, {
	id: 'channels',
	Cell: ChannelsCell,
	className: 'role-page__subs-table-channels-col',
	minWidth: 60,
	maxWidth: 500
}, {
	id: '_settingsExpander',
	Expander: SettingsExpanderCell,
	expander: true,
	className: 'role-page__ptable-rest-expander-col',
	width: 50,
	getProps: getSettingsExpanderProps
}
];

export default columns;
