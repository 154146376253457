import React, {Context, useCallback, useContext} from 'react';
import {RoleDispatchCtx, RolePermissionsCtx} from '../../../RolePage';
import {Option, Select} from '@tehzor/ui-components';
import {IParamProps} from './Param';

interface SelectParam {
	id: string;
	name: string;
}

interface ISelectableParamProps<T extends SelectParam> extends IParamProps {
	context: Context<T[]>;
}

const SelectableParam = <T extends SelectParam>({id, name, permissionId, context}: ISelectableParamProps<T>) => {
	const permissions = useContext(RolePermissionsCtx);
	const dispatch = useContext(RoleDispatchCtx);
	const items = useContext(context);

	const p = permissions.find(item => item.permissionId === permissionId);
	const selected = p?.params?.[id] as string;

	const onChange = useCallback(
		(value: string) => {
			dispatch({
				type: 'update-param',
				permissionId,
				param: id,
				value
			});
		},
		[permissionId, id]
	);

	return (
		<div className="role-page__settings-select-wrap">
			<div className="role-page__settings-label-wrap">
				<span className="role-page__settings-label">{name}</span>
				<span className="role-page__settings-id">
					(
					{id}
					)
				</span>
			</div>

			<Select
				className="role-page__settings-select"
				value={selected}
				onChange={onChange}
			>
				{items.map(item => (
					<Option
						key={item.id}
						value={item.id}
					>
						{item.name}
					</Option>
				))}
			</Select>
		</div>
	);
};

export default SelectableParam;
