import React, {memo, useCallback, useState} from 'react';
import './AppHeader.less';
import {useIsLargeTablet} from '@tehzor/ui-components/src/utils/mediaQueries';
import useAppSelector from '@src/core/hooks/useAppSelector';
import DesktopAppHeader from './components/DesktopAppHeader';
import MobileAppHeader from './components/MobileAppHeader';
import {AppHeaderCtx, AppHeaderCtxOptions} from './utils/AppHeaderCtx';
import {IHeaderSectionsMenuItem} from '@src/interfaces/IHeaderSectionsMenuItem';

interface IAppHeaderProps {
	children?: React.ReactNode;
}

/**
 * Шапка приложения
 */
const AppHeader = ({children}: IAppHeaderProps) => {
	const isAuthenticated = useAppSelector(s => s.auth.isAuthenticated);

	const [title, setTitle] = useState<string | undefined>();
	const [subTitle, setSubTitle] = useState<string | undefined>();
	const [backBtnVisible, setBackBtnVisible] = useState<boolean | undefined>(false);
	const [sectionsMenu, setSectionsMenu] = useState<IHeaderSectionsMenuItem[] | undefined>();
	const [mobileButtons, setMobileButtons] = useState<React.ReactNode | undefined>();

	const ctxValue = useCallback((options?: AppHeaderCtxOptions) => {
		setTitle(options?.title);
		setSubTitle(options?.subTitle);
		setBackBtnVisible(options?.showBackBtn);
		setSectionsMenu(options?.sectionsMenu);
		setMobileButtons(options?.mobileButtons);
	}, []);

	const isDesktop = useIsLargeTablet();

	return (
		<>
			{isAuthenticated
				&& (isDesktop ? (
					<DesktopAppHeader
						title={title}
						backBtnVisible={backBtnVisible}
						sectionsMenu={sectionsMenu}
					/>
				) : (
					<MobileAppHeader
						title={title}
						subTitle={subTitle}
						backBtnVisible={backBtnVisible}
						sectionsMenu={sectionsMenu}
						mobileButtons={mobileButtons}
					/>
				))}

			<AppHeaderCtx.Provider value={ctxValue}>{children}</AppHeaderCtx.Provider>
		</>
	);
};

export default memo(AppHeader);
