import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';
import ICustomizableField, {CustomizableFieldDestination} from '@tehzor/tools/interfaces/ICustomizableField';

const getAllIds = (state: IState) => state.entities.customizableFields.allIds || [];
const getById = (state: IState): { [id: string]: ICustomizableField } => state.entities.customizableFields.byId || {};
const getDestination = (state: IState, props: { destination: CustomizableFieldDestination }) => props.destination;

/**
 * Возвращает настраиваемые поля в виде массива
 */
export const getCustomizableFieldsAsArray = createSelector(
	[getAllIds, getById],
	(allIds, byId) => allIds.map(id => byId[id])
);

/**
 * Возвращает настраиваемые поля, отфильтрованные по назначению
 */
export const getCustomizableFieldsByDest = createSelector(
	[getCustomizableFieldsAsArray, getDestination],
	(items, destination) => items.filter(item => item.destinations.includes(destination))
);
