import {IState} from '@src/store/modules';
import checkExpiration from '@tehzor/tools/utils/checkExpiration';
import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {AppThunkAction} from '@src/store/interfaces/thunk';
import {ApiAction, createApiAction} from '@src/store/middlewares/api';
import {IGetTemplatesSetResponse, requestTemplatesSet} from '@src/api/backend/problemTemplatesSet';

export type IGetTemplatesSetPayload = IGetTemplatesSetResponse;

const request = (id: string) => ({
	type: types.GET_REQUEST,
	payload: {id}
});

const success = (response: IGetTemplatesSetResponse) => ({
	type: types.GET_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	if (error.statusCode !== 401) {
		addErrorToast('Ошибка', 'при загрузке набора шаблонов нарушений');
	}
	return {
		type: types.GET_FAILURE,
		payload: error
	};
};

/**
 * Получает набор шаблонов нарушений по Id
 */
export const getTemplatesSet
	= (id: string): AppThunkAction<Promise<IGetTemplatesSetResponse>> =>
	(dispatch, getState) => {
		const state = getState();

		const apiAction = createApiAction<IGetTemplatesSetResponse>(
			() => request(id),
			success,
			failure,
			() => requestTemplatesSet(id)
		);

		return state.entities.problemTemplatesSet.data?.id === id
			? dispatch(
					checkExpiration<IState, IGetTemplatesSetResponse, ApiAction>(
						s => s.entities.problemTemplatesSet,
						apiAction
					)
			  )
			: dispatch(apiAction);
	};
