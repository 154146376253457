import React from 'react';
import {MobileHeader, MobileHeaderMenu} from '@tehzor/ui-components';
import {IHeaderSectionsMenuItem} from '@src/interfaces/IHeaderSectionsMenuItem';
import {useLocation} from 'react-router-dom';
import MobileLeftButton from './MobileLeftButton';
import {useChangePath} from '@src/core/hooks/useChangePath';
// import {useIsTablet} from '@tehzor/ui-components/src/utils/mediaQueries';

interface IMobileAppHeaderProps {
	title?: string;
	subTitle?: string;
	backBtnVisible?: boolean;
	sectionsMenu?: IHeaderSectionsMenuItem[];
	mobileButtons?: React.ReactNode;
}

const MobileAppHeader = (props: IMobileAppHeaderProps) => {
	const {title, subTitle, backBtnVisible, sectionsMenu, mobileButtons} = props;
	const location = useLocation();
	const {replacePath} = useChangePath();
	// const isTablet = useIsTablet();

	return (
		<MobileHeader
			className="app-header"
			title={title}
			subTitle={subTitle}
			left={<MobileLeftButton backBtnVisible={backBtnVisible}/>}
			right={mobileButtons}
			sectionsMenu={(
				<MobileHeaderMenu
					items={sectionsMenu}
					value={location.pathname}
					onChange={replacePath}
				/>
			)}
			// hideOnScroll={!isTablet}
			// scrollContainerId="scroll-container"
		/>
	);
};

export default MobileAppHeader;
