const domain = 'user';

export const ADD_REQUEST = `${domain}/add/request`;
export const ADD_SUCCESS = `${domain}/add/success`;
export const ADD_FAILURE = `${domain}/add/failure`;

export const EDIT_REQUEST = `${domain}/edit/request`;
export const EDIT_SUCCESS = `${domain}/edit/success`;
export const EDIT_FAILURE = `${domain}/edit/failure`;

export const DELETE_REQUEST = `${domain}/delete/request`;
export const DELETE_SUCCESS = `${domain}/delete/success`;
export const DELETE_FAILURE = `${domain}/delete/failure`;

export const RESTORE_REQUEST = `${domain}/restore/request`;
export const RESTORE_SUCCESS = `${domain}/restore/success`;
export const RESTORE_FAILURE = `${domain}/restore/failure`;

export const CHECK_CONTENT_REQUEST = `${domain}/content/check/request`;
export const CHECK_CONTENT_SUCCESS = `${domain}/content/check/success`;
export const CHECK_CONTENT_FAILURE = `${domain}/content/check/failure`;
