import {Column} from 'react-table';
import MenuCell from './MenuCell';
import {IPreparedProblemTemplatesSet} from '@tehzor/tools/interfaces/problemTemplatesSets/IPreparedProblemTemplatesSet';

export const desktopColumns: Array<Column<IPreparedProblemTemplatesSet>> = [
	{
		accessor: 'name',
		Header: 'Название',
		width: 100
	},
	{
		accessor: row => (row.shared ? 'Да' : 'Нет'),
		Header: 'Общий',
		width: 100
	},
	{
		Header: 'Автор',
		accessor: row => row.createdBy,
		width: 100
	},
	{
		Header: 'Добавлено',
		accessor: 'createdAt',
		width: 80
	},
	{
		Header: 'Изменено',
		accessor: 'modifiedAt',
		width: 80
	},
	{
		id: '_menu',
		Cell: MenuCell,
		className: 'problems-page__d-table-menu',
		width: 68,
		minWidth: 68,
		defaultCanSort: false,
		disableResizing: true,
		isNonClickable: true
	}
];

export const desktopColumnsWithoutObject = desktopColumns.filter(item => item.id !== 'object');
