import {httpRequests} from '@tehzor/tools/api/httpRequests';
import ITempFile from '@tehzor/tools/interfaces/ITempFile';
import {TempFileDestination} from '@tehzor/tools/enums/TempFileDestination';
import axios from 'axios';

const CancelToken = axios.CancelToken;

export type IAddTempFilesResponse = ITempFile;

/**
 * Добавляет временный файл
 *
 * @param file файл
 * @param destination назначение, где будет использоваться файл
 * @param onInitialized функция, вызываемая после инициализации запроса,
 * первым аргументом передаётся abort-функция
 * @param onProgress функция, вызываемая при обновлении процесса выгрузки
 */
export const makeTempFilesAddRequest = async (
	file: File,
	destination: TempFileDestination,
	onInitialized?: (abort: () => void) => void,
	onProgress?: (progress: number) => void
) => {
	const formData = new FormData();
	formData.append('destination', destination);
	formData.append('file', file);

	const response = await httpRequests.withToken.post<IAddTempFilesResponse>('/temp-files', formData, {
		headers: {'Content-Type': 'multipart/form-data'},
		timeout: 240000,
		cancelToken: onInitialized ? new CancelToken(onInitialized) : undefined,
		onUploadProgress: (event: ProgressEvent) => {
			if (onProgress) {
				onProgress(Math.round((event.loaded * 100) / event.total));
			}
		}
	});
	return response.data;
};
