import React, {memo} from 'react';
import {ILogRecord} from '@tehzor/tools/interfaces/ILogRecord';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {getLogRecordTypesById} from '@src/selectors/entities/logRecordTypes';

interface IDetailsListProps {
	typeId: string;
	fields?: ILogRecord['fields'];
}

export const DetailsInfo = memo(({typeId, fields}: IDetailsListProps) => {
	const typesById = useAppSelector(getLogRecordTypesById);

	const type = typesById[typeId];

	if (!fields) {
		return null;
	}

	return (
		<>
			<div className="field__label log-page__details-title">Информация:</div>

			<div className="log-page__details-list">
				{type?.availableFields
					? type.availableFields.map(item =>
							(fields[item.key] ? (
								<div
									key={item.key}
									className="log-page__details-list-row"
								>
									<div className="log-page__details-list-name">{item.name}</div>
									<div className="log-page__details-list-value">{fields[item.key]}</div>
								</div>
							) : null))
					: Object.entries(fields).map(([key, value]) => (
						<div
							key={key}
							className="log-page__details-list-row"
						>
							<div className="log-page__details-list-name">{key}</div>
							<div className="log-page__details-list-value">{value}</div>
						</div>
					  ))}
			</div>
		</>
	);
});
