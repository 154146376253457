import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {wsConnector} from '@src/api/backend/wsConnector';
import IUser from '@tehzor/tools/interfaces/IUser';

export type IGetUsersResponse = INormalizedData<IUser>;

/**
 * Получает список пользователей
 */
export const requestUsers = () => wsConnector.sendAuthorizedRequest<IGetUsersResponse>('getUsers');
