import React, {useMemo} from 'react';
import './ContentCounts.less';
import useAsync from 'react-use/lib/useAsync';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {checkUserContent} from '@src/store/modules/entities/user/actions';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {SimpleTable} from '@tehzor/ui-components';
import {Column} from 'react-table';

interface IContentCountsProps {
	userId: string;
}

interface ITableItem extends Record<string, unknown> {
	key: string;
	name: string;
	value: number;
}

const columns: Array<Column<ITableItem>> = [
	{
		Header: 'Название',
		accessor: 'name',
		className: 'user-content-counts-table__name'
	},
	{
		Header: 'Количество',
		accessor: 'value'
	}
];

const fields = [
	{key: 'checks', name: 'Проверки'},
	{key: 'checkStories', name: 'Истории проверок'},
	{key: 'problems', name: 'Нарушения'},
	{key: 'problemStories', name: 'Истории нарушений'},
	{key: 'inspections', name: 'Осмотры'},
	{key: 'inspectionStories', name: 'Истории осмотров'},
	{key: 'warrantyClaims', name: 'Гарантийные обращения'},
	{key: 'warrantyClaimStories', name: 'Истории гарантийных обращений'},
	{key: 'categories', name: 'Виды работ'},
	{key: 'categoryStories', name: 'Истории видов работ'},
	{key: 'plans', name: 'Планы'},
	{key: 'planStories', name: 'Истории планов'}
];

const ContentCounts = ({userId}: IContentCountsProps) => {
	const counts = useAppSelector(s => s.entities.user.contentCounts);
	const dispatch = useAppDispatch();

	useAsync(async () => {
		await dispatch(checkUserContent(userId));
	});

	const data = useMemo(
		(): ITableItem[] =>
			(counts
				? fields.map(item => ({
						...item,
						value: counts[item.key]
				  }))
				: []),
		[counts]
	);

	return (
		<>
			<div className="field__wide-label-wrap">
				<div className="field__label">Количество записей, ссылающихся на пользователя:</div>
			</div>

			<SimpleTable
				className="user-content-counts-table"
				columns={columns}
				data={data}
				showHead={false}
			/>
		</>
	);
};

export default ContentCounts;
