import React, {memo, MouseEvent} from 'react';
import {CellContext} from '@tanstack/react-table';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import useConfirmDialog from '../../../../../../ui-components/src/hooks/useConfirmDialog';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {deleteObject} from '@src/store/modules/entities/object/actions';
import './DeleteObjectCell.less';
import {IconButton} from '@tehzor/ui-components';
import classNames from 'classnames';

const deleteIcon = <i className="tz-delete"/>;
export const DeleteObjectCell = memo(({row}: CellContext<IObject, IObject>) => {
	const dispatch = useAppDispatch();
	const [dialog, getDeleteConfirmation] = useConfirmDialog(
		`Вы действительно хотите удалить "${row.original.name}"?`,
		undefined,
		{acceptBtnProps: {type: 'accent-red'}}
	);

	const [, handleObjectDelete] = useAsyncFn(
		async (e: MouseEvent<HTMLButtonElement>) => {
			e.stopPropagation();
			if (await getDeleteConfirmation()) {
				await dispatch(deleteObject(row.original.id));
			}
		},
		[row.original.id]
	);

	const objectDeleted = row.original.deleted ? 'Да' : 'Нет';
	return (
		<div className="delete-object-cell">
			{objectDeleted}
			<IconButton
				className={classNames('delete-object-cell__delete-button', {
					'tz-delete-disabled': row.original.deleted
				})}
				onClick={handleObjectDelete}
				type="transparent"
				disabled={row.original.deleted}
			>
				{deleteIcon}
			</IconButton>

			{dialog}
		</div>
	);
});
