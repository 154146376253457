import {useContext, useEffect} from 'react';
import {PageLeavingBlockCtx} from './PageLeavingBlockCtx';
import useUnmount from 'react-use/lib/useUnmount';

/**
 * Блокирует переход при isBlocking === true
 *
 * @param key ключ для идентификации компонента, вызывающего блокировку
 * @param isBlocking флаг блокировки
 * @param title заголовок окна подтверждения
 * @param message сообщения окна подтверждения
 * @param whitelist адреса, при переходе на которые блокировка не будет происходить
 */
export function usePageLeavingBlock(
	key: string,
	isBlocking: boolean,
	title?: string,
	message?: string,
	whitelist?: string[]
) {
	const ctx = useContext(PageLeavingBlockCtx);

	useEffect(() => {
		if (isBlocking) {
			ctx.add({key, title, message, whitelist});
		} else {
			ctx.delete(key);
		}
	}, [isBlocking, ctx]);

	useUnmount(() => {
		ctx.delete(key);
	});
}
