import {RowInfo} from 'react-table-6';
import ExpanderCell from './ExpanderCell';
import NameCell from './NameCell';
import RoleCell from './RoleCell';

const getExpanderProps = (...args: any[]) => {
	const {original} = args[1] as RowInfo;
	if (!original.children || !original.children.length) {
		return {onClick: () => null};
	}
	return {};
};

const columns = [
{
	id: '_expander',
	Expander: ExpanderCell,
	expander: true,
	className: 'user-page__roles-table-expander-col',
	width: 50,
	getProps: getExpanderProps
}, {
	id: 'name',
	Cell: NameCell,
	className: 'user-page__roles-table-name-col',
	minWidth: 100
}, {
	id: 'role',
	Cell: RoleCell,
	width: 350
}
];

export default columns;
