import React, {memo} from 'react';
import {Button, PageHeader} from '@tehzor/ui-components';
import {changeLogRecordsOffset, getLogRecords} from '@src/actions/entities/logRecords';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {getLogRecordsCount} from '@src/selectors/entities/logRecords';

export const LogPageHeader = memo(() => {
	const dispatch = useAppDispatch();
	const logRecordsCount = useAppSelector(getLogRecordsCount);
	const reload = () => {
		dispatch(changeLogRecordsOffset(0));
		dispatch(getLogRecords());
	};

	return (
		<PageHeader
			className="log-page__header"
			title="Лог действий"
			subTitle={`Всего записей: ${logRecordsCount}`}
			wrapClassName="log-page__header-wrap"
			leftSideClassName="log-page__header-left"
			rightSideClassName="log-page__header-right"
		>
			<Button
				key="reload"
				label="Обновить"
				type="accent-blue"
				onClick={reload}
			/>
		</PageHeader>
	);
});
