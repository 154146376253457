import {createContext, Dispatch, useContext} from 'react';
import {IEditableEntityAction} from '../../../../tools/core/states/editableEntityState';

export const createUseDispatchContext = <S, E>() => {
	const DispatchContext = createContext<Dispatch<IEditableEntityAction<S, E>>>(() => null);
	const useDispatchContext = () => useContext(DispatchContext);

	return {
		DispatchContext,
		useDispatchContext
	};
};
