import {combineReducers} from 'redux';
import {objectsSettingsReducer} from './objects/objectsSettingsSlice';
import problemTemplatesSets from './problemTemplatesSets/reducers';
import {rolesSettingsReducer} from './roles/rolesSettingsSlice';
import {companiesSettingsReducer} from './companies/companiesSettingsSlice';
import {usersSettingsReducer} from './users/usersSettingsSlice';
import {regulatoryStandardsSettingsReducer} from './regulatoryStandards/regulatoryStandardsSettingsSlice';
import {logSettingsReducer} from './log/logSettingsSlice';

export default combineReducers({
	log: logSettingsReducer,
	users: usersSettingsReducer,
	roles: rolesSettingsReducer,
	objects: objectsSettingsReducer,
	regulatoryStandards: regulatoryStandardsSettingsReducer,
	problemTemplatesSets,
	companies: companiesSettingsReducer
});
