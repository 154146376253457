import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {IRestoreUserResponse, makeUserRestoreRequest} from '@src/api/backend/user';
import {addErrorToast} from '@src/utils/toasts';

export type IRestoreUserPayload = IRestoreUserResponse;

const request = () => ({type: types.RESTORE_REQUEST});

const success = (response: IRestoreUserResponse) => ({
	type: types.RESTORE_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	if (error.statusCode !== 401) {
		addErrorToast('Ошибка', 'при восстановлении пользователя');
	}
	return {
		type: types.RESTORE_FAILURE,
		payload: {...error}
	};
};

/**
 * Восстанавливает пользователя
 *
 * @param userId id пользователя
 */
export const restoreUser = (userId: string) =>
	createApiAction<IRestoreUserResponse>(request, success, failure, () => makeUserRestoreRequest(userId));
