import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {IDeleteUserResponse, makeUserDeleteRequest} from '@src/api/backend/user';
import {addErrorToast} from '@src/utils/toasts';
import IUser from '@tehzor/tools/interfaces/IUser';

export interface IDeleteUserPayload {
	id: string;
	user?: IUser;
}

const request = () => ({type: types.DELETE_REQUEST});

const success = (response: IDeleteUserResponse, userId: string) => ({
	type: types.DELETE_SUCCESS,
	payload: {
		id: userId,
		user: response.hasOwnProperty('success') ? undefined : response
	} as IDeleteUserPayload
});

const failure = (error: IError) => {
	if (error.statusCode !== 401) {
		addErrorToast('Ошибка', 'при удалении пользователя');
	}
	return {
		type: types.DELETE_FAILURE,
		payload: {...error}
	};
};

/**
 * Удаляет пользователя
 *
 * @param userId id пользователя
 */
export const deleteUser = (userId: string) =>
	createApiAction<IDeleteUserResponse>(
		request,
		res => success(res, userId),
		failure,
		() => makeUserDeleteRequest(userId)
	);
