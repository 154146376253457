import * as types from '@src/constants/entities/customizableFields';
import createReducer from '@tehzor/tools/utils/createReducer';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';
import ICustomizableField from '@tehzor/tools/interfaces/ICustomizableField';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';

interface ICustomizableFieldsState {
	byId: {
		[id: string]: ICustomizableField;
	};
	allIds: string[];
	loaded: boolean;
}

const initialState = {
	byId: {},
	allIds: [],
	loaded: false
} as ICustomizableFieldsState;

const customizableFields = createReducer<ICustomizableFieldsState>(initialState, {
	[types.GET_CUSTOMIZABLE_FIELDS_SUCCESS]: (state, payload: INormalizedData<ICustomizableField>) => ({
		byId: payload.byId,
		allIds: payload.allIds,
		loaded: true
	}),
	[types.GET_CUSTOMIZABLE_FIELDS_FAILURE]: () => ({...initialState}),
	[CLEAR_STORE]: () => ({...initialState})
});

export {ICustomizableFieldsState};
export default customizableFields;
