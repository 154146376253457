import React from 'react';
import EditableTextField from '@src/components/editableFields/EditableTextField';
import {IEditableCompanyState, useEditableCompanyDispatchCtx} from '@src/core/hooks/states/useEditableCompanyState';

interface IMainTabProps {
	editingState: IEditableCompanyState;
	disabled?: boolean;
}

const MainTab = ({editingState, disabled}: IMainTabProps) => {
	const editingDispatch = useEditableCompanyDispatchCtx();

	return (
		<div className="company-page__main-data-grid">
			<EditableTextField
				label="Название"
				value={editingState.name}
				fieldName="name"
				editingDispatch={editingDispatch}
				required
				disabled={disabled}
				hasError={editingState.errors.name}
				errorMessage="Введите название"
			/>
		</div>
	);
};

export default MainTab;
