import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';
import arrayToTree from 'array-to-tree';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';

const getAllIds = (state: IState) => state.entities.objects.allIds || [];
const getById = (state: IState) => state.entities.objects.byId || {};
const getObjectId = (state: IState, objectId: string | undefined) => objectId;

const getParentObjects = (objects: IObject[], objectId: string, parentObjects: IObject[]) => {
	const object = objects.find(object => object.id === objectId);
	if (object) {
		parentObjects.push(object);
	}
	if (object?.parentId) {
		getParentObjects(objects, object.parentId, parentObjects);
	}
	return parentObjects;
};

// const getFilter = (/* state: IState */) => /* state.settings.objectsPage.filter || */ ({objectName: '', companies: []});
// const getObjectId = (state: IState, props: {objectId?: string, match?: match<{objectId?: string}>}) =>
// 	props.objectId || (props.match && props.match.params && props.match.params.objectId) || '';
// const getObjectId = (state: IState, objectId: string) => objectId;

/**
 * Возвращает количество объектов
 */
// export const extractObjectsCount = createSelector([getAllIds], allIds => allIds.length);

/**
 * Возвращает объекты в виде массива
 */
export const extractObjectsAsArray = createSelector([getAllIds, getById], (allIds, byId) => allIds.map(id => byId[id]));

/**
 * Возвращает родительские объекты в виде массив
 */
export const extractParentObjects = createSelector(
	[extractObjectsAsArray, getObjectId],
	(objects, objectId) => getParentObjects(objects, objectId || '', [])
);

/**
 * Возвращает объект и его детей в виде массива
 */
 export const extractObjectWithChilds = createSelector(
	[extractObjectsAsArray, getObjectId],
	(objects, objectId) => objects.filter(object =>
			object.ancestors?.includes(objectId || '')
			|| object.id === objectId)
);

/**
 * Возвращает текущий объект
 */
// export const extractCurrentObject = createSelector(
// 	[getById, getObjectId],
// 	(byId: {[id: string]: IObject}, objectId: string) => byId[objectId] || ({name: ''} as IObject)
// );

/**
 * Возвращает объекты в виде дерева
 */
export const extractObjectsAsTree = createSelector([extractObjectsAsArray], objects =>
	arrayToTree<IObject>(objects, {
		parentProperty: 'parentId',
		customID: 'id'
	}));

/**
 * Возвращает отфильрованные объекты
 */
// export const extractFilteredTreeObjects = createSelector([extractObjectsAsTree, getFilter], (tree, filter) => {
// 	const hasName = !!filter.objectName;
// 	const hasCompanies = Array.isArray(filter.companies) && filter.companies.length;
//
// 	return tree.filter(
// 		object =>
// 			(hasName ? object.name.toLowerCase().includes(filter.objectName.toLowerCase()) : true)
// 			&& (hasCompanies && object.company ? (filter.companies as string[]).includes(object.company.id) : true)
// 	);
// });

/**
 * Возвращает текущий объект из дерева
 */
// export const extractCurrentTreeObject = createSelector(
// 	[extractObjectsAsTree, getObjectId],
// 	(tree, objectId) => findTreeNode(tree, objectId) || ({name: ''} as Tree<IObject>)
// );

/**
 * Возвращает родительский объект текущего объекта
 */
// export const extractParentTreeObject = createSelector(
// 	[extractObjectsAsTree, extractCurrentTreeObject],
// 	(tree, object) => object && object.parentId && findTreeNode(tree, object.parentId)
// );

// export const extractObjectRespRules = createSelector([extractCurrentObject], object => {
// 	if (!object.contractors) {
// 		return [] as IObjectRespRule[];
// 	}
// 	let rules = [] as IObjectRespRule[];
// 	for (const c of object.contractors) {
// 		if (c.respRules) {
// 			rules = rules.concat(c.respRules.filter((r: IObjectRespRule) => r.user));
// 		}
// 	}
// 	return rules;
// });

// export const extractObjectRespUsers = createSelector([extractObjectRespRules], rules =>
// 	rules
// 		.map(rule => rule.user || ({} as IBriefUser))
// 		.filter((user, i, all) => i === all.findIndex(u => user.id === u.id))
// 		.sort((a: IBriefUser, b: IBriefUser) => (a.fullName > b.fullName ? 1 : b.fullName > a.fullName ? -1 : 0)));

// export const extractObjectAutoSelectRespRules = createSelector([extractObjectRespRules], rules =>
// 	rules.filter(rule => rule.autoSelect));

// export const extractObjectFieldsSettings = createSelector(
// 	[extractCurrentObject],
// 	object => object.fieldsSettings || ({} as NonNullable<IObject['fieldsSettings']>)
// );
