import React, {memo, useMemo, useState} from 'react';
import {FilterButton, Select2, SelectOption, SelectPopup, SelectSearch} from '@tehzor/ui-components';

import IRole from '@src/interfaces/IRole';
import {flatFilter} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';
import {makeFilterLabel} from '@src/utils/makeFiltersLabel';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';

interface IRoleSelectProps {
	roles?: IRole[];
	roleId?: string;
	changeFilter: (value: string) => void;
}

const LABEL = 'Роль';
export const RoleSelect = memo((props: IRoleSelectProps) => {
	const {roles = [], roleId, changeFilter} = props;
	const [search, setSearch] = useState('');
	const [selectedRole, setSelectedRole] = useState('');

	useUpdateEffect(() => {
		if (roleId) {
			setSelectedRole(roleId);
		} else {
			setSelectedRole('');
		}
	}, [roleId]);

	const handleApply = () => {
		changeFilter(selectedRole);
		setSearch('');
	};

	const handleCancel = () => {
		setSelectedRole('');
		setSearch('');
	};

	const handleFullClear = () => {
		setSelectedRole('');
		changeFilter('');
	};

	const filteredData = useMemo(() => flatFilter(roles, 'name', search), [roles, search]);
	const roleName = roleId && roles?.find(c => c.id === roleId)?.name;
	return (
		<SelectPopup
			footer
			onCancel={handleCancel}
			onApply={handleApply}
			trigger={(
				<FilterButton
					label={makeFilterLabel(LABEL, roleName)}
					onClear={handleFullClear}
					active={Boolean(roleId)}
				/>
			)}
			search={(
				<SelectSearch
					value={search}
					onChange={setSearch}
					type="popup"
				/>
			)}
		>
			<Select2
				onChange={setSelectedRole}
				value={selectedRole}
			>
				{filteredData?.map(option => (
					<SelectOption
						content={option.name}
						inputType="radio"
						itemKey={option.id}
						key={option.id}
					/>
				))}
			</Select2>
		</SelectPopup>
	);
});
