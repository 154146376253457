import React from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractFilteredDevicesAsArray} from '@src/store/modules/entities/devices/selectors';
import {IconButton} from '@tehzor/ui-components';
import {format} from 'date-fns';
import {dateTimeCommaSeparatedFormat} from '@tehzor/tools/utils/dateFormats';
import {useAsyncFn} from 'react-use';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {deleteDevice} from '@src/store/modules/entities/devices/actions/delete';
import './DevicesHistory.less';
import {logout} from '@src/store/modules/auth/actions';
import {useGetDeviceId} from '@src/core/hooks/useGetDeviceId';
import {useUsersPermissions} from '@src/core/hooks/permissions/useUsersPermissions';

interface IDevicesHistoryProps {
	userId: string;
}

const deleteIcon = <i className="tz-delete"/>;

const DevicesHistory = ({userId}: IDevicesHistoryProps) => {
	const devices = useAppSelector(s => extractFilteredDevicesAsArray(s, userId));
	const permissions = useUsersPermissions();
	const deviceId = useGetDeviceId();

	const dispatch = useAppDispatch();

	const [deleteDialog, getDeleteConfirmation] = useConfirmDialog(
		'Выйти',
		'Вы действительно хотите выйти из устройства?',
		{acceptBtnProps: {type: 'accent-red'}}
	);

	const [, handleDelete] = useAsyncFn(async (id: string) => {
		if (await getDeleteConfirmation()) {
			await dispatch(deleteDevice(userId, id));

			if (deviceId === id) {
				await dispatch(logout());
			}
		}
	});

	return (
		<div>
			{devices.map(({id, platform, browser, os, appName, lastUsedAt, createdAt}) => (
				<div
					className="devices-history__plate-card"
					key={id}
				>
					<div className="devices-history__plate-card__content">
						<div className="devices-history__plate-card__content-item">
							<span>Платформа:</span>
							{' '}
							{platform}
						</div>
						<div className="devices-history__plate-card__content-item">
							<span>Операционная система:</span>
							{' '}
							{os}
						</div>
						<div className="devices-history__plate-card__content-item">
							<span>Браузер:</span>
							{' '}
							{browser}
						</div>
						<div className="devices-history__plate-card__content-item">
							<span>Первый вход в аккаунт:</span>
							{' '}
							{format(new Date(createdAt || 0), dateTimeCommaSeparatedFormat)}
						</div>
						<div className="devices-history__plate-card__content-item">
							<span>Недавние действия:</span>
							{' '}
							{format(new Date(lastUsedAt || 0), dateTimeCommaSeparatedFormat)}
						</div>
						<div className="devices-history__plate-card__content-item">
							<span>Приложение:</span>
							{' '}
							{appName}
						</div>
					</div>
					{permissions.canDelete && (
						<IconButton
							className="devices-history__plate-card-btn"
							type="inline-red-accent"
							onClick={() => handleDelete(id)}
						>
							{deleteIcon}
						</IconButton>
					)}
				</div>
			))}
			{deleteDialog}
		</div>
	);
};

export default DevicesHistory;
