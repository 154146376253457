import createReducer from '@tehzor/tools/utils/createReducer';
import {combineReducers} from 'redux';
import {
	CHANGE_SECTION_LINK,
	CHANGE_SECTION_TITLE,
	TOGGLE_SIDEBAR_DOCKED
} from '@src/constants/ui/app';

interface IUpdating {
	newVersion: string | null;
	isDialogOpen: boolean;
}

interface IAppUIState {
	isSidebarDocked: boolean;
	updating: IUpdating;
}

const isSidebarDocked = createReducer<boolean>(false, {
	[TOGGLE_SIDEBAR_DOCKED]: (state, payload) => (
		payload !== undefined ? payload : !state
	)
});

const sectionTitle = createReducer<string>('', {
	[CHANGE_SECTION_TITLE]: (state, payload) => payload
});

const sectionLink = createReducer<string>('', {
	[CHANGE_SECTION_LINK]: (state, payload) => payload
});

const app = combineReducers({
	isSidebarDocked,
	sectionTitle,
	sectionLink
});

export {IAppUIState};
export default app;
