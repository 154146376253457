import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';
import ICompany from '@tehzor/tools/interfaces/companies/ICompany';
import {getScopeIds, hasPermission} from '@tehzor/tools/utils/findPermission';
import {UserRoleScopes} from '@tehzor/tools/interfaces/IUser';
import {extractUserRoles} from '@src/store/modules/auth/profile/selectors';
import {ICompanyWithCustomer} from '@src/pages/CompaniesPage/components/CompaniesTable/buildCompaniesColumns';
import {checkDateInRange} from '@src/utils/checkDateInRange';
import {handlePagination} from '@src/utils/handlePagination';

const getAllIds = (state: IState) => state.entities.companies.allIds || [];
const getById = (state: IState) => state.entities.companies.byId || {};
const getCompanyId = (state: IState, companyId: string) => companyId;
const getCompanyIds = (state: IState, companyIds: string[]) => companyIds;
const getObjectId = (state: IState, objectId: string | undefined) => objectId;
const getRoleId = (state: IState, roleId: string | undefined) => roleId;

export const getCompaniesFilter = (state: IState) => state.settings.pages.companies.filter;
export const getCompaniesSettings = (state: IState) => state.settings.pages.companies;
export const getCompaniesPageSortSettings = (state: IState) => state.settings.pages.companies.sorting;
export const getCompaniesPageOffset = (state: IState) => state.settings.pages.companies.offset || 0;
export const getCompaniesPageSize = (state: IState) => state.settings.pages.companies.pageSize || 20;

export const extractCompaniesAsMap = (state: IState) => state.entities.companies.byId;

/**
 * Возвращает компании в виде массива
 */
export const extractCompaniesAsArray = createSelector([getAllIds, getById], (allIds, byId) =>
	allIds.map((id: string) => byId[id]));

/**
 * Возвращает компании которые могут быть подрядчиками в виде массива
 */
export const extractPotentialContractorCompaniesAsArray = createSelector([extractCompaniesAsArray], companies =>
	companies.filter(company => !company.contractors || company.contractors.length === 0));

/**
 * Возвращает текущую компанию
 */
export const extractCompany = createSelector(
	[getById, getCompanyId],
	(byId, companyId): ICompany | undefined => byId[companyId]
);

/**
 * Возвращает компании в виде массива добавляя поле customers
 */
export const extractCompaniesWithCustomers = createSelector([extractCompaniesAsArray], companies => {
	const companiesWithContractors = companies.filter(({contractors}) => contractors);
	return companies.map(company => {
		const customers = companiesWithContractors.filter(({contractors}) =>
			contractors?.some(({subCompanyId}) => subCompanyId === company.id));
		return customers.length ? {...company, customers} : company;
	});
});

export const extractFilteredCompanies = createSelector(
	[
		extractCompaniesWithCustomers,
		getCompaniesSettings
	],
	(companies: ICompanyWithCustomer[], settings) => {
		const {filter, pageSize, offset} = settings;
		const {companiesIds, customersIds, createdAt, modifiedAt} = filter;
		const filterByCompanyIds = (company: ICompanyWithCustomer, companyIds?: string[]) =>
			(companyIds?.length ? companyIds.includes(company.id) : true);

		const filterByCustomerIds = (company: ICompanyWithCustomer, customerIds?: string[]) =>
			(customerIds?.length ? company.customers?.some(({id}) => customerIds.includes(id)) : true);

		const filterByCreatedAt = (company: ICompanyWithCustomer, from?: Date, to?: Date) =>
			(from && to && company.createdAt ? checkDateInRange(company.createdAt, from, to) : true);

		const filterByModifiedAt = (company: ICompanyWithCustomer, from?: Date, to?: Date) =>
			(from && to && company.modifiedAt ? checkDateInRange(company.modifiedAt, from, to) : true);

		const filteredArr = companies.filter(
			company =>
				filterByCompanyIds(company, companiesIds)
				&& filterByCustomerIds(company, customersIds)
				&& filterByCreatedAt(company, createdAt?.from, createdAt?.to)
				&& filterByModifiedAt(company, modifiedAt?.from, modifiedAt?.to)
		);
		const paginatedArr = filteredArr.filter((item, i) => handlePagination(i, offset, pageSize));
		return {
			data: paginatedArr,
			pageCount: Math.ceil(filteredArr.length / pageSize),
			currentPage: Math.floor(offset / pageSize),
			total: filteredArr.length
		};
	}
);

/**
 * Возвращает компании, которые могут использоваться при добавлении/редактировании объекта
 */
export const extractCompaniesForObject = createSelector(
	[extractCompaniesAsArray, extractUserRoles, getObjectId],
	(companies, userRoles, objectId) => {
		if (hasPermission(userRoles, objectId ? 'objectsEdit' : 'objectsAdd', UserRoleScopes.ALL)) {
			return companies;
		}
		const ids = getScopeIds(userRoles, UserRoleScopes.COMPANY, objectId ? 'objectsEdit' : 'objectsAdd');
		return companies.filter((company: ICompany) => ids.includes(company.id));
	}
);

/**
 * Возвращает компании, которые могут использоваться при добавлении/редактировании роли
 */
export const extractCompaniesForRole = createSelector(
	[extractCompaniesAsArray, extractUserRoles, getRoleId],
	(companies, userRoles, roleId) => {
		if (hasPermission(userRoles, roleId ? 'rolesEdit' : 'rolesAdd', UserRoleScopes.ALL)) {
			return companies;
		}
		const ids = getScopeIds(userRoles, UserRoleScopes.COMPANY, roleId ? 'rolesEdit' : 'rolesAdd');
		return companies.filter((company: ICompany) => ids.includes(company.id));
	}
);

/**
 * Возвращает компании-подрядчики в виде массива для конкретной компании
 */
export const extractSubCompaniesAsArray = createSelector(
	[getAllIds, getById, extractCompany],
	(allIds, byId, company) => {
		const contractorsIds = company?.contractors?.map(item => item.subCompanyId);
		const contractors: ICompany[] = [];
		if (contractorsIds) {
			contractorsIds.forEach(id => {
				if (allIds.includes(id) && byId[id]) {
					contractors.push(byId[id]);
				}
			});
		}
		return contractors;
	}
);

/**
 * Возвращает все компании-подрядчики в виде массива
 */
export const extractAllSubCompaniesAsArray = createSelector([getAllIds, getById], (allIds, byId) => {
	const contractorsIds: string[] = [];
	allIds.forEach(id => {
		const company = byId[id];
		if (company && company.contractors) {
			company.contractors.forEach(c => {
				if (!c.deleted) {
					contractorsIds.push(c.subCompanyId);
				}
			});
		}
	});
	const contractorsCompanies = allIds.filter(id => contractorsIds.includes(id)).map(item => byId[item]);
	return contractorsCompanies;
});

/**
 * Возвращает компании-подрядчики в виде массива по массиву их id
 */
export const extractSubCompaniesAsArrayByIds = createSelector(
	[getAllIds, getById, getCompanyIds],
	(allIds, byId, ids) => {
		const contractors: ICompany[] = [];

		ids.forEach(id => {
			if (allIds.includes(id) && byId[id]) {
				contractors.push(byId[id]);
			}
		});
		return contractors;
	}
);
