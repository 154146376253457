import {IUserRole, UserRoleScopes} from '@tehzor/tools/interfaces/IUser';
import {hasPermission} from '@tehzor/tools/utils/findPermission';
import IRole from '@src/interfaces/IRole';

interface IUserRolesPermissions {
	canDelete?: boolean;
}

/**
 * Возвращает полномочия для текущего пользователя
 *
 * @param {IUserRole[]} userRoles роли текущего пользователя
 * @param {IRole|undefined} role роль
 */
export const useUserRolesPermissions = (userRoles: IUserRole[], role?: IRole): IUserRolesPermissions => {
	if (!userRoles || !role) {
		return {};
	}

	return {
		canDelete:
			hasPermission(userRoles, 'rolesDelete', UserRoleScopes.ALL)
			|| (role?.company && hasPermission(userRoles, 'rolesDelete', UserRoleScopes.COMPANY, role.company.id))
	};
};