import {wsConnector} from '../wsConnector';

export interface ICheckUserContentResponse {
	checks?: number;
	checkStories?: number;
	problems?: number;
	problemStories?: number;
	inspections?: number;
	inspectionStories?: number;
	warrantyClaims?: number;
	warrantyClaimStories?: number;
	categories?: number;
	categoryStories?: number;
	plans?: number;
	// planStories?: number;
}

/**
 * Проверяет наличие созданного контента пользователем
 *
 * @param userId id пользователя
 */
export const makeCheckUserContentRequest = (userId: string) =>
	wsConnector.sendAuthorizedRequest<ICheckUserContentResponse>('checkUserContent', {userId});
