import React from 'react';
import {IState} from '@src/store/modules';
import {connect} from 'react-redux';
import {getNotificationTypesAsArray} from '@src/selectors/notifications/types';
import INotificationType from '@tehzor/tools/interfaces/notifications/INotificationType';
import columns from './columns';
import {Table} from '@tehzor/ui-components';
import {RowInfo} from 'react-table-6';
import INotificationChannel from '@tehzor/tools/interfaces/INotificationChannel';
import INotificationInterval from '@tehzor/tools/interfaces/INotificationInterval';
import INotificationPeriod from '@tehzor/tools/interfaces/INotificationPeriod';
import {getNotificationChannelsAsArray} from '@src/selectors/notifications/channels';
import Settings from './Settings';
import {getNotificationIntervalsAsArray} from '@src/selectors/notifications/intervals';
import {getNotificationPeriodsAsArray} from '@src/selectors/notifications/periods';

const ChannelsCtx = React.createContext<INotificationChannel[]>([]);

const IntervalsCtx = React.createContext<INotificationInterval[]>([]);

const PeriodsCtx = React.createContext<INotificationPeriod[]>([]);

interface ISubsTableProps {
	types: INotificationType[];
	channels: INotificationChannel[];
	intervals: INotificationInterval[];
	periods: INotificationPeriod[];
}

const getTbodyProps = () => ({className: 'role-page__ptable-tbody'});

const getTrProps = (state: any, row?: RowInfo) => ({row});

const TheadComponent = () => null;

const SubsTable = ({types, channels, intervals, periods}: ISubsTableProps) => (
	<>
		<div className="field__wide-label-wrap">
			<div className="field__label">Подписки на уведомления по умолчанию:</div>
		</div>

		<ChannelsCtx.Provider value={channels}>
			<IntervalsCtx.Provider value={intervals}>
				<PeriodsCtx.Provider value={periods}>
					<Table
						data={types}
						columns={columns}
						sortable={false}
						resizable={false}
						showPagination={false}
						pageSize={types.length}
						getTbodyProps={getTbodyProps}
						getTrProps={getTrProps}
						TheadComponent={TheadComponent}
						SubComponent={Settings}
					/>
				</PeriodsCtx.Provider>
			</IntervalsCtx.Provider>
		</ChannelsCtx.Provider>
	</>
);

const mapStateToProps = (state: IState) =>
	({
		types: getNotificationTypesAsArray(state),
		channels: getNotificationChannelsAsArray(state),
		intervals: getNotificationIntervalsAsArray(state),
		periods: getNotificationPeriodsAsArray(state)
	} as ISubsTableProps);

export {ChannelsCtx, IntervalsCtx, PeriodsCtx};
export default connect(mapStateToProps)(SubsTable);
