import {Button, Plate} from '@tehzor/ui-components';
import React, {useCallback} from 'react';
import './NotFoundPage.less';
import {useChangePath} from '@src/core/hooks/useChangePath';

/**
 * Страница для обработки ошибочных URL адресов
 */
export const NotFoundPage = () => {
	const {pushPath} = useChangePath();
	const handleHomeClick = useCallback(() => pushPath('/'), []);

	return (
		<div className="page-cont">
			<Plate>
				<div className="not-found"/>
				<h1 className="not-found-h1">Страница не найдена</h1>
				<div className="not-found-text">
					Страница, которую вы запрашиваете, не существует. Возможно она устарела, была удалена, или был
					введен наверный адрес в адресной строке
				</div>
				<div className="not-found-btn">
					<Button
						className="not-found-btn-content"
						label="На главную страницу"
						type="accent-blue"
						onClick={handleHomeClick}
					/>
				</div>
			</Plate>
		</div>
	);
};
