import {
	IEditableEntityAction,
	IEditableEntityState,
	isEntityEdited
} from '@tehzor/tools/core/states/editableEntityState';
import {IProblemTemplatesSet} from '@tehzor/tools/interfaces/problemTemplatesSets/IProblemTemplatesSet';
import {isEqual} from 'lodash';
import {ISavingProblemTemplatesSet} from '@tehzor/tools/interfaces/problemTemplatesSets/ISavingProblemTemplatesSet';
import {ISavingProblemTemplate} from '@tehzor/tools/interfaces/problemTemplatesSets/ISavingProblemTemplate';

export type IEditableProblemTemplatesSetState = IEditableEntityState<ISavingProblemTemplatesSet>;

export type IEditableProblemTemplatesSetAction = IEditableEntityAction<
	IEditableProblemTemplatesSetState,
	IProblemTemplatesSet
>;

const makeEmptyState = (): IEditableProblemTemplatesSetState => ({
	name: '',
	companies: [],
	data: [],
	shared: false,
	errors: {
		name: false,
		companies: false,
		data: false,
		shared: false
	}
});

export const makeEmptyTemplate = (): ISavingProblemTemplate => ({
	description: '',
	categoryId: undefined,
	reason: undefined,
	fixDate: undefined,
	stageId: undefined,
	critical: undefined,
	prescription: undefined,
	moderated: true
});

export const init = (problemTemplatesSet?: IProblemTemplatesSet): IEditableProblemTemplatesSetState => {
	const empty = makeEmptyState();
	return problemTemplatesSet
		? {
				name: problemTemplatesSet.name,
				companies: problemTemplatesSet.companies,
				data: problemTemplatesSet.data,
				shared: problemTemplatesSet.shared,
				errors: empty.errors
		  }
		: empty;
};

const isPropEdited = (
	prop: keyof ISavingProblemTemplatesSet,
	state: IEditableProblemTemplatesSetState,
	original?: IProblemTemplatesSet
) => {
	const currentVal = state[prop];

	if (!original) {
		return !!currentVal;
	}

	let result = true;
	const originalVal = original[prop];

	if (Array.isArray(currentVal)) {
		result
			= result
			&& (currentVal.length !== (originalVal as []).length
				|| JSON.stringify(currentVal) !== JSON.stringify(originalVal));
	} else if (typeof currentVal === 'object') {
		result = result && !isEqual(currentVal, originalVal);
	} else {
		result = result && currentVal != originalVal;
	}

	return result;
};

/**
 * Возвращает значение, показывающее были ли отредактированы поля нарушения
 *
 * @param state состояние
 * @param original изначальные данные
 */
export const isEdited = (state: IEditableProblemTemplatesSetState, original?: IProblemTemplatesSet): boolean =>
	isEntityEdited<ISavingProblemTemplatesSet, IProblemTemplatesSet>(
		state,
		original,
		isPropEdited.bind(null, 'name'),
		isPropEdited.bind(null, 'companies'),
		isPropEdited.bind(null, 'data'),
		isPropEdited.bind(null, 'shared')
	);

/**
 * Функции проверки полей на ошибки
 */
export const errorsFns = {
	name: (state: IEditableProblemTemplatesSetState) => !state.name,
	companies: (state: IEditableProblemTemplatesSetState) => !state.companies?.length,
	data: (state: IEditableProblemTemplatesSetState) => !state.data?.length,
	shared: (state: IEditableProblemTemplatesSetState) => !state.shared
};

/**
 * Конвертирует данные в формат, пригодный для отправки на сервер
 *
 * @param state состояние
 * @param original изначальные данные
 * @param onlyEdited возвращать только изменённые поля
 */
export const convertToSave = (
	state: IEditableProblemTemplatesSetState,
	original?: IProblemTemplatesSet,
	onlyEdited?: boolean
): ISavingProblemTemplatesSet => {
	if (!onlyEdited) {
		return {
			name: state.name,
			companies: state.companies,
			data: state.data,
			shared: state.shared
		};
	}

	const problemTemplatesSet: ISavingProblemTemplatesSet = {};

	if (isPropEdited('name', state, original)) {
		problemTemplatesSet.name = state.name;
	}

	if (isPropEdited('companies', state, original)) {
		problemTemplatesSet.companies = state.companies;
	}

	if (isPropEdited('data', state, original)) {
		problemTemplatesSet.data = state.data?.map(({fixDate, ...template}) => ({
			...template,
			fixDate: fixDate && parseInt(fixDate.toString())
		}));
	}

	if (isPropEdited('shared', state, original)) {
		problemTemplatesSet.shared = state.shared;
	}

	return problemTemplatesSet;
};
