import * as types from '@src/constants/notifications/channels';
// @ts-ignore
import {CALL_BACKEND_API} from '../../middlewares/backendMiddleware';
// @ts-ignore
import SocketConnector from '../../api/SocketConnector';
import {IState} from '@src/store/modules';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import INotificationChannel from '@tehzor/tools/interfaces/INotificationChannel';
import IError from '@tehzor/tools/interfaces/IError';

const socketConnector = SocketConnector.instance;

/**
 * Получает список каналов уведомлений
 *
 * @returns {Function}
 */
export const getChannels = () => (dispatch: any, getState: () => IState) => {
		const channels = getState().notifications.channels;
		if (channels.loaded) {
			return Promise.resolve(channels);
		}
		return dispatch({
			[CALL_BACKEND_API]: {
				actions: [getChannelsRequest, getChannelsSuccess, getChannelsFailure],
				promise: socketConnector.getNotificationChannels
			}
		});
	};

const getChannelsRequest = () => ({
		type: types.GET_NOTIFICATION_CHANNELS_REQUEST
	});

const getChannelsSuccess = (response: INormalizedData<INotificationChannel>) => ({
		type: types.GET_NOTIFICATION_CHANNELS_SUCCESS,
		payload: {...response}
	});

const getChannelsFailure = (error: IError) => ({
		type: types.GET_NOTIFICATION_CHANNELS_FAILURE,
		payload: {...error}
	});
