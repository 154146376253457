import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast, addSuccessToast} from '@src/utils/toasts';
import {AppThunkAction} from '@src/store/interfaces/thunk';
import {createApiAction} from '@src/store/middlewares/api';
import {IEditTemplatesSetResponse, makeEditTemplatesSetRequest} from '@src/api/backend/problemTemplatesSet';
import {ISavingProblemTemplatesSet} from '@tehzor/tools/interfaces/problemTemplatesSets/ISavingProblemTemplatesSet';

export type IEditTemplatesSetPayload = IEditTemplatesSetResponse;

const request = (data: ISavingProblemTemplatesSet) => ({
	type: types.EDIT_REQUEST,
	payload: data
});

const success = (response: IEditTemplatesSetResponse) => {
	addSuccessToast('Успех', 'Набор шаблонов был успешно изменен');
	return {
		type: types.EDIT_SUCCESS,
		payload: response
	};
};

const failure = (error: IError) => {
	if (error.statusCode !== 401) {
		addErrorToast('Ошибка', 'при изменении набора шаблонов нарушений');
	}

	return {
		type: types.EDIT_FAILURE,
		payload: error
	};
};

/**
 * Изменяет набор шаблонов нарушений
 */
export const editTemplatesSet
	= (id: string, data: ISavingProblemTemplatesSet): AppThunkAction<Promise<IEditTemplatesSetResponse>> =>
	dispatch =>
		dispatch(
			createApiAction<IEditTemplatesSetResponse>(
				() => request(data),
				success,
				failure,
				() => makeEditTemplatesSetRequest(id, data)
			)
		);
