import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import {ITreeDataItem} from '@tehzor/ui-components/src/components/Tree';

export const makeObjectsTreeData = (
	objects: IObject[]
): ITreeDataItem[] => objects.map(object => ({
	id: object.id,
	content: object.name,
	parentId: object.parentId,
	onlySemiCheckable: !object.parentId
}));