import {combineReducers} from 'redux';
import * as types from '../../constants/uiConstants';
import createReducer from '@tehzor/tools/utils/createReducer';

const isBusy = createReducer(false, {
	[types.TOGGLE_ROLES_BUSY]: (state, payload) => (
		payload !== undefined ? payload : !state
	)
});

export default combineReducers({
	isBusy
});
