// import React, {useCallback} from 'react';
import './AppBottomMenu.less';
// import {MobileBottomMenu, MobileBottomMenuItem, NotificationsIcon} from '@tehzor/ui-components';
// import useAppSelector from '@src/core/hooks/useAppSelector';
// import {toggleNotificationsVisible} from '@src/actions/settings/notifications';
// import useAppDispatch from '@src/core/hooks/useAppDispatch';
// import {useNavPermissions} from '@src/core/hooks/useNavPermissions';
// import {usePageKey} from './utils/usePageKey';
// import {replacePath} from '@src/core/history';

const AppBottomMenu = () => null;
// {
// const user = useAppSelector(s => s.auth.profile);
// const notificationsCount = useAppSelector(s => s.notifications.messages.total);
// const dispatch = useAppDispatch();
// const permissions = useNavPermissions();
// const pageKey = usePageKey();
//
// const navigate = useCallback((path?: string) => {
// 	if (path) {
// 		replacePath(`/${path}`);
// 	}
// }, []);
//
// const toggleNotifications = useCallback(() => {
// 	dispatch(toggleNotificationsVisible());
// }, []);

// return null;
// return pageKey ? (
// 	<MobileBottomMenu className="app-bottom-menu">
// 		<MobileBottomMenuItem
// 			icon={<i className="tz-object-28"/>}
// 			label="Объекты"
// 			active={pageKey === 'objects'}
// 			data="objects"
// 			onClick={navigate}
// 		/>
// 		{permissions.problemsView && (
// 			<MobileBottomMenuItem
// 				icon={<i className="tz-problem"/>}
// 				label="Нарушения"
// 				active={pageKey === 'problems'}
// 				data="problems"
// 				onClick={navigate}
// 			/>
// 		)}
// 		{permissions.inspectionsView && (
// 			<MobileBottomMenuItem
// 				icon={<i className="tz-inspection"/>}
// 				label="Осмотры"
// 				active={pageKey === 'inspections'}
// 				data="inspections"
// 				onClick={navigate}
// 			/>
// 		)}
// 		{user.activated && (
// 			<MobileBottomMenuItem
// 				icon={<NotificationsIcon count={notificationsCount}/>}
// 				label="События"
// 				onClick={toggleNotifications}
// 			/>
// 		)}
// 	</MobileBottomMenu>
// ) : null;
// };

export default AppBottomMenu;
