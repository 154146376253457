import {ILogRecord} from '@tehzor/tools/interfaces/ILogRecord';
import ILogRecordType from '@tehzor/tools/interfaces/ILogRecordType';
import IUser from '@tehzor/tools/interfaces/IUser';

export interface IPreparedLogRecord {
	id: string;
	type: string;
	typeId: string;
	user: string;
	date: string;
	fields: ILogRecord['fields'];
	error: ILogRecord['error'];
}

export const convertRecords = (
	records: ILogRecord[],
	typesMap: Record<string, ILogRecordType>,
	usersMap: Record<string, IUser>
): IPreparedLogRecord[] =>
	records.map(({id, type, userId, date, fields, error}: ILogRecord) => {
		const typeName = typesMap[type]?.name ?? type;
		const userDisplayName = userId ? usersMap[userId]?.displayName : '';

		return {
			id,
			type: typeName,
			typeId: type,
			user: userDisplayName,
			date,
			fields,
			error
		};
	});
