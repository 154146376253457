import React from 'react';
import {IconMenu, MenuItem} from '@tehzor/ui-components';

interface IPageHeaderMenuProps {
	onDeleteClick?(): void;
	onRestoreClick?(): void;
}

const PageHeaderMenu = ({onDeleteClick, onRestoreClick}: IPageHeaderMenuProps) => {
	const menuActions = [];
	if (onDeleteClick) {
		menuActions.push(
			<MenuItem
				key="delete"
				icon={<i className="tz-delete"/>}
				onClick={onDeleteClick}
			>
				Удалить
			</MenuItem>
		);
	}
	if (onRestoreClick) {
		menuActions.push(
			<MenuItem
				key="restore"
				// icon={<i className="tz-delete"/>}
				onClick={onRestoreClick}
			>
				Восстановить
			</MenuItem>
		);
	}
	if (menuActions.length) {
		return <IconMenu>{menuActions}</IconMenu>;
	}
	return null;
};

export default PageHeaderMenu;
