import {AnyAction} from 'redux';
import {IUserRole, IUserWithSubscription} from '@tehzor/tools/interfaces/IUser';
import ISavingUser from '@tehzor/tools/interfaces/users/ISavingUser';

export interface IEditableUser {
	password: string;
	email: string;
	phone: string;
	firstName: string;
	middleName: string;
	lastName: string;
	fullName: string;
	displayName: string;
	position: string;
	roles: {
		[scopeId: string]: {
			scope: IUserRole['scope'];
			roleId: string;
		};
	};
	color: string;
	activated: boolean;
	rolesEdited: boolean;
	edited: boolean;
	subscribed?: boolean;
}

/**
 * Конвертирует пользовательские роли в формат, пригодный для редактирования
 *
 * @param roles пользовательские роли
 */
const convertRolesToEdit = (roles?: IUserRole[]) =>
	(roles
		? roles.reduce((acc, item) => {
				if (item.scope === 'all') {
					acc.all = {
						scope: item.scope,
						roleId: item.roleId
					};
					return acc;
				}
				if (item.scopeIds) {
					return item.scopeIds.reduce((a, id) => {
						// eslint-disable-next-line no-param-reassign
						a[id] = {
							scope: item.scope,
							roleId: item.roleId
						};
						return a;
					}, acc);
				}
				return acc;
		  }, {} as IEditableUser['roles'])
		: {});

/**
 * Конвертирует пользовательские роли в формат, пригодный для отправки на сервер
 *
 * @param userRoles пользовательские роли
 */
const convertRolesToSave = (userRoles: IEditableUser['roles']) => {
	const roles = [] as NonNullable<ISavingUser['roles']>;
	for (const scopeId in userRoles) {
		if (userRoles.hasOwnProperty(scopeId) && userRoles[scopeId]) {
			const item = userRoles[scopeId];
			const i = roles.findIndex(g => g.roleId === item.roleId && g.scope === item.scope);
			if (i === -1) {
				roles.push({
					scope: item.scope,
					scopeIds: item.scope !== 'all' ? [scopeId] : [],
					roleId: item.roleId
				});
			} else if (item.scope !== 'all') {
				roles[i].scopeIds.push(scopeId);
			}
		}
	}
	return roles;
};

export const init = (user?: IUserWithSubscription): IEditableUser => {
	if (!user) {
		return {
			password: '',
			email: '',
			phone: '',
			firstName: '',
			middleName: '',
			lastName: '',
			fullName: '',
			displayName: '',
			position: '',
			roles: {},
			color: '#FFFFFF',
			activated: true,
			edited: false,
			rolesEdited: false,
			subscribed: undefined
		};
	}
	return {
		password: '',
		email: user.email || '',
		phone: user.phone || '',
		firstName: user.firstName || '',
		middleName: user.middleName || '',
		lastName: user.lastName || '',
		fullName: user.fullName || '',
		displayName: user.displayName || '',
		position: user.position || '',
		roles: convertRolesToEdit(user.roles),
		color: user.color || '',
		activated: !!user.activated,
		edited: false,
		rolesEdited: false,
		subscribed: user.subscribed
	};
};

export const reducer = (state: IEditableUser, action: AnyAction): IEditableUser => {
	switch (action.type) {
		case 'update':
			return {
				...state,
				[action.field]: action.value,
				edited: true
			};
		case 'update-role':
			return {
				...state,
				roles: {
					...state.roles,
					[action.scopeId]: action.value
				},
				edited: true,
				rolesEdited: true
			};
		case 'reset':
			return init(action.user);
		default:
			return state;
	}
};

/**
 * Конвертирует данные в формат, пригодный для отправки на сервер
 *
 * @param {IEditableUser} edited сохраняемые данные
 * @param {IUser|undefined} original первоначальные данные
 * @param {string|undefined} userId id пользователя
 */
export const convertToSave = (
	edited: IEditableUser, original?: IUserWithSubscription, userId?: string
): ISavingUser => {
	if (!original || !userId) {
		return {
			password: edited.password,
			email: edited.email,
			phone: edited.phone,
			firstName: edited.firstName,
			middleName: edited.middleName,
			lastName: edited.lastName,
			fullName: edited.fullName,
			displayName: edited.displayName,
			position: edited.position || undefined,
			roles: convertRolesToSave(edited.roles),
			color: edited.color,
			activated: edited.activated,
			subscribed: edited.subscribed
		};
	}
	const user = {} as ISavingUser;
	if (edited.password) {
		user.password = edited.password;
	}
	if (edited.email !== original.email) {
		user.email = edited.email;
	}
	if (edited.phone !== original.phone) {
		user.phone = edited.phone;
	}
	if (edited.firstName !== original.firstName) {
		user.firstName = edited.firstName;
	}
	if (edited.middleName !== original.middleName) {
		user.middleName = edited.middleName;
	}
	if (edited.lastName !== original.lastName) {
		user.lastName = edited.lastName;
	}
	if (edited.fullName !== original.fullName) {
		user.fullName = edited.fullName;
	}
	if (edited.displayName !== original.displayName) {
		user.displayName = edited.displayName;
	}
	if (edited.position !== original.position) {
		user.position = edited.position;
	}
	if (edited.color !== original.color) {
		user.color = edited.color;
	}
	if (edited.activated !== original.activated) {
		user.activated = edited.activated;
	}
	if (edited.rolesEdited) {
		user.roles = convertRolesToSave(edited.roles);
	}
	if (edited.subscribed !== original.subscribed) {
		user.subscribed = edited.subscribed;
	}
	return user;
};
