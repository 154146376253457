import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useCallback} from 'react';
import {rolesSettingsActions} from '@src/store/modules/settings/pages/roles/rolesSettingsSlice';
import {getRolesFilter} from '@src/selectors/entities/roles';

export const useRolesFilters = () => {
	const dispatch = useAppDispatch();
	const filterState = useAppSelector(getRolesFilter);
	const {setFilter} = rolesSettingsActions;

	const onCompaniesChange = useCallback((value: string[]) => {
		dispatch(setFilter({companiesIds: value}));
	}, [dispatch, setFilter]);

	return {
		filterState,
		onCompaniesChange
	};
};