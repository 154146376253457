import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import {IState} from '@src/store/modules';
import checkExpiration from '@tehzor/tools/utils/checkExpiration';
import {ApiAction, createApiAction} from '@src/store/middlewares/api';
import {AppThunkAction} from '@src/store/interfaces/thunk';
import preventExtraLoading from '@tehzor/tools/core/preventExtraLoading';
import {IGetCompaniesResponse, requestCompanies} from '@src/api/backend/companies';

export type IGetCompaniesPayload = IGetCompaniesResponse;

const request = () => ({type: types.GET_REQUEST});

const success = (response: IGetCompaniesResponse) => ({
	type: types.GET_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	if (error.statusCode !== 401) {
		addErrorToast('Ошибка', 'при загрузке списка компаний');
	}
	return {
		type: types.GET_FAILURE,
		payload: {...error}
	};
};

/**
 * Получает список компаний
 */
export const getCompanies = () =>
	preventExtraLoading(
		'companies',
		checkExpiration<IState, IGetCompaniesResponse, ApiAction>(
			s => s.entities.companies,
			createApiAction<IGetCompaniesResponse>(request, success, failure, requestCompanies)
		)
	) as AppThunkAction<Promise<IGetCompaniesResponse>>;
