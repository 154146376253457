import {useUpdateEffect} from 'react-use';
import React, {memo, useMemo, useState} from 'react';
import {flatFilter} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch/helpers/flatFilter';
import {Select2, SelectOption, SelectPopup, SelectSearch, TextFieldWithForwardedRef} from '@tehzor/ui-components';

interface ISingleSelectProps<D extends { name: string, id: string }> {
	className?: string;
	popupClassName?: string;
	style?: React.CSSProperties;
	noHeader?: boolean;
	options?: D[];
	optionId?: string;
	onChange?: (value: string) => void;
	cleanable?: boolean;
	error?: string;
	disabled?: boolean;
}

const simpleArrowIcon = <i className="tz-simple-arrow-20"/>;
export const SingleSelect = memo(
	<D extends { name: string, id: string }>(props: ISingleSelectProps<D>) => {
		const {
			options = [],
			optionId,
			onChange,
			disabled,
			cleanable,
			noHeader,
			error,
			style,
			className,
			popupClassName
		} = props;
		const [search, setSearch] = useState('');
		const [selectedValue, setSelectedValue] = useState(optionId);

		useUpdateEffect(() => {
			setSelectedValue(optionId);
		}, [optionId]);

		const handleApply = () => {
			if (selectedValue) {
				onChange?.(selectedValue);
				setSearch('');
			}
		};

		const handleClear = () => {
			setSelectedValue('');
			onChange?.('');
			setSearch('');
		};

		const handleCancel = () => {
			setSearch('');
			setSelectedValue(optionId);
		};

		const filteredData = useMemo(() => flatFilter(options, 'name', search), [options, search]);
		const optionName = optionId && options?.find(c => c.id === optionId)?.name;

		return (
			<div
				className={className}
				style={style}
			>
				<SelectPopup
					noHeader={noHeader}
					footer
					onCancel={handleCancel}
					onApply={handleApply}
					trigger={(
						<TextFieldWithForwardedRef
							elementType="div"
							value={optionName}
							icon={simpleArrowIcon}
							disabled={disabled}
							cleanable={cleanable && Boolean(optionId) && !disabled}
							onClearClick={handleClear}
							error={error}
						/>
					)}
					search={(
						<SelectSearch
							value={search}
							onChange={setSearch}
							type="popup"
						/>
					)}
				>
					<Select2
						className={popupClassName}
						onChange={setSelectedValue}
						value={selectedValue}
					>
						{filteredData?.map(option => (
							<SelectOption
								content={option.name}
								inputType="radio"
								itemKey={option.id}
								key={option.id}
							/>
						))}
					</Select2>
				</SelectPopup>
			</div>
		);
	}
);
