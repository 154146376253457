/* eslint-disable object-shorthand */

// С помощью DefinePlugin заносятся данные переменные, нельзя использовать сокращенное присваивание в объекте
declare const appUrl: string | undefined;
declare const appName: string | undefined;
declare const appVersion: string | undefined;
declare const apiUrl: string | undefined;
declare const newApiUrl: string | undefined;
declare const newWsApiUrl: string | undefined;

export const config = {
	appId: 'web_admin',
	appSecret: 'a2924c8021d37bb1289452499b804e77',
	appUrl: appUrl,
	appName: appName,
	appVersion: appVersion,
	apiUrl: apiUrl,
	newApiUrl: newApiUrl,
	newWsApiUrl: newWsApiUrl,
	entitiesCacheTime: 300,
	regStandardExternalLink: 'https://www.faufcc.ru/technical-regulation-in-constuction/formulary-list/?s='
};

export default config;
