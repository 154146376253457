import {wsConnector} from '../wsConnector';
import IUser from '@tehzor/tools/interfaces/IUser';

export type IDeleteUserResponse = IUser | {success: true};

/**
 * Удаляет пользователя
 *
 * @param userId id пользователя
 */
export const makeUserDeleteRequest = (userId: string) =>
	wsConnector.sendAuthorizedRequest<IDeleteUserResponse>('deleteUser', {userId});
