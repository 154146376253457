import {createReducer} from '@reduxjs/toolkit';
import config from '@src/core/config';
import {ILoadableEntitiesState} from '@tehzor/tools/core/storeHelpers/reducers';
import {IListDevice} from '@tehzor/tools/interfaces/IDevice';
import {CLEAR_STORE} from '../../auth/constants';
import {IDeleteDevicePayload} from './actions/delete';
import {IGetDevicesPayload} from './actions/get';
import * as types from './constants';

const {entitiesCacheTime} = config;

export type IDevicesUserState = ILoadableEntitiesState<IListDevice>;

export type IDevicesState = Record<string, IDevicesUserState>;

export const getInitialStateForUser = (): IDevicesUserState => ({
	byId: {},
	allIds: [],
	loadedAt: undefined,
	loaded: false
});

export default createReducer<IDevicesState>({}, {
	[types.GET_REQUEST]: (state, {payload}: {payload: {userId: string}}) => {
		if (!state.hasOwnProperty(payload.userId)) {
			state[payload.userId] = getInitialStateForUser();
		}
	},
	[types.GET_SUCCESS]: (state, {payload}: {payload: IGetDevicesPayload}) => {
		state[payload.userId] = {
			allIds: payload.allIds,
			byId: payload.byId,
			loaded: true,
			loadedAt: Date.now() + entitiesCacheTime * 1000
		};
	},
	[types.DELETE_SUCCESS]: (state, {payload}: {payload: IDeleteDevicePayload}) => {
		if (state[payload.userId]) {
			const userState = state[payload.userId];
			userState.allIds = userState.allIds.filter(id => id !== payload.id);
			delete userState.byId[payload.id];
			userState.loaded = false;
			userState.loadedAt = undefined;
		}
	},
	[CLEAR_STORE]: () => ({})
});