import React, {Dispatch, useCallback, useEffect, useReducer} from 'react';
import {ActionButtons, Button, LoadingPanel, TabContent, TabLink, Tabs} from '@tehzor/ui-components';
import UserPageHeader from './components/UserPageHeader';
import {AnyAction} from 'redux';
import {extractUser} from '@src/selectors/entities/users';
import {convertToSave, IEditableUser, init, reducer} from './utils/editableUserState';
import useAsync from 'react-use/lib/useAsync';
import './UserPage.less';
import RolesTable from './components/roles/RolesTable';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {getObjects} from '@src/store/modules/entities/objects/actions';
import {getCompanies} from '@src/store/modules/entities/companies/actions';
import {addUser, editUser} from '@src/store/modules/entities/user/actions';
import ContentCounts from './components/ContentCounts';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import Fields from './components/fields/Fields';
import {getProfile} from '@src/store/modules/auth/profile/actions';
import DevicesHistory from './components/DevicesHistory/DevicesHistory';
import {getDevices} from '@src/store/modules/entities/devices/actions/get';
import {getOneUser} from '@src/store/modules/entities/users/actions/getOne';
import {useParams} from 'react-router-dom';
import {getRoles} from '@src/store/modules/entities/roles/actions/get';
import {useChangePath} from "@src/core/hooks/useChangePath";

export const UserDispatchCtx = React.createContext<Dispatch<AnyAction>>(() => ({}));

export const UserRolesCtx = React.createContext<IEditableUser['roles']>({});

const UserPage = () => {
	// Если Использовать useStrictParams попадаем в ошибку :с
	const {userId} = useParams<{userId?: string}>();
	const {pushPath} = useChangePath();
	const user = useAppSelector(s => extractUser(s, userId));
	const currentUserId = useAppSelector(s => s.auth.profile.id);

	const [editableUser, stateDispatch] = useReducer(reducer, user, init);
	const dispatch = useAppDispatch();

	useAsync(async () => {
		if (userId) {
			await dispatch(getOneUser(userId));
			await dispatch(getDevices(userId));
		}
		await dispatch(getRoles());
		await dispatch(getCompanies());
		await dispatch(getObjects());
	}, []);

	useEffect(() => {
		stateDispatch({type: 'reset', user});
	}, [user]);

	const [, save] = useAsyncFn(async () => {
		if (userId) {
			await dispatch(editUser(userId, convertToSave(editableUser, user, userId)));
			if (userId === currentUserId) {
				await dispatch(getProfile());
			}
		} else {
			const savedUser = await dispatch(addUser(convertToSave(editableUser, user)));
			pushPath(`/users/${savedUser.id}`);
		}
	}, [userId, editableUser]);

	const cancel = useCallback(() => {
		if (userId) {
			stateDispatch({type: 'reset', user});
		} else {
			pushPath('/users');
		}
	}, [user]);

	const tabLinks = [<TabLink label="Основная информация"/>, <TabLink label="Привязка ролей"/>];
	if (userId) {
		tabLinks.push(<TabLink label="Наличие контента"/>, <TabLink label="История сессий"/>);
	}

	return (
		<LoadingPanel className="loading-panel_main">
			<div className="page-cont user-page">
				<UserPageHeader
					userId={userId}
					user={user}
				/>

				<UserDispatchCtx.Provider value={stateDispatch}>
					<div className="panel user-page__panel">
						<Tabs
							className={{links: 'role-page__tabs-links'}}
							links={tabLinks}
						>
							<TabContent>
								<Fields
									user={user}
									editableUser={editableUser}
								/>
							</TabContent>

							<TabContent>
								<UserRolesCtx.Provider value={editableUser.roles}>
									<RolesTable user={user}/>
								</UserRolesCtx.Provider>
							</TabContent>

							{userId && (
								<TabContent>
									<ContentCounts userId={userId}/>
								</TabContent>
							)}

							{userId && (
								<TabContent>
									<DevicesHistory userId={userId}/>
								</TabContent>
							)}
						</Tabs>

						{(!userId || editableUser.edited) && (
							<ActionButtons className="user-page__buttons">
								<Button
									type="accent-blue"
									label="Сохранить"
									onClick={save}
								/>
								<Button
									type="cancel"
									label="Отменить"
									onClick={cancel}
								/>
							</ActionButtons>
						)}
					</div>
				</UserDispatchCtx.Provider>
			</div>
		</LoadingPanel>
	);
};

export default UserPage;
