import React from 'react';
import './EditableFieldHeader.less';
import classNames from 'classnames';

interface IEditableFieldHeaderProps {
	className?: string;
	style?: React.CSSProperties;
	children?: React.ReactNode;
	title: React.ReactNode;
}

const EditableFieldHeader = ({className, style, children, title}: IEditableFieldHeaderProps) => (
	<div
		className={classNames('editable-field-header', className)}
		style={style}
	>
		<div className="editable-field-header__title">{title}</div>
		{children}
	</div>
);

export default EditableFieldHeader;
