import IError from '@tehzor/tools/interfaces/IError';

export const getErrorMessage = (error: IError): string => {
	switch (error.error) {
		case 'InvalidCredentials':
			return '';
		case 'AccessDenied':
			return 'доступ запрещен';
		case 'RoleIsUsed':
			return 'есть пользователи, привязанные к этой роли';
		default:
			return 'при удалении роли';
	}
};