/* eslint-disable no-param-reassign */
import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';
import IObjectStage from '@tehzor/tools/interfaces/objects/IObjectStage';
import {
	getLoadedEntitiesInitialState,
	getLoadedEntitiesSuccess,
	ILoadedEntityState
} from '@tehzor/tools/utils/reducersHandlers';

export type IObjectStagesState = ILoadedEntityState<IObjectStage>;

export default createReducer<IObjectStagesState>(getLoadedEntitiesInitialState<IObjectStage>(), {
	[types.GET_REQUEST]: getLoadedEntitiesInitialState,
	[types.GET_SUCCESS]: getLoadedEntitiesSuccess<IObjectStage>(),
	[CLEAR_STORE]: getLoadedEntitiesInitialState
});
