import React, {HTMLAttributes, useMemo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractFilteredObjects, getObjectsPageOffset, getObjectsPageSize} from '@src/selectors/entities/objects';
import {SimpleTable} from '@src/components/SimpleTable';
import {buildObjectsColumns} from './buildObjectsColumns';
import {Row} from '@tanstack/react-table';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {objectsSettingsActions} from '@src/store/modules/settings/pages/objects/objectsSettingsSlice';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {defaultPageSizes} from '@src/constants/defaultPageSizes';

export const ObjectsTable = () => {
	const dispatch = useAppDispatch();
	const {pushPath} = useChangePath();
	const offset = useAppSelector(getObjectsPageOffset);
	const pageSize = useAppSelector(getObjectsPageSize);
	const {setOffset, setPageSize} = objectsSettingsActions;
	const {data, currentPage, pageCount} = useAppSelector(extractFilteredObjects);
	const columns = useMemo(() => buildObjectsColumns, []);

	const handlePageChange = (page: number) => {
		const newOffset = page * pageSize;
		if (offset !== newOffset) {
			dispatch(setOffset(page * pageSize));
		}
	};

	const handlePageSizeChange = (value: number) => {
		const newOffset = Math.floor(offset / value);
		dispatch(setPageSize(value));
		dispatch(setOffset(newOffset));
	};

	const goToObject = (objId: string) => {
		pushPath(`/objects/${objId}`);
	};

	const getObjectsTableRowProps = (row: Row<IObject>): HTMLAttributes<HTMLTableRowElement> => ({
		onClick: () => goToObject(row.original.id)
	});

	return (
		<SimpleTable
			data={data}
			columns={columns}
			pageCount={pageCount}
			currentPage={currentPage}
			onPageChange={handlePageChange}
			onPageSizeChange={handlePageSizeChange}
			pageSizeOptions={defaultPageSizes}
			pageSize={pageSize}
			getTableRowProps={getObjectsTableRowProps}
		/>
);
};
