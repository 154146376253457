import {createReducer} from '@reduxjs/toolkit';
import * as types from '../constants';

export const isAuthenticated = createReducer<boolean>(false, {
	[types.LOGIN_REQUEST]: () => false,
	[types.LOGIN_SUCCESS]: () => true,
	[types.LOGIN_FAILURE]: () => false,
	[types.UPDATE_TOKENS_FAILURE]: () => false,
	[types.LOGOUT]: () => false
});
