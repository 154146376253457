import {ColumnDef} from '@tanstack/react-table';
import IRegulatoryStandard from '@tehzor/tools/interfaces/IRegulatoryStandard';
import {LinkCell} from '../LinkCell/LinkCell';

export const buildRolesColumns: Array<ColumnDef<IRegulatoryStandard>> = [
	{
		accessorKey: 'number',
		header: 'Номер'
	},
	{
		accessorKey: 'name',
		header: 'Название',
		size: 600
	},
	{
		id: 'link',
		header: 'Ссылка',
		cell: LinkCell
	},
	{
		accessorKey: 'endDate',
		header: 'Архивный',
		accessorFn: originalRow => (originalRow.endDate ? 'Да' : 'Нет')
	}
];
