import {combineReducers} from 'redux';
import * as types from '../../constants/uiConstants';
import createReducer from '@tehzor/tools/utils/createReducer';

const isBusy = createReducer(false, {
	[types.TOGGLE_USERS_BUSY]: (state, payload) => (
		payload !== undefined ? payload : !state
	)
});

const editMode = createReducer(false, {
	[types.TOGGLE_USERS_EDIT_MODE]: (state, payload) => (
		payload !== undefined ? payload : !state
	)
});

const filterVisible = createReducer(false, {
	[types.TOGGLE_USERS_FILTER]: (state, payload) => (
		payload !== undefined ? payload : !state
	)
});

export default combineReducers({
	isBusy,
	editMode,
	filterVisible
});
