import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {ApiAction, createApiAction} from '@src/store/middlewares/api';
import {addErrorToast} from '@src/utils/toasts';
import {ICheckUserContentResponse, makeCheckUserContentRequest} from '@src/api/backend/user';
import checkExpiration from '@tehzor/tools/utils/checkExpiration';
import {IState} from '@src/store/modules';
import {AppThunkAction} from '@src/store/interfaces/thunk';

export interface ICheckUserContentPayload {
	userId: string;
	counts: ICheckUserContentResponse;
}

const request = () => ({type: types.CHECK_CONTENT_REQUEST});

const success = (counts: ICheckUserContentResponse, userId: string) => ({
	type: types.CHECK_CONTENT_SUCCESS,
	payload: {
		userId,
		counts
	} as ICheckUserContentPayload
});

const failure = (error: IError) => {
	if (error.statusCode !== 401) {
		addErrorToast('Ошибка', 'при проверке наличия контента');
	}
	return {
		type: types.CHECK_CONTENT_FAILURE,
		payload: {...error}
	};
};

/**
 * Проверяет наличие созданного пользователем контента
 *
 * @param userId id пользователя
 */
export const checkUserContent = (userId: string): AppThunkAction<Promise<ICheckUserContentResponse>> => (
	dispatch,
	getState
) => {
	const state = getState();

	const apiAction = createApiAction<ICheckUserContentResponse>(
		request,
		res => success(res, userId),
		failure,
		() => makeCheckUserContentRequest(userId)
	);

	return state.entities.user.id === userId
		? dispatch(checkExpiration<IState, ICheckUserContentResponse, ApiAction>(s => s.entities.user, apiAction))
		: dispatch(apiAction);
};
