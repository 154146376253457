export const GET_OPERATION_TYPES_REQUEST = 'GET_OPERATION_TYPES_REQUEST';
export const GET_OPERATION_TYPES_SUCCESS = 'GET_OPERATION_TYPES_SUCCESS';
export const GET_OPERATION_TYPES_FAILURE = 'GET_OPERATION_TYPES_FAILURE';

export const GET_LOG_ITEMS_REQUEST = 'GET_LOG_ITEMS_REQUEST';
export const GET_LOG_ITEMS_SUCCESS = 'GET_LOG_ITEMS_SUCCESS';
export const GET_LOG_ITEMS_FAILURE = 'GET_LOG_ITEMS_FAILURE';

export const GET_NEXT_LOG_ITEMS_REQUEST = 'GET_NEXT_LOG_ITEMS_REQUEST';
export const GET_NEXT_LOG_ITEMS_SUCCESS = 'GET_NEXT_LOG_ITEMS_SUCCESS';
export const GET_NEXT_LOG_ITEMS_FAILURE = 'GET_NEXT_LOG_ITEMS_FAILURE';

export const ARCHIVE_OBJECT_REQUEST = 'ARCHIVE_OBJECT_REQUEST';
export const ARCHIVE_OBJECT_SUCCESS = 'ARCHIVE_OBJECT_SUCCESS';
export const ARCHIVE_OBJECT_FAILURE = 'ARCHIVE_OBJECT_FAILURE';

export const RESTORE_OBJECT_REQUEST = 'RESTORE_OBJECT_REQUEST';
export const RESTORE_OBJECT_SUCCESS = 'RESTORE_OBJECT_SUCCESS';
export const RESTORE_OBJECT_FAILURE = 'RESTORE_OBJECT_FAILURE';

export const CHANGE_OBJECTS_FILTER = 'CHANGE_OBJECTS_FILTER';
export const CLEAR_OBJECTS_FILTER = 'CLEAR_OBJECTS_FILTER';

export const GET_ARCHIVE_OBJECTS_REQUEST = 'GET_ARCHIVE_OBJECTS_REQUEST';
export const GET_ARCHIVE_OBJECTS_SUCCESS = 'GET_ARCHIVE_OBJECTS_SUCCESS';
export const GET_ARCHIVE_OBJECTS_FAILURE = 'GET_ARCHIVE_OBJECTS_FAILURE';

export const UNARCHIVED_ARCHIVE_OBJECT_REQUEST = 'UNARCHIVED_ARCHIVE_OBJECT_REQUEST';
export const UNARCHIVED_ARCHIVE_OBJECT_SUCCESS = 'UNARCHIVED_ARCHIVE_OBJECT_SUCCESS';
export const UNARCHIVED_ARCHIVE_OBJECT_FAILURE = 'UNARCHIVED_ARCHIVE_OBJECT_FAILURE';

export const DELETE_ARCHIVE_OBJECT_REQUEST = 'DELETE_ARCHIVE_OBJECT_REQUEST';
export const DELETE_ARCHIVE_OBJECT_SUCCESS = 'DELETE_ARCHIVE_OBJECT_SUCCESS';
export const DELETE_ARCHIVE_OBJECT_FAILURE = 'DELETE_ARCHIVE_OBJECT_FAILURE';

export const ADD_TEMP_FILE_REQUEST = 'ADD_TEMP_FILE_REQUEST';
export const ADD_TEMP_FILE_SUCCESS = 'ADD_TEMP_FILE_SUCCESS';
export const ADD_TEMP_FILE_FAILURE = 'ADD_TEMP_FILE_FAILURE';

export const DELETE_TEMP_FILE_REQUEST = 'DELETE_TEMP_FILE_REQUEST';
export const DELETE_TEMP_FILE_SUCCESS = 'DELETE_TEMP_FILE_SUCCESS';
export const DELETE_TEMP_FILE_FAILURE = 'DELETE_TEMP_FILE_FAILURE';

export const GET_LIST_TYPES_EXPORT_REQUEST = 'GET_LIST_TYPES_EXPORT_REQUEST';
export const GET_LIST_TYPES_EXPORT_SUCCESS = 'GET_LIST_TYPES_EXPORT_SUCCESS';
export const GET_LIST_TYPES_EXPORT_FAILURE = 'GET_LIST_TYPES_EXPORT_FAILURE';
