import * as types from './constants';
import {IState} from '@src/store/modules';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@src/utils/toasts';
import checkLoaded from '@tehzor/tools/core/checkLoaded';
import {ApiAction, createApiAction} from '@src/store/middlewares/api';
import {IGetProblemStatusesResponse, requestProblemStatuses} from '@src/api/backend/problemStatuses';

export type IGetProblemStatusesPayload = IGetProblemStatusesResponse;

const request = () => ({type: types.GET_REQUEST});

const success = (response: IGetProblemStatusesResponse) => ({
	type: types.GET_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	if (error.statusCode !== 401) {
		addErrorToast('Ошибка', 'при загрузке статусов нарушений');
	}
	return {
		type: types.GET_FAILURE,
		payload: error
	};
};

/**
 * Получает список статусов нарушений
 */
export const getProblemStatuses = () =>
	checkLoaded<IState, IGetProblemStatusesResponse, ApiAction>(
		state => state.entities.problemStatuses,
		createApiAction<IGetProblemStatusesResponse>(request, success, failure, requestProblemStatuses)
	);
