import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import config from '@src/core/config';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';
import {
	IAddTemplatesSetPayload,
	IEditTemplatesSetPayload,
	IGetTemplatesSetPayload
} from '@src/store/modules/entities/problemTemplatesSet/actions';
import {IProblemTemplatesSet} from '@tehzor/tools/interfaces/problemTemplatesSets/IProblemTemplatesSet';

const {entitiesCacheTime} = config;

export interface ITemplatesSetFiltersState {}

export interface ITemplatesSetState {
	data: IProblemTemplatesSet;
	expires?: number;
	filters: ITemplatesSetFiltersState;
}

export const getInitialState = (): ITemplatesSetState => ({
	data: {
		id: '',
		name: '',
		companies: [],
		data: [],
		shared: false
	},
	filters: {}
});

export default createReducer<ITemplatesSetState>(getInitialState(), {
	[types.GET_REQUEST]: getInitialState,
	[types.RESET]: getInitialState,
	[types.GET_SUCCESS]: (state, {payload}: {payload: IGetTemplatesSetPayload}) => {
		state.data = payload;
		state.expires = Date.now() + entitiesCacheTime * 1000;
	},
	[types.ADD_SUCCESS]: (state, {payload}: {payload: IAddTemplatesSetPayload}) => {
		state.data = payload;
		state.expires = Date.now() + entitiesCacheTime * 1000;
	},
	[types.EDIT_SUCCESS]: (state, {payload}: {payload: IEditTemplatesSetPayload}) => {
		state.data = payload;
		state.expires = Date.now() + entitiesCacheTime * 1000;
	},
	[types.CHANGE_FILTERS]: (state, {payload}: {payload: ITemplatesSetFiltersState}) => {
		state.filters = payload;
	},
	[CLEAR_STORE]: getInitialState
});
