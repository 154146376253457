import {ColumnDef} from '@tanstack/react-table';
import {format} from 'date-fns';
import {dateTimeCommaSeparatedFormat} from '@tehzor/tools/utils/dateFormats';
import {IPreparedLogRecord} from '../../utils/convertRecords';
import {TypeCell} from '../TypeCell';

export const buildRecordsColumns: Array<ColumnDef<IPreparedLogRecord>> = [
	{
		accessorKey: 'type',
		header: 'Действие',
		cell: TypeCell
	},
	{
		accessorKey: 'user',
		header: 'Пользователь'
	},
	{
		accessorKey: 'date',
		header: 'Дата',
		accessorFn: originalRow => format(new Date(originalRow.date), dateTimeCommaSeparatedFormat)
	}
];
