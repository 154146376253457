import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import * as objectTypes from '../object/constants';
import config from '@src/core/config';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';
import {
	addEntitySuccess,
	editEntitySuccess,
	getEntitiesSuccess,
	IEntityState,
	getEntitiesInitialState
} from '@tehzor/tools/utils/reducersHandlers';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import {IArchiveObjectPayload} from '../object/actions';

const {entitiesCacheTime} = config;

export type IObjectsState = IEntityState<IObject>;

export default createReducer<IObjectsState>(getEntitiesInitialState<IObject>(), {
	[types.GET_SUCCESS]: getEntitiesSuccess<IObject>(entitiesCacheTime),
	[objectTypes.GET_SUCCESS]: editEntitySuccess<IObject>(),
	[objectTypes.ADD_SUCCESS]: addEntitySuccess<IObject>(),
	[objectTypes.EDIT_SUCCESS]: state => {
		// Сброс expires, чтобы можно было обновить весь список
		state.expires = undefined;
	},
	[objectTypes.DELETE_SUCCESS]: editEntitySuccess<IObject>(),
	[objectTypes.RESTORE_SUCCESS]: editEntitySuccess<IObject>(),
	[objectTypes.ARCHIVE_SUCCESS]: (state, {payload}: {payload: IArchiveObjectPayload}) => {
		delete state.byId[payload.objectId];
		state.allIds = state.allIds.filter(id => id !== payload.objectId);
	},
	[CLEAR_STORE]: () => getEntitiesInitialState<IObject>()
});
