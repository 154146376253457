import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import ISavingCompany from '@tehzor/tools/interfaces/companies/ISavingCompany';
import {IAddCompanyResponse, makeCompanyAddRequest} from '@src/api/backend/company';
import {addErrorToast} from '@src/utils/toasts';

export type IAddCompanyPayload = IAddCompanyResponse;

const request = () => ({type: types.ADD_REQUEST});

const success = (response: IAddCompanyResponse) => ({
	type: types.ADD_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	if (error.statusCode !== 401) {
		addErrorToast('Ошибка', 'при добавлении компании');
	}
	return {
		type: types.ADD_FAILURE,
		payload: {...error}
	};
};

/**
 * Добавляет компанию
 *
 * @param company данные компании
 */
export const addCompany = (company: ISavingCompany) =>
	createApiAction<IAddCompanyResponse>(request, success, failure, () => makeCompanyAddRequest(company));
