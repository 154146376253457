import React, {useEffect, useRef} from 'react';
import './AppContent.less';
import {Outlet, Route, Routes} from 'react-router-dom';
import classNames from 'classnames';
import AppSidebar from '../AppSidebar';
import {PrivateRoute} from '@src/containers/PrivateRoute';
import {DashboardPage} from '@src/pages/DashboardPage';
import {LoginPage} from '@src/pages/LoginPage';
import {LogoutPage} from '@src/pages/LogoutPage';
import {RolesPage} from '@src/pages/RolesPage';
import {RolePage} from '@src/pages/RolePage';
import {LogPage} from '@src/pages/LogPage';
import {UsersPage} from '@src/pages/UsersPage';
import UserPage from '@src/pages/UserPage';
import NotFoundPage from '@src/pages/NotFoundPage';
import {CompaniesPage} from '@src/pages/CompaniesPage';
import {CompanyPage} from '@src/pages/CompanyPage';
import {ObjectsPage} from '@src/pages/ObjectsPage';
import ObjectPage from '@src/pages/ObjectPage';
import {RegStandardsPage} from '@src/pages/RegStandardsPage';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useIsTablet, useIsLargeTablet} from '@tehzor/ui-components/src/utils/mediaQueries';
import {ProblemTemplatesSetsPage} from '@src/pages/ProblemTemplatesSetsPage/ProblemTemplatesSetsPage';
import {ProblemTemplatesSetPage} from '@src/pages/ProblemTemplatesSetPage/ProblemTemplatesSetPage';

const AppContent = () => {
	const isAuthenticated = useAppSelector(s => s.auth.isAuthenticated);
	const isActivated = useAppSelector(s => s.auth.profile.activated);
	const expanded = useAppSelector(s => s.settings.sidebar.expanded);
	const isTablet = useIsTablet();
	const isDesktop = useIsLargeTablet();
	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (ref.current) {
			// Добавление/удаление transition руками необходимо для избежания ненужных анимаций,
			// связанных с отступом из-за sidebar'а (при mount'е, logout'е и др.),
			// а также для предотвращения избыточного рендеринга
			// в случае использвания локального state для хранения класса с transition.
			ref.current.style.transition
				= isDesktop && isAuthenticated && isActivated ? 'margin 0.2s cubic-bezier(0.32, 0.72, 0.37, 0.95)' : '';
		}
	}, [isDesktop, isAuthenticated, isActivated]);

	const classes = classNames(
		'app-content',
		{'app-content_padding_56': isAuthenticated && isTablet},
		{'app-content_padding_72': isAuthenticated && isDesktop && !expanded},
		{'app-content_padding_288': isAuthenticated && isDesktop && expanded}
	);

	return (
		<div
			className={classes}
			ref={ref}
		>
			<AppSidebar/>

			<Routes>
				<Route
					path="/"
					element={(
						<PrivateRoute
							path="/"
							element={<DashboardPage/>}
						/>
					)}
				/>
				<Route
					path="/companies/*"
					element={(
						<PrivateRoute
							path="/companies"
							element={<CompaniesPage/>}
						/>
					)}
				/>
				<Route
					path="/companies/:companyId/*"
					element={(
						<PrivateRoute
							path="/companies/:companyId/*"
							element={<CompanyPage/>}
						/>
					)}
				/>
				<Route
					path="/companies/add"
					element={(
						<PrivateRoute
							path="/companies/add"
							element={<CompanyPage/>}
						/>
					)}
				/>

				<Route
					path="/objects/*"
					element={(
						<PrivateRoute
							path="/objects/*"
							element={<ObjectsPage/>}
						/>
					)}
				/>
				<Route
					path="/objects/add"
					element={(
						<PrivateRoute
							path="/objects/add"
							element={<ObjectPage/>}
						/>
					)}
				/>
				<Route
					path="/objects/:objectId"
					element={(
						<PrivateRoute
							path="/objects/:objectId"
							element={<ObjectPage/>}
						/>
					)}
				/>
				<Route
					path="/users"
					element={(
						<PrivateRoute
							path="/users"
							element={<UsersPage/>}
						/>
					)}
				/>
				<Route
					path="/users/add"
					element={(
						<PrivateRoute
							path="/users/add"
							element={<UserPage/>}
						/>
					)}
				/>
				<Route
					path="/users/:userId"
					element={(
						<PrivateRoute
							path="/users/:userId"
							element={<UserPage/>}
						/>
					)}
				/>
				<Route
					path="/roles"
					element={(
						<PrivateRoute
							path="/roles"
							element={<RolesPage/>}
						/>
					)}
				/>
				<Route
					path="/roles/add"
					element={(
						<PrivateRoute
							path="/roles/add"
							element={<RolePage/>}
						/>
					)}
				/>
				<Route
					path="/roles/:roleId"
					element={(
						<PrivateRoute
							path="/roles/:roleId"
							element={<RolePage/>}
						/>
					)}
				/>
				<Route
					path="/reg-standards"
					element={(
						<PrivateRoute
							path="/reg-standards"
							element={<RegStandardsPage/>}
						/>
					)}
				/>
				<Route
					path="/problem-templates-sets"
					element={(
						<PrivateRoute
							path="/problem-templates-sets"
							element={<ProblemTemplatesSetsPage/>}
						/>
					)}
				/>
				<Route
					path="/problem-templates-sets/:templatesSetId"
					element={(
						<PrivateRoute
							path="/problem-templates-sets/:templatesSetId"
							element={<ProblemTemplatesSetPage/>}
						/>
					)}
				/>
				<Route
					path="/logs"
					element={(
						<PrivateRoute
							path="/logs"
							element={<LogPage/>}
						/>
					)}
				/>
				<Route
					path="/login"
					element={<LoginPage/>}
				/>
				<Route
					path="/logout"
					element={<LogoutPage/>}
				/>
				{/* <PrivateRoute path="/profile" component={ProfilePage}/> */}
				<Route element={<PrivateRoute element={<NotFoundPage/>}/>}/>
			</Routes>
			<Outlet/>
		</div>
	);
};

export default AppContent;
