import * as listTypes from '../constants';

/**
 * Изменяет смещение списка
 *
 * @param offset смещение
 */
export const changeOffset = (offset: number) => ({
	type: listTypes.CHANGE_OFFSET,
	payload: {
		objectId: 'all',
		offset
	}
});

/**
 * Изменяет выбранные строки в таблице
 *
 * @param selectedRows массив id выбранных строк
 */
export const changeSelectedRows = (selectedRows: string[]) => ({
	type: listTypes.CHANGE_SELECTED_ROWS,
	payload: {
		objectId: 'all',
		selectedRows
	}
});

export * from './get';
