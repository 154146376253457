import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import IRegulatoryStandard from '@tehzor/tools/interfaces/IRegulatoryStandard';
import {
	getLoadedEntitiesInitialState,
	getLoadedEntitiesSuccess,
	ILoadedEntityState
} from '@tehzor/tools/utils/reducersHandlers';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';

export type IRegStandardsState = ILoadedEntityState<IRegulatoryStandard>;

export default createReducer<IRegStandardsState>(getLoadedEntitiesInitialState<IRegulatoryStandard>(), {
	[types.GET_REQUEST]: getLoadedEntitiesInitialState,
	[types.GET_SUCCESS]: getLoadedEntitiesSuccess<IRegulatoryStandard>(),
	[types.UPDATE_SUCCESS]: getLoadedEntitiesSuccess<IRegulatoryStandard>(),
	[CLEAR_STORE]: getLoadedEntitiesInitialState
});
