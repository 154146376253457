import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';

const getAllIds = (state: IState) => state.entities.objectStages.allIds || [];
const getById = (state: IState) => state.entities.objectStages.byId || {};

/**
 * Возвращает стадии объекта в виде массива
 */
export const extractObjectStagesAsArray = createSelector(
	[getAllIds, getById],
	(allIds, byId) => allIds.map((id: string) => byId[id])
);
