import IObjectFieldSetting from '@tehzor/tools/interfaces/objects/IObjectFieldSetting';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';

const convertFieldsSettings = (fields?: IObjectFieldSetting[]) =>
	(fields
		? fields.map(field => ({
				fieldId: field.fieldId,
				isRequired: Boolean(field.isRequired),
				canBeCopied: Boolean(field.canBeCopied)
		  }))
		: []);
export const convertForm = (objectState: IObject) => {
	const {fieldsSettings, fullImage} = objectState;
	return {
		...objectState,
		image: fullImage,
		newImage: [],
		fieldsSettings: {
			buildingProblem: convertFieldsSettings(fieldsSettings?.buildingProblem),
			acceptanceProblem: convertFieldsSettings(fieldsSettings?.acceptanceProblem),
			acceptanceClaimProblem: convertFieldsSettings(fieldsSettings?.acceptanceClaimProblem),
			warrantyClaimProblem: convertFieldsSettings(fieldsSettings?.warrantyClaimProblem),
			problemReply: convertFieldsSettings(fieldsSettings?.problemReply),
			inspection: convertFieldsSettings(fieldsSettings?.inspection),
			task: convertFieldsSettings(fieldsSettings?.task),
			contract: convertFieldsSettings(fieldsSettings?.contract)
		}
	};
};
