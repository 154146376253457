import {createReducer} from '@reduxjs/toolkit';
import * as types from '@src/constants/notifications/periods';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';
import INotificationPeriod from '@tehzor/tools/interfaces/INotificationPeriod';
import {
	getLoadedEntitiesSuccess,
	ILoadedEntityState,
	getLoadedEntitiesInitialState
} from '@tehzor/tools/utils/reducersHandlers';

export type INotificationsPeriodsState = ILoadedEntityState<INotificationPeriod>;

export default createReducer<INotificationsPeriodsState>(getLoadedEntitiesInitialState(), {
	[types.GET_NOTIFICATION_PERIODS_SUCCESS]: getLoadedEntitiesSuccess<INotificationPeriod>(),
	[CLEAR_STORE]: getLoadedEntitiesInitialState
});
