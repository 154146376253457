import React, {memo} from 'react';
import {EditableFieldLabel, TextField} from '@tehzor/ui-components';

interface INameFieldProps {
	value?: string;
	label?: string;
	hasError?: string;
	onChange?: (value: string) => void;
}

export const FieldWithLabel = memo(({value, label, hasError, onChange}: INameFieldProps) => (
	<div className="field">
		<EditableFieldLabel className="field__label">{label}</EditableFieldLabel>
		<TextField
			className="object-page__field"
			elementType="input"
			error={hasError}
			value={value}
			onChange={onChange}
		/>
	</div>
	));