import {createSelector} from 'reselect';
import {getInitialStateForPage, ITemplatesSetsPagesSettingsState} from './reducers';
import {IState} from '@src/store/modules';

/**
 * Возвращает настройки страницы для конкретного объекта
 */
export const extractTemplatesSetsPageSettings = createSelector(
	(state: IState) => state.settings.pages.problemTemplatesSets,
	(state: IState, objectId?: string) => objectId || 'all',
	(rootData: ITemplatesSetsPagesSettingsState, objectId: string) =>
		rootData[objectId] || getInitialStateForPage(objectId)
);
