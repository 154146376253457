import {useMemo} from 'react';
import useAppSelector from '../useAppSelector';
import {formCompanyLink} from '@src/utils/links';
import {extractCompany} from '@src/store/modules/entities/companies/selectors';

/**
 * Хук для хлебных крошек до компании
 *
 * @param companyId id компании
 */
export function useCompanyBreadcrumbs(companyId: string) {
	const company = useAppSelector(s => extractCompany(s, companyId));

	return useMemo(() => {
		const items = [{label: 'Все компании', url: '/companies'}];
		if (company) {
			items.push({
				label: company.name,
				url: formCompanyLink(company.id)
			});
		}
		return items;
	}, [company]);
}
