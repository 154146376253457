import React from 'react';
import {Button} from '@tehzor/ui-components';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {deleteCompany} from '@src/store/modules/entities/company/actions';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useCompanyPermissions} from '@src/core/hooks/permissions/useCompanyPermissions';
import {useChangePath} from '@src/core/hooks/useChangePath';

interface IDesktopTopActionsProps {
	companyId: string;
}

const Actions = ({companyId}: IDesktopTopActionsProps) => {
	const dispatch = useAppDispatch();
	const permissions = useCompanyPermissions(companyId);
	const {pushPath} = useChangePath();

	const [deleteDialog, getDeleteConfirmation] = useConfirmDialog(
		'Вы действительно хотите удалить компанию?',
		'Это действие также заархивирует все её объекты.',
		{acceptBtnProps: {type: 'accent-red'}}
	);

	const [, handleDelete] = useAsyncFn(async () => {
		if (companyId && (await getDeleteConfirmation())) {
			await dispatch(deleteCompany(companyId));
			pushPath('/companies');
		}
	}, [companyId]);

	return (
		<>
			{permissions.canDelete && (
				<Button
					// type="cancel"
					leftIcon={<i className="tz-delete"/>}
					label="Удалить"
					onClick={handleDelete}
				/>
			)}

			{deleteDialog}
		</>
	);
};

export default Actions;
