import React from 'react';
import './AppSidebar.less';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useIsLargeTablet, useIsTablet} from '@tehzor/ui-components/src/utils/mediaQueries';
import DesktopAppSidebar from './components/DesktopAppSidebar';
import TabletAppSidebar from './components/TabletAppSidebar';
import MobileAppSidebar from './components/MobileAppSidebar';

const AppSidebar = () => {
	const isAuthenticated = useAppSelector(s => s.auth.isAuthenticated);
	const isActivated = useAppSelector(s => s.auth.profile.activated);
	const isTablet = useIsTablet();
	const isDesktop = useIsLargeTablet();

	if (!isAuthenticated || !isActivated) {
		return null;
	}
	if (isDesktop) {
		return <DesktopAppSidebar/>;
	}
	if (isTablet) {
		return <TabletAppSidebar/>;
	}
	return <MobileAppSidebar/>;
};
export default AppSidebar;
