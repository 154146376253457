import {Checkbox} from '@tehzor/ui-components';
import React, {useCallback, useContext} from 'react';
import {RoleDispatchCtx, RolePermissionsCtx} from '../../../RolePage';
import {IRestrictionProps} from './Restriction';

const BooleanRestriction = ({id, name, permissionId}: IRestrictionProps) => {
	const permissions = useContext(RolePermissionsCtx);
	const dispatch = useContext(RoleDispatchCtx);

	const p = permissions.find(item => item.permissionId === permissionId);
	const isChecked = !!(p && p.restrictions && p.restrictions[id]);

	const onChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			dispatch({
				type: 'update-restriction',
				permissionId,
				restriction: id,
				value: event.target.checked || undefined
			});
		},
		[permissionId, id]
	);

	return (
		<>
			<Checkbox
				className={{content: 'role-page__settings-label'}}
				checked={isChecked}
				onChange={onChange}
			>
				{name}
			</Checkbox>
			<span className="role-page__settings-id">
				(
				{id}
				)
			</span>
		</>
	);
};

export default BooleanRestriction;
