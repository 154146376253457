import * as types from '@src/constants/entities/rolePermissions';
import createReducer from '@tehzor/tools/utils/createReducer';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';
import IRolePermission from '@tehzor/tools/interfaces/IRolePermission';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';

interface IRolePermissionsState {
	byId: {
		[id: string]: IRolePermission;
	};
	allIds: string[];
	loaded: boolean;
}

const initialState = {
	byId: {},
	allIds: [],
	loaded: false
} as IRolePermissionsState;

const rolePermissions = createReducer<IRolePermissionsState>(initialState, {
	[types.GET_ROLE_PERMISSIONS_SUCCESS]: (state, payload: INormalizedData<IRolePermission>) => ({
		byId: payload.byId,
		allIds: payload.allIds,
		loaded: true
	}),
	[types.GET_ROLE_PERMISSIONS_FAILURE]: () => ({...initialState}),
	[CLEAR_STORE]: () => ({...initialState})
});

export {IRolePermissionsState};
export default rolePermissions;
