import React, {memo} from 'react';
import {ILogRecord} from '@tehzor/tools/interfaces/ILogRecord';

interface IDetailsErrorProps {
	error?: ILogRecord['error'];
}

export const DetailsError = memo(({error}: IDetailsErrorProps) =>
	(error ? (
		<>
			<div className="field__label log-page__details-title">Сведения об ошибке:</div>

			<div className="log-page__details-list">
				{error.error && (
					<div className="log-page__details-list-row">
						<div className="log-page__details-list-name">Код ошибки</div>
						<div className="log-page__details-list-value">{error.error}</div>
					</div>
				)}
				{error.message && (
					<div className="log-page__details-list-row">
						<div className="log-page__details-list-name">Описание ошибки</div>
						<div className="log-page__details-list-value">{error.message}</div>
					</div>
				)}
				{error.stack && (
					<div className="log-page__details-list-row">
						<div className="log-page__details-list-name">Стек</div>
						<div className="log-page__details-list-value">{error.stack}</div>
					</div>
				)}
			</div>
		</>
	) : null));
