import React, {memo} from 'react';
import {CellContext} from '@tanstack/react-table';
import IRegulatoryStandard from '@tehzor/tools/interfaces/IRegulatoryStandard';

export const LinkCell = memo(({row}: CellContext<IRegulatoryStandard, IRegulatoryStandard>) => {
	const regulatoryStandardLink = `https://www.faufcc.ru/technical-regulation-in-constuction/formulary-list/?s=${row.original.number}`;
	return (
		<a
			href={regulatoryStandardLink}
			target="_blank"
			rel="noreferrer"
		>
			Посмотреть
		</a>
	);
});
