import React from 'react';
import {Field} from './Field';
import {rowIdent} from '../InnerTable';
import {IRolePermissionAvailableRestrictions, IRolePermissionFields} from '@tehzor/tools/interfaces/IRolePermission';

interface IRestrictionsProps {
	permissionId: string;
	fields: IRolePermissionFields[];
	restrictions: IRolePermissionAvailableRestrictions[];
	level?: number;
}

export const Fields = ({permissionId, fields, level = 0, restrictions}: IRestrictionsProps) => (
	<div
		className="role-page__settings"
		style={{marginLeft: `${level * rowIdent + 50}px`}}
	>
		<div className="role-page__settings-title">Ограничения по полям:</div>

		{fields.map(item => (
			<div
				key={item.id}
				className="role-page__settings-item"
			>
				<Field
					{...item}
					permissionId={permissionId}
					restrictions={restrictions}
				/>
			</div>
		))}
	</div>
);
