import React, {useCallback, useContext} from 'react';
import {Checkbox} from '@tehzor/ui-components';
import {UserDispatchCtx} from '../../UserPage';

const ActivatedField = ({value, disabled}: {value: boolean, disabled?: boolean}) => {
	const dispatch = useContext(UserDispatchCtx);

	const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		dispatch({type: 'update', field: 'activated', value: event.target.checked});
	}, []);

	return (
		<div className="field">
			<div className="field__label">Состояние:</div>

			<Checkbox
				className="user-page__checkbox"
				checked={value}
				disabled={disabled}
				onChange={onChange}
			>
				Активирован
			</Checkbox>
		</div>
	);
};

export default ActivatedField;
