import createReducer from '@tehzor/tools/utils/createReducer';
import * as types from '@src/constants/entities/logRecords';
import {ILogRecord} from '@tehzor/tools/interfaces/ILogRecord';
import {IGetLogRecordsResponse} from '@src/actions/entities/logRecords';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';

interface ILogRecordsState extends INormalizedData<ILogRecord> {
	offset: number;
	total: number;
	loading: boolean;
}

const initialState = {
	byId: {},
	allIds: [],
	offset: 0,
	total: 0,
	loading: false
} as ILogRecordsState;

const logRecords = createReducer<ILogRecordsState>(initialState, {
	[types.GET_LOG_RECORDS_REQUEST]: state => ({
		...state,
		loading: true
	}),
	[types.GET_LOG_RECORDS_SUCCESS]: (state, payload: IGetLogRecordsResponse) => ({
		byId: payload.byId,
		allIds: payload.allIds,
		offset: payload.offset,
		total: payload.total,
		loading: false
	}),
	[types.CHANGE_LOG_RECORDS_OFFSET]: (state, payload: {offset: number}) => ({
		...state,
		offset: payload.offset,
		loading: false
	})
});

export {ILogRecordsState};
export default logRecords;
