import {wsConnector} from '@src/api/backend/wsConnector';
import {IProblemTemplatesSet} from '@tehzor/tools/interfaces/problemTemplatesSets/IProblemTemplatesSet';

export type IGetTemplatesSetResponse = IProblemTemplatesSet;

/**
 * Возвращает набор шаблонов нарушений
 * @param id идентификатор набора шаблонов нарушений
 */
export const requestTemplatesSet = (id: string) =>
	wsConnector.sendAuthorizedRequest<IGetTemplatesSetResponse>('getProblemTemplatesSet', {id});
