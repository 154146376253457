import {RoleDispatchCtx, RolePermissionsCtx} from '@src/pages/RolePage/RolePage';
import {IRolePermissionAvailableRestrictions} from '@tehzor/tools/interfaces/IRolePermission';
import {Checkbox} from '@tehzor/ui-components';
import React, {useCallback, useContext} from 'react';

export interface IFieldProps {
	id: string;
	name: string;
	permissionId: string;
	restrictions: IRolePermissionAvailableRestrictions[];
}

export const Field = (props: IFieldProps) => {
	const {name, permissionId, restrictions, id} = props;

	const permissions = useContext(RolePermissionsCtx);
	const dispatch = useContext(RoleDispatchCtx);

	const selectFields = permissions.find(item => item.permissionId === permissionId)?.fields;
	const selectFieldsRestrictions = selectFields ? selectFields[id] : undefined;

	const onChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>, restrictionId: string, disabeOthers: boolean) => {
			if (event.target.checked === true) {
				restrictions.forEach(el => {
					if (disabeOthers === true) {
					dispatch({
						type: 'update-fields',
						permissionId,
						restriction: el.id,
						field: id,
						value: el.id === restrictionId
					});
				} else if (el.id === restrictionId) {
					dispatch({
						type: 'update-fields',
						permissionId,
						restriction: el.id,
						field: id,
						value: true
					});
				} else if (el.disabeOthers) {
					dispatch({
						type: 'update-fields',
						permissionId,
						restriction: el.id,
						field: id,
						value: false
					});
				}
				});
			} else {
				dispatch({
					type: 'update-fields',
					permissionId,
					restriction: restrictionId,
					field: id,
					value: event.target.checked || undefined
				});
			}
		},
		[restrictions, permissionId, id]
	);

	return (
		<div
			className="role-page__settings"
			style={{marginLeft: '50px'}}
		>
			<div className="role-page__settings-title">
				{name}
				:
			</div>

			{restrictions.map(item => (
				<div
					key={item.id}
					className="role-page__settings-item"
				>
					<>
						<Checkbox
							className={{content: 'role-page__settings-label'}}
							checked={!!(selectFieldsRestrictions ? selectFieldsRestrictions[item.id] : false)}
						// checked={true}
							onChange={e => onChange(e, item.id, item.disabeOthers)}
						>
							{item.name}
						</Checkbox>
						<span className="role-page__settings-id">
							(
							{item.id}
							)
						</span>
					</>

				</div>
		))}
		</div>
);
};
