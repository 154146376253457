import IUser from '@tehzor/tools/interfaces/IUser';

export function formTitle(userId?: string, user?: IUser) {
	if (!userId) {
		return 'Новый пользователь';
	}
	if (user) {
		return user.deleted ? `${user.displayName} (удалено)` : user.displayName;
	}
	return undefined;
}
