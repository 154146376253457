import {
	ColumnDef,
	ExpandedState,
	flexRender,
	getCoreRowModel,
	getExpandedRowModel,
	getSortedRowModel,
	OnChangeFn,
	Row,
	SortingState,
	useReactTable
} from '@tanstack/react-table';
import {Plate, TableWrap} from '@tehzor/ui-components';
import './SimpleTable.less';
import React, {Fragment, HTMLAttributes, ReactElement, useCallback, useState} from 'react';
import TablePagination from '@tehzor/ui-components/src/components/Table/components/TablePagination';
import classNames from 'classnames';

interface ISimpleTableProps<D extends { id: string }> {
	columns: Array<ColumnDef<D>>;
	enableSorting?: boolean;
	data: D[];
	sorting?: SortingState;
	pageCount?: number;
	pageSize?: number;
	pageSizeOptions?: number[];
	onPageChange?: (page: number) => void;
	onPageSizeChange?: (pageSize: number) => void;
	onSortingChange?: OnChangeFn<SortingState>;
	currentPage?: number;
	renderSubComponent?: (props: { row: Row<D> }) => ReactElement;
	getTableRowProps?: (row: Row<D>) => HTMLAttributes<HTMLTableRowElement>;
}

const ascIcon = (
	<i
		className={classNames(
			'tz-table-sort-20',
			'simple-table__table-header-cell__sort-icon',
			'simple-table__table-header-cell__sort-icon_asc'
		)}
	/>
);

const descIcon = <i className={classNames('tz-table-sort-20', 'simple-table__table-header-cell__sort-icon')}/>;

export const SimpleTable = <D extends { id: string }>(props: ISimpleTableProps<D>) => {
	const {
		data,
		columns,
		pageSizeOptions,
		onPageSizeChange,
		currentPage = 0,
		pageSize = 0,
		onPageChange,
		onSortingChange,
		pageCount = 0,
		sorting,
		enableSorting = false,
		getTableRowProps,
		renderSubComponent
	} = props;

	const [expanded, setExpanded] = useState<ExpandedState>({});
	const table = useReactTable<D>({
		data,
		columns,
		state: {
			expanded,
			sorting
		},
		onExpandedChange: setExpanded,
		onSortingChange: updaterOrValue =>
			(sorting && typeof updaterOrValue === 'function'
				? onSortingChange?.(updaterOrValue(sorting))
				: onSortingChange?.(updaterOrValue)),
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getExpandedRowModel: getExpandedRowModel(),
		sortDescFirst: false,
		enableSorting
	});
	const handleRowProps = (row: Row<D>) => {
		const rowProps = getTableRowProps?.(row);
		return {
			...rowProps,
			className: classNames('simple-table__table-row', rowProps?.className)
		};
	};

	const handlePageChange = useCallback(
		(page: number) => {
			if (onPageChange) {
				onPageChange(page);
				setExpanded({});
			}
		},
		[onPageChange]
	);
	const pagination = onPageSizeChange && handlePageChange && (
		<TablePagination
			showPageSizeOptions
			pageSizeOptions={pageSizeOptions}
			page={currentPage}
			pages={pageCount}
			pageSize={pageSize}
			onPageChange={handlePageChange}
			onPageSizeChange={onPageSizeChange}
		/>
	);

	return (
		<>
			<Plate withoutPadding>
				<TableWrap>
					<table className="simple-table">
						<thead className="simple-table__table-header">
							{table.getHeaderGroups().map(headerGroup => (
								<tr key={headerGroup.id}>
									{headerGroup.headers.map(header => (
										<th
											key={header.id}
											colSpan={header.colSpan}
											className="simple-table__table-header-cell"
										>
											{header.isPlaceholder ? null : (
											// eslint-disable-next-line jsx-a11y/click-events-have-key-events
												<div
													{...{
													className: header.column.getCanSort()
														? 'simple-table__table-header-cell__sort-container'
														: '',
													onClick: header.column.getToggleSortingHandler()
												}}
												>
													{flexRender(header.column.columnDef.header, header.getContext())}
													{{
													asc: ascIcon,
													desc: descIcon
												}[header.column.getIsSorted() as string] ?? null}
												</div>
										)}
										</th>
								))}
								</tr>
						))}
						</thead>
						<tbody>
							{table.getRowModel().rows.map(row => (
								<Fragment key={row.id}>
									<tr {...handleRowProps(row)}>
										{row.getVisibleCells().map(cell => (
											<td
												key={cell.id}
												className="simple-table__table-body"
												{...{
													style: {
														maxWidth: cell.column.getSize()
													}
												}}
											>
												{flexRender(cell.column.columnDef.cell, cell.getContext())}
											</td>
										))}
									</tr>
									{renderSubComponent && row.getIsExpanded() ? (
										<tr>
											<td colSpan={row.getVisibleCells().length}>
												{renderSubComponent({row})}
											</td>
										</tr>
									) : null}
								</Fragment>
							))}
						</tbody>
					</table>
				</TableWrap>
			</Plate>
			{pagination}
		</>
	);
};
