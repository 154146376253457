import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {wsConnector} from '@src/api/backend/wsConnector';
import {IProblemTemplatesSet} from '@tehzor/tools/interfaces/problemTemplatesSets/IProblemTemplatesSet';
import {
	ITemplatesSetsFiltersState,
	ITemplatesSetsSortState
} from '@src/store/modules/settings/pages/problemTemplatesSets/reducers';

export type IGetTemplatesSetsResponse = INormalizedData<IProblemTemplatesSet>;

/**
 * Возвращает список наборов шаблонов нарушений
 */
export const requestTemplatesSets = (filters: ITemplatesSetsFiltersState, sort: ITemplatesSetsSortState) =>
	wsConnector.sendAuthorizedRequest<IGetTemplatesSetsResponse>('getProblemTemplatesSets', {filters, sort});
