import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';

const getAllIds = (state: IState) => state.entities.logRecordTypes.allIds || [];
export const getLogRecordTypesById = (state: IState) => state.entities.logRecordTypes.byId || {};

/**
 * Возвращает записи в виде массива
 */
export const getLogRecordTypesAsArray = createSelector([getAllIds, getLogRecordTypesById], (allIds, byId) =>
	allIds.map((id: string) => byId[id]));
