import React from 'react';
import {TextField} from '@tehzor/ui-components';
import {CellProps} from 'react-table';
import ICompany from '@tehzor/tools/interfaces/companies/ICompany';

export const NameCell = ({row}: CellProps<ICompany>) => {
	const contractor = row.original;

	return (
		<TextField
			value={contractor.name}
			elementType="input"
			disabled
		/>
	);
};