/* eslint-disable @typescript-eslint/ban-types */
import React from 'react';
import {Column, useFlexLayout, useResizeColumns, useTable} from 'react-table';
import Table from '../Table';
import TableHead from '../Table/components/TableHead';
import TableRow from '../Table/components/TableRow';
import TableBody from '../Table/components/TableBody';
import TableCell from '../TableCell';
import TableWrap from '../Table/components/TableWrap';
import TableHeadRow from '../Table/components/TableHeadRow';

interface ISimpleTableProps<D extends object> {
	className?: string;
	style?: React.CSSProperties;
	columns: Array<Column<D>>;
	data: D[];
	showHead?: boolean;

	onRowClick?: (data: D) => void;
}

const SimpleTable = <D extends object>(props: ISimpleTableProps<D>) => {
	const {className, style, columns, data, showHead = true, onRowClick} = props;
	const isRowsClickable = !!onRowClick;

	const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} = useTable<D>(
		{columns, data},
		useFlexLayout,
		useResizeColumns
	);

	return (
		<TableWrap
			className={className}
			style={style}
		>
			<Table {...getTableProps()}>
				{showHead && (
					<TableHead>
						{headerGroups.map(headerGroup => (
							<TableHeadRow {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map(column => (
									<TableCell
										{...column.getHeaderProps()}
										showBorders
									>
										{column.render('Header')}
									</TableCell>
								))}
							</TableHeadRow>
						))}
					</TableHead>
				)}
				<TableBody {...getTableBodyProps()}>
					{rows.map(row => {
						prepareRow(row);
						return (
							<TableRow
								{...row.getRowProps()}
								clickable={isRowsClickable}
							>
								{row.cells.map(cell => (
									<TableCell
										{...cell.getCellProps()}
										className={cell.column.className}
										data={row.original}
										showBorders
										onClick={cell.column.isNonClickable ? undefined : onRowClick}
									>
										{cell.render('Cell')}
									</TableCell>
								))}
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
		</TableWrap>
	);
};

export default SimpleTable;
