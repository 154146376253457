import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {wsConnector} from '@src/api/backend/wsConnector';
import IRegulatoryStandard from '@tehzor/tools/interfaces/IRegulatoryStandard';

export type IGetRegulatoryStandardsResponse = INormalizedData<IRegulatoryStandard>;

/**
 * Возвращает список стандартов
 *
 * @param objectId id объекта
 */
export const requestRegulatoryStandards = () =>
	wsConnector.sendAuthorizedRequest<IGetRegulatoryStandardsResponse>('getRegulatoryStandards');
