import React from 'react';
import {EditableCompanyContractors} from '@src/components/editableFields/EditableCompanyContractors';
import {IEditableCompanyState} from '@src/core/hooks/states/useEditableCompanyState';

interface IContractorsTabProps {
	editingState: IEditableCompanyState;
	companyId: string;
}

export const ContractorsTab = ({editingState, companyId}: IContractorsTabProps) => (
	<EditableCompanyContractors
		value={editingState.contractors}
		companyId={companyId}
	/>
);