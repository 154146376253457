import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';
import IRole from '@src/interfaces/IRole';
import {handlePagination} from '@src/utils/handlePagination';

const getAllIds = (state: IState) => state.entities.roles.allIds || [];
const getById = (state: IState): { [id: string]: IRole } => state.entities.roles.byId || {};

export const getRolesFilter = (state: IState) => state.settings.pages.roles.filter;
export const getRolesPageOffset = (state: IState) => state.settings.pages.roles.offset || 0;
export const getRolesPageSize = (state: IState) => state.settings.pages.roles.pageSize || 20;
export const getRolesSettings = (state: IState) => state.settings.pages.roles;

/**
 * Возвращает роли в виде массива
 */
export const getRolesAsArray = createSelector([getAllIds, getById], (allIds, byId) =>
	allIds.map((id: string) => byId[id]).sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())));

/**
 * Возвращает текущую роль
 */
export const getCurrentRole = createSelector(
	getById,
	(state: IState, objectId?: string) => objectId,
	(byId: Record<string, IRole>, objectId?: string) => (objectId ? byId[objectId] : undefined)
);
/**
 * Возвращает отфильрованный список ролей
 */

export const extractFilteredRoles = createSelector([getRolesAsArray, getRolesSettings], (roles, settings) => {
	const {filter, offset, pageSize} = settings;
	const {companiesIds} = filter;
	const filteredArr = roles.filter(role =>
		!companiesIds?.length || (role.company?.id && companiesIds.includes(role.company.id)));
	const paginatedArr = filteredArr.filter((item, i) => handlePagination(i, offset, pageSize));
	return {
		data: paginatedArr,
		pageCount: Math.ceil(filteredArr.length / pageSize),
		currentPage: Math.floor(offset / pageSize)
	};
});
