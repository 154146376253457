import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';
import INotificationChannel from '@tehzor/tools/interfaces/INotificationChannel';

const getAllIds = (state: IState) => state.notifications.channels.allIds || [];
const getById = (state: IState): {[id: string]: INotificationChannel} => state.notifications.channels.byId || {};

/**
 * Возвращает каналы уведомлений в виде массива
 */
export const getNotificationChannelsAsArray = createSelector(
	[getAllIds, getById],
	(allIds, byId) => allIds.map((id: string) => byId[id])
);
