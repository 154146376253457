import {createReducer} from '@reduxjs/toolkit';
import * as types from '@src/constants/entities/logRecordTypes';
import {getLoadedEntitiesInitialState, getLoadedEntitiesSuccess, ILoadedEntityState} from '@tehzor/tools/utils/reducersHandlers';
import ILogRecordType from '@tehzor/tools/interfaces/ILogRecordType';

export type ILogRecordTypesState = ILoadedEntityState<ILogRecordType>;

export default createReducer<ILogRecordTypesState>(getLoadedEntitiesInitialState(), {
	[types.GET_LOG_RECORD_TYPES_SUCCESS]: getLoadedEntitiesSuccess<ILogRecordType>()
});
