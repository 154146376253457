import {useMemo} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {hasPermission} from '@tehzor/tools/utils/findPermission';
import {UserRoleScopes} from '@tehzor/tools/interfaces/IUser';

export interface IPermissions {
	canAdd?: boolean;
}

/**
 * Возвращает полномочия для компаний
 */
export const useCompaniesPermissions = (): IPermissions => {
	const user = useAppSelector(s => s.auth.profile);

	return useMemo(() => {
		if (!user || !user.roles) {
			return {};
		}
		return {
			canAdd: hasPermission(user.roles, 'companiesAdd', UserRoleScopes.ALL)
		};
	}, [user]);
};
