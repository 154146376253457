interface IItemWithId {
	id: string;
}

type Tree<T> = T & {
	children?: Array<Tree<T>>;
};

/**
 * Првоеряет, есть ли элемент с указанным id в потомках у элемента дерева
 *
 * @param {Tree} tree элемент дерева
 * @param {string} id id элемента
 */
const hasDescendant = <T extends IItemWithId>(tree: Tree<T>, id: string): boolean => {
	if (!tree.children) {
		return false;
	}
	if (tree.children.some(item => item.id === id)) {
		return true;
	}
	for (const item of tree.children) {
		if (hasDescendant(item, id)) {
			return true;
		}
	}
	return false;
};

export default hasDescendant;
