import * as types from '../../constants/userConstants';
import createReducer from '@tehzor/tools/utils/createReducer';

const initialState = {
	id: '',
	email: '',
	companies: [],
	fullName: '',
	position: '',
	created: '',
	role: {
		id: '',
		name: '',
		permissions: []
	}
};

export default createReducer(initialState, {
	[types.UPDATE_CURRENT_USER_SUCCESS]: (state, payload) => {
		let newState = {
			...state
		};
		if (payload) {
			newState = {
				id: payload.id,
				email: payload.email,
				companies: payload.companies,
				fullName: payload.fullName,
				position: payload.position,
				created: payload.created,
				role: payload.role
			};
		}
		return newState;
	},
	[types.UPDATE_CURRENT_USER_FAILURE]: (state, payload) => ({
		...state,
		code: payload.code
	})
});
