import React, {useContext} from 'react';
import {CellInfo} from 'react-table-6';
import ICustomizableField from '@tehzor/tools/interfaces/ICustomizableField';
import {SettingsValueCtx} from './FieldsTable';
import classNames from 'classnames';

const NameCell = ({original}: CellInfo) => {
	const {id} = original as ICustomizableField;
	const value = useContext(SettingsValueCtx);
	const isChecked = value?.some(item => item.fieldId === id);

	return (
		<div className={classNames({'object-page__fields-settings-name-col_disabled': !isChecked})}>
			{original.name}
			<span className="object-page__fields-settings-name-id">
				(
				{original.id}
				)
			</span>
		</div>
	);
};

export default NameCell;
