import * as types from '../../constants/entitiesConstants';
import * as storeTypes from '../../constants/storeConstants';
import createReducer from '@tehzor/tools/utils/createReducer';
import config from '../../core/config';

const {entitiesCacheTime} = config;

const initialState = {
	typeExport: [],
	expires: null
};
export default createReducer(initialState, {
	[types.GET_LIST_TYPES_EXPORT_REQUEST]: () => ({
		...initialState
	}),
	[types.GET_LIST_TYPES_EXPORT_SUCCESS]: (state, payload) => ({
		typeExport: Object.values(payload),
		expires: Date.now() + entitiesCacheTime * 1000
	}),
	[storeTypes.CLEAR_STORE]: () => ({
		...initialState
	})
});
