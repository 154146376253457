import React, {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {SelectPopup, TextFieldWithForwardedRef, Tree} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import Select from '@tehzor/ui-components/src/components/inputs/select/Select';
import {extractObjectWithChilds} from '@src/store/modules/entities/objects/selectors';
import {makeObjectsTreeData} from '@src/store/modules/entities/objects/utils/makeObjectsTreeData';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import isEqual from 'lodash/isEqual';

interface IObjectIdsForIncFieldProps {
	objectId: string;
	value?: string[];
	onChange?: (value: string[]) => void;
}

const getTitle = (
	ids: string[],
	objectsMap: Record<string, IObject>
) =>
	ids.reduce((acc: string[], id) => {
		const object = objectsMap[id];
		if (
			object?.name
			&& !ids.find(item => object.parentId === item)
		) {
			acc.push(object.name);
		}
		return acc;
	}, []).join(', ');

export const ObjectIdsForIncField = memo(({objectId, value = [], onChange}: IObjectIdsForIncFieldProps) => {
	const [checked, setChecked] = useState(value);
	const [expanded, setExpanded] = useState(objectId ? [objectId] : []);

	useEffect(() => {
		setChecked(value);
	}, [value]);

	const objects = useAppSelector(s => extractObjectWithChilds(s, objectId));
	const objectsMap = useAppSelector(s => s.entities.objects.byId);
	const treeData = makeObjectsTreeData(objects);
	const title = useMemo(() => getTitle(checked, objectsMap), [checked, objectsMap]);

	const handleConfirm = useCallback(() => {
		if (!isEqual(checked, value)) {
			onChange?.(checked);
		}
	}, [onChange, checked, value]);

	const handleCancel = () => {
		setChecked(value);
	};

	return (
		<div className="field">
			<div className="field__label">Сквозная нумерация</div>
			<SelectPopup
				noHeader
				footer
				trigger={(
					<TextFieldWithForwardedRef
						elementType="div"
						value={title}
						icon={<i className="tz-simple-arrow-20"/>}
					/>
				)}
				onApply={handleConfirm}
				onCancel={handleCancel}
			>
				<Select
					multiple
				>
					<Tree
						data={treeData}
						multiple
						isCheckable
						checkedValue={checked}
						onCheck={setChecked}
						expandedValue={expanded}
						onExpand={setExpanded}
					/>
				</Select>
			</SelectPopup>
		</div>
	);
});