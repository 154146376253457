import {combineReducers} from 'redux';
import messages, {INotificationMessagesState} from './messages';
import subscriptions, {INotificationsSubscriptionsState} from './subscriptions';
import types, {INotificationsTypesState} from './types';
import channels, {INotificationsChannelsState} from './channels';
import intervals, {INotificationsIntervalsState} from './intervals';
import periods, {INotificationsPeriodsState} from './periods';

export interface INotificationsState {
	messages: INotificationMessagesState;
	subscriptions: INotificationsSubscriptionsState;
	types: INotificationsTypesState;
	channels: INotificationsChannelsState;
	intervals: INotificationsIntervalsState;
	periods: INotificationsPeriodsState;
}

export default combineReducers({
	messages,
	subscriptions,
	types,
	channels,
	intervals,
	periods
});
