import React, {memo} from 'react';
import {Button, LoadingPanel} from '@tehzor/ui-components';
import './RolesPage.less';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {RolesTable} from './components/RolesTable/RolesTable';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {getCompanies} from '@src/store/modules/entities/companies/actions';
import {useAsync} from 'react-use';
import {extractCompaniesAsArray} from '@src/store/modules/entities/companies/selectors';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {MultipleSelect} from '@src/components/MultipleSelect';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {getRoles} from '@src/store/modules/entities/roles/actions/get';
import {useRolesFilters} from './hook/useRolesFilters/useRolesFilter';

const plusIcon = <i className="tz-plus-24"/>;

export const RolesPage = memo(() => {
	const companies = useAppSelector(extractCompaniesAsArray);
	const {pushPath} = useChangePath();
	const dispatch = useAppDispatch();
	const loadingState = useAsync(async () => {
		await Promise.allSettled([dispatch(getRoles()), dispatch(getCompanies())]);
	}, []);

	useAppHeader(
		{
			title: 'Роли'
		},
		[]
	);

	const goToEditRole = () => {
		pushPath('/roles/add');
	};

	const {filterState, onCompaniesChange} = useRolesFilters();

	return (
		<LoadingPanel
			className="loading-panel_main"
			active={loadingState.loading}
		>
			<div className="page-cont">
				<div className="container">
					<div className="roles-page__buttons-block">
						<Button
							onClick={goToEditRole}
							leftIcon={plusIcon}
							label="Добавить"
							type="accent-blue"
						/>
					</div>
					<div className="roles-page__filters-block">
						<MultipleSelect
							label="Компании"
							onChangeFilter={onCompaniesChange}
							optionsIds={filterState.companiesIds}
							options={companies}
						/>
					</div>
					<RolesTable/>
				</div>
			</div>
		</LoadingPanel>
	);
});
