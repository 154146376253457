import {wsConnector} from '../wsConnector';
import ISavingUser from '@tehzor/tools/interfaces/users/ISavingUser';
import IUser from '@tehzor/tools/interfaces/IUser';

export type IAddUserResponse = IUser;

/**
 * Добавляет пользователя
 *
 * @param user данные пользователя
 */
export const makeUserAddRequest = (user: ISavingUser) =>
	wsConnector.sendAuthorizedRequest<IAddUserResponse>('addUser', {...user});
