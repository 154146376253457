import React, {memo} from 'react';

import {makeDateFilterLabel} from '@src/utils/makeDateFilterLabel';
import {longDateFormat} from '@tehzor/tools/utils/dateFormats';
import {DateRangePicker, FilterButton} from '@tehzor/ui-components';

interface IDateFilterFilterProps {
	label: string;
	from?: Date;
	to?: Date;
	changeFilters: (from: Date | undefined, to: Date | undefined) => void;
}

export const DateFilter = memo(({label, from, to, changeFilters}: IDateFilterFilterProps) => {
	const handleChange = (dateFrom: Date | null, dateTo: Date | null) => {
		const plannedFixDateFrom = dateFrom
			? new Date(dateFrom.getFullYear(), dateFrom.getMonth(), dateFrom.getDate())
			: undefined;
		const plannedFixDateTo = dateTo
			? new Date(dateTo.getFullYear(), dateTo.getMonth(), dateTo.getDate(), 23, 59, 59, 999)
			: undefined;

		changeFilters(plannedFixDateFrom, plannedFixDateTo);
	};

	const handleClear = () => {
		changeFilters(undefined, undefined);
	};

	return (
		<DateRangePicker
			trigger={triggerProps => (
				<FilterButton
					className="entities-filters__item"
					label={makeDateFilterLabel(label, from, to)}
					active={from !== undefined || to !== undefined}
					onClick={triggerProps.toggle}
					onClear={handleClear}
					ref={triggerProps.ref}
				/>
			)}
			valueFrom={from}
			valueTo={to}
			dateFormat={longDateFormat}
			useApplyButton
			onChange={handleChange}
		/>
	);
});
