import {DeleteCell} from './DeleteCell';
import {Column} from 'react-table';
import IUser from '@tehzor/tools/interfaces/IUser';

export const columns: Array<Column<IUser>> = [
	{
		accessor: 'fullName',
		minWidth: 200
	},
	{
		accessor: 'email',
		minWidth: 160
	},
	{
		accessor: 'position',
		minWidth: 160
	},
	{
		id: '_delete',
		Cell: DeleteCell,
		width: 78,
		disableResizing: true
	}
];
