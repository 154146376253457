import useAppSelector from '@src/core/hooks/useAppSelector';
import {getCompaniesFilter, getCompaniesPageSortSettings} from '@src/store/modules/entities/companies/selectors';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useCallback} from 'react';
import {SortingState} from '@tanstack/react-table';
import {companiesSettingsActions} from '@src/store/modules/settings/pages/companies/companiesSettingsSlice';

export const useCompaniesFilters = () => {
	const dispatch = useAppDispatch();
	const filterState = useAppSelector(getCompaniesFilter);
	const sorting = useAppSelector(getCompaniesPageSortSettings);
	const {setFilter, clearFilter, setSort} = companiesSettingsActions;

	const onCompaniesChange = useCallback(
		(value: string[]) => {
			dispatch(setFilter({companiesIds: value}));
		},
		[dispatch, setFilter]
	);

	const onCustomersChange = useCallback(
		(value: string[]) => {
			dispatch(setFilter({customersIds: value}));
		},
		[dispatch, setFilter]
	);

	const onCreatedAtDateChange = useCallback(
		(from: Date, to: Date) => {
			dispatch(setFilter({createdAt: {from, to}}));
		},
		[dispatch, setFilter]
	);

	const onModifiedAtDateChange = useCallback(
		(from: Date, to: Date) => {
			dispatch(setFilter({modifiedAt: {from, to}}));
		},
		[dispatch, setFilter]
	);

	const onSortingChange = useCallback(
		(value: SortingState) => {
			dispatch(setSort(value));
		},
		[dispatch, setSort]
	);

	const onClearFilters = useCallback(() => {
		dispatch(clearFilter());
	}, [clearFilter, dispatch]);

	const hasCompaniesFilters = Boolean(
		filterState.companiesIds?.length
		|| filterState.customersIds?.length
		|| filterState.createdAt?.from
		|| filterState.modifiedAt?.from
	);
	return {
		filterState,
		sorting,
		onCompaniesChange,
		onCustomersChange,
		onCreatedAtDateChange,
		onModifiedAtDateChange,
		onSortingChange,
		onClearFilters,
		hasCompaniesFilters
	};
};
