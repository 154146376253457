import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';
import INotificationPeriod from '@tehzor/tools/interfaces/INotificationPeriod';

const getAllIds = (state: IState) => state.notifications.periods.allIds || [];
const getById = (state: IState): {[id: string]: INotificationPeriod} => state.notifications.periods.byId || {};

/**
 * Возвращает каналы уведомлений в виде массива
 */
export const getNotificationPeriodsAsArray = createSelector([getAllIds, getById], (allIds, byId) =>
	allIds.map((id: string) => byId[id]));
