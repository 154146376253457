import {createSelector} from 'reselect';
import {IState} from '../..';
import {getInitialStateForUser} from './reducers';
import {orderBy} from 'lodash';

export const extractDevicesData = createSelector(
	[
		(state: IState) => state.entities.devices,
		(state: IState, userId: string) => userId
	],
	(data, userId) => data[userId] || getInitialStateForUser()
);

export const extractDevicesAsArray = createSelector(
	extractDevicesData,
	data => data.allIds.map(id => data.byId[id])
);

export const extractFilteredDevicesAsArray = createSelector(
	extractDevicesAsArray,
	arr => orderBy(arr, ['lastUsedAt'], ['desc'])
);