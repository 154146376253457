import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import {IChangeFiltersPayload} from '@tehzor/tools/interfaces/store/IChangeFiltersPayload';
import {IClearFiltersPayload} from '@tehzor/tools/interfaces/store/IClearFiltersPayload';
import {IChangeSortPayload} from '@tehzor/tools/interfaces/store/IChangeSortPayload';
import {IChangePageSizePayload} from '@tehzor/tools/interfaces/store/IChangePageSizePayload';
import {GET_REQUEST} from '@src/store/modules/entities/problemTemplatesSets/constants';

export interface ITemplatesSetsFiltersState extends Record<string, unknown> {
	name?: string;
}

export type ITemplatesSetsSortState = Record<string, boolean>;

export interface ITemplatesSetsPageSettingsState {
	tabIndex: number;
	filters: ITemplatesSetsFiltersState;
	sort: ITemplatesSetsSortState;
	pageSize: number;
}

export type ITemplatesSetsPagesSettingsState = Record<string, ITemplatesSetsPageSettingsState>;

export const getInitialStateForPage = (objectId: string): ITemplatesSetsPageSettingsState => ({
	tabIndex: 0,
	filters: {objects: [objectId]},
	sort: {},
	pageSize: 20
});

export default createReducer<ITemplatesSetsPagesSettingsState>(
	{},
	{
		[GET_REQUEST]: (state, {payload}: {payload: {objectId: string}}) => {
			if (!state.hasOwnProperty(payload.objectId)) {
				state[payload.objectId] = getInitialStateForPage(payload.objectId);
			}
		},
		[types.CHANGE_FILTERS]: (state, {payload}: {payload: IChangeFiltersPayload<ITemplatesSetsFiltersState>}) => {
			const {filters} = payload;
			if (payload.objectId !== 'all') {
				filters.objects = [payload.objectId];
			}
			state[payload.objectId].filters = filters;
		},
		[types.CLEAR_FILTERS]: (state, {payload}: {payload: IClearFiltersPayload}) => {
			const filters = {} as ITemplatesSetsFiltersState;
			if (payload.objectId !== 'all') {
				filters.objects = [payload.objectId];
			}
			state[payload.objectId].filters = filters;
		},
		[types.CHANGE_SORT]: (state, {payload}: {payload: IChangeSortPayload<ITemplatesSetsSortState>}) => {
			state[payload.objectId].sort = payload.sort;
		},
		[types.CHANGE_PAGE_SIZE]: (state, {payload}: {payload: IChangePageSizePayload}) => {
			state[payload.objectId].pageSize = payload.pageSize;
		}
	}
);
