import React from 'react';
import {CompaniesTable} from './components/CompaniesTable/CompaniesTable';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useAsync} from 'react-use';
import {getCompanies} from '@src/store/modules/entities/companies/actions';
import {LoadingPanel} from '@tehzor/ui-components';
import './CompaniesPage.less';
import CompanyAddingWithPermissions from './components/CompanyAddingWithPermissions/CompanyAddingWithPermissions';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {CompaniesFilter} from './components/CompaniesFilter/CompaniesFilter';

export const CompaniesPage = () => {
	useAppHeader(
		{
			title: 'Компании'
		},
		[]
	);
	const dispatch = useAppDispatch();
	const loadingState = useAsync(async () => {
		await dispatch(getCompanies());
	}, []);

	return (
		<LoadingPanel
			className="loading-panel_main"
			active={loadingState.loading}
		>
			<div className="page-cont">
				<div className="companies-page__button-block">
					<CompanyAddingWithPermissions/>
				</div>
				<CompaniesFilter/>
				<CompaniesTable/>
			</div>
		</LoadingPanel>
	);
};
