import {RowInfo} from 'react-table-6';
import CheckboxCell from './CheckboxCell';
import NameCell from './NameCell';
import PermExpanderCell from './PermExpanderCell';
import RestExpanderCell from './RestExpanderCell';

const getPermExpanderProps = (...args: any[]) => {
	const {original} = args[1] as RowInfo;
	if (!original.children || !original.children.length) {
		return {onClick: () => null};
	}
	return {};
};

const getRestExpanderProps = (...args: any[]) => {
	const {original} = args[1] as RowInfo;
	if (
		original.children && original.children.length
		|| !original.availableRestrictions
		|| !original.availableRestrictions.length
	) {
		return {onClick: () => null};
	}
	return {};
};

const columns = [
{
	id: '_permExpander',
	Expander: PermExpanderCell,
	expander: true,
	className: 'role-page__ptable-perm-expander-col',
	width: 50,
	getProps: getPermExpanderProps
}, {
	id: 'name',
	Cell: NameCell,
	minWidth: 100
}, {
	id: 'checkbox',
	Cell: CheckboxCell,
	className: 'role-page__ptable-checkbox-col',
	minWidth: 36,
	maxWidth: 350
}, {
	id: '_restExpander',
	Expander: RestExpanderCell,
	expander: true,
	className: 'role-page__ptable-rest-expander-col',
	width: 50,
	getProps: getRestExpanderProps
}
];

export default columns;
