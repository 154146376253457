import React, {memo} from 'react';
import {EditableFieldLabel} from '@tehzor/ui-components';
import {extractCompaniesForObject} from '@src/store/modules/entities/companies/selectors';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {SingleSelect} from '@src/components/SingleSelect';

interface ICompanyFieldProps {
	objectId?: string;
	value?: string;
	hasError?: string;
	onChange?: (value: string) => void;
}

export const CompanyField = memo(({objectId, value, hasError, onChange}: ICompanyFieldProps) => {
	const companies = useAppSelector(s => extractCompaniesForObject(s, objectId));

	return (
		<div className="field">
			<EditableFieldLabel className="field__label">Компания</EditableFieldLabel>
			<SingleSelect
				onChange={onChange}
				optionId={value}
				options={companies}
				error={hasError}
			/>
		</div>
	);
});
